import { Box, Button, Card, toast, useOpenClose } from '@palmetto/palmetto-components';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { ConstructionAdderType, PriceSheetMappingBulkChangesPayload, PricingSettingsType, ProgramType } from 'types';
import '../mappings.css';
import { FilteredMappingsList } from '../FilteredMappingsList';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { BulkEditModal } from './BulkEditModal';
import {
  useApplyBulkPriceSheetMappingsMutation,
  useGetConstructionAddersQuery,
  useGetHoldbacksQuery,
  useGetItcModifiersQuery,
  useGetModifiersQuery,
  useGetPriceSheetsQuery,
} from '../../../../services/pricing';
import { getColumnConfig } from './BulkChangesColumnConfig';

export const BulkEditMappings = () => {
  const [currentEditColumn, setCurrentEditColumn] = useState<string | null>(null);
  const { programType } = useParams<{ programType: string }>();
  const { data: priceSheetResults }: any = useGetPriceSheetsQuery({
    pageNum: 1,
    pageSize: 1000,
    programType: programType as ProgramType,
  });
  const { data: energyCommunities = [] } = useGetItcModifiersQuery({
    type: PricingSettingsType.energyCommunity,
    programType: programType as ProgramType,
  });
  const { data: ppwModifiers = [] } = useGetModifiersQuery({
    type: PricingSettingsType.ppwAdjustment,
    programType: programType as ProgramType,
  });
  const { data: domesticContentModifiers = [] } = useGetItcModifiersQuery({
    type: PricingSettingsType.domesticContent,
    programType: programType as ProgramType,
  });
  const { data: holdbacks = [] } = useGetHoldbacksQuery({ programType: programType as ProgramType });
  const { data: arbitrageBatteryAdders = [] } = useGetConstructionAddersQuery({
    type: ConstructionAdderType.arbitrageBattery,
    programType: programType as ProgramType,
  });
  const { data: electricalUpgradeAdders = [] } = useGetConstructionAddersQuery({
    type: ConstructionAdderType.electricalUpgrade,
    programType: programType as ProgramType,
  });
  const { data: backupBatteryAdders = [] } = useGetConstructionAddersQuery({
    type: ConstructionAdderType.backupBattery,
    programType: programType as ProgramType,
  });
  const { data: backupBatteryKwhAdders = [] } = useGetConstructionAddersQuery({
    type: ConstructionAdderType.backupBatteryKwh,
    programType: programType as ProgramType,
  });
  const { data: backupBatteryPriceCaps = [] } = useGetConstructionAddersQuery({
    type: ConstructionAdderType.backupBatteryPriceCap,
    programType: programType as ProgramType,
  });
  const [applyBulkPriceSheetMappings] = useApplyBulkPriceSheetMappingsMutation();
  const [searchParams] = useSearchParams();
  const advancedFilters = searchParams.get('advancedFilters');
  const searchTerm = searchParams.get('search') || undefined;

  const [editValuesDisplay, setEditValuesDisplay] = useState<
    Record<string, string | { id: string; name: string | number }>
  >({});
  const [editValues, setEditValuesInternal] = useState<Record<string, string>>({});

  const setEditValues = useCallback(
    (values: Record<string, string>) => {
      const currentValues = { ...editValues, ...values };
      setEditValuesInternal(currentValues);
      const {
        absoluteCeiling,
        escalationRateMax,
        escalationRateMin,
        priceSheet,
        pvOnlyCeiling,
        pvOnlyMin,
        zeroEscalatorCeiling,
        holdback,
        energyCommunity,
        ppwModifier,
        domesticContentModifier,
        arbitrageBattery,
        electricalUpgrade,
        backupBattery,
        backupBatteryKwh,
        backupBatteryPriceCap,
      } = currentValues;
      const newDisplayValues: Record<string, string | { id: string; name: string }> = {
        holdback: holdback ? (holdbacks.find((hb: any) => hb.id === holdback) ?? 'remove') : undefined,
        energyCommunity: energyCommunity
          ? (energyCommunities.find((ec: any) => ec.id === energyCommunity) ?? 'remove')
          : undefined,
        ppwModifier: ppwModifier ? (ppwModifiers.find((ppw: any) => ppw.id === ppwModifier) ?? 'remove') : undefined,
        domesticContentModifier: domesticContentModifier
          ? (domesticContentModifiers.find((dcm: any) => dcm.id === domesticContentModifier) ?? 'remove')
          : undefined,
        arbitrageBattery: arbitrageBattery
          ? (arbitrageBatteryAdders.find((aba: any) => aba.id === arbitrageBattery) ?? 'remove')
          : undefined,
        electricalUpgrade: electricalUpgrade
          ? (electricalUpgradeAdders.find((ela: any) => ela.id === electricalUpgrade) ?? 'remove')
          : undefined,
        backupBattery: backupBattery
          ? (backupBatteryAdders.find((bub: any) => bub.id === backupBattery) ?? 'remove')
          : undefined,
        backupBatteryKwh: backupBatteryKwh
          ? (backupBatteryKwhAdders.find((bub: any) => bub.id === backupBatteryKwh) ?? 'remove')
          : undefined,
        backupBatteryPriceCap: backupBatteryPriceCap
          ? (backupBatteryPriceCaps.find((priceCap: any) => priceCap.id === backupBatteryPriceCap) ?? 'remove')
          : undefined,
        absoluteCeiling,
        escalationRateMax,
        escalationRateMin,
        priceSheet,
        pvOnlyCeiling,
        pvOnlyMin,
        zeroEscalatorCeiling,
      };
      setEditValuesDisplay(newDisplayValues);
    },
    [
      editValues,
      setEditValuesDisplay,
      setEditValuesInternal,
      electricalUpgradeAdders,
      backupBatteryAdders,
      backupBatteryKwhAdders,
      backupBatteryPriceCaps,
      holdbacks,
      energyCommunities,
      ppwModifiers,
      domesticContentModifiers,
      priceSheetResults,
    ],
  );

  const { isOpen: isModalOpen, handleOpen: openModal, handleClose: closeModal } = useOpenClose();

  const onColumnClick = useCallback(
    (dataKey: string) => {
      setCurrentEditColumn(dataKey);
      openModal();
    },
    [openModal],
  );

  const confirmApplyBulkChanges = useCallback(async () => {
    const confirmed = window.confirm(`Selected price mappings will be replaced. \n Are you sure?`);
    if (!confirmed) return;
    const payload: PriceSheetMappingBulkChangesPayload = {
      filters: {
        advancedFilters,
        searchTerm,
      },
      ...(editValues.priceSheet
        ? {
            priceSheetId: editValues.priceSheet,
            pvOnlyMin: parseFloat(editValues.pvOnlyMin),
            pvOnlyCeiling: parseFloat(editValues.pvOnlyCeiling),
            zeroEscalatorCeiling: parseFloat(editValues.zeroEscalatorCeiling),
            absoluteCeiling: parseFloat(editValues.absoluteCeiling),
            escalationRateMin: parseFloat(editValues.escalationRateMin),
            escalationRateMax: parseFloat(editValues.escalationRateMax),
          }
        : {}),
      holdback: editValues.holdback,
      energyCommunity: editValues.energyCommunity,
      ppwModifier: editValues.ppwModifier,
      domesticContentModifier: editValues.domesticContentModifier,
      constructionAdders: Object.fromEntries(
        Object.values(ConstructionAdderType)
          .map((type) => (editValues[type] ? [type, editValues[type]] : []))
          .filter(Boolean),
      ),
    };
    try {
      await applyBulkPriceSheetMappings({ data: payload, programType: programType as ProgramType }).unwrap();
      setEditValues({});
      setEditValuesDisplay({});
      toast.success('Bulk Price Sheet mappings applied');
    } catch (e) {
      toast.error('Error applying bulk price sheet mappings');
    }
  }, [editValues, applyBulkPriceSheetMappings, advancedFilters, searchTerm]);

  const columnConfig = useMemo(
    () =>
      getColumnConfig(
        editValuesDisplay,
        onColumnClick,
        Object.fromEntries(
          priceSheetResults?.data?.map((priceSheet: any) => [priceSheet.id, priceSheet.name]) ?? [],
        ),
      ),
    [editValuesDisplay, onColumnClick, priceSheetResults],
  );

  return (
    <Card padding="sm">
      <Card.Header>
        <Box direction="row" justifyContent="space-between">
          <Box gap="2xs">
            <Box fontSize="sm" color="body-primary">
              <Link to={`/admin/pricing/${programType}`}>Price Mappings</Link>
            </Box>
            <Box fontSize="2xl">Bulk Edit Active Price Mappings</Box>
          </Box>
        </Box>
      </Card.Header>
      {currentEditColumn && (
        <BulkEditModal
          dataKey={currentEditColumn}
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          setEditValues={setEditValues}
          initialValues={editValues}
        />
      )}
      <FilteredMappingsList columnConfig={columnConfig} />
      <Box padding="sm" direction="row" justifyContent="flex-end">
        <Button onClick={confirmApplyBulkChanges}>Apply Bulk Changes</Button>
      </Box>
    </Card>
  );
};
