import { Helmet } from 'react-helmet';
import { MainContainer } from '..';
import { FilterableProjectTransactionTable } from '@/components/Payouts/FilterableProjectTransactionTable';
import { useParams } from 'react-router-dom';
import {
  useCreateProjectTransactionMutation,
  useDeleteProjectTransactionMutation,
  useGetProjectByExternalIdQuery,
  useGetProjectTransactionsQuery,
} from '@/services/payouts';
import { AccountFundingSummary } from '@/components/Account/AccountFundingSummary';
import { useGetPaymentPlanQuery } from '@/services/paymentPlans';
import { Alert, Box, Button, useOpenClose, ButtonTone, toast, Card } from '@palmetto/palmetto-components';
import { useCallback, useMemo } from 'react';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { CreateProjectTransactionPayload, UserPermissions } from 'types';
import { AddProjectTransactionModal } from '../Payouts/admin/AddProjectTransactionModal';
import { RequirePayoutsAccountingAdminUser } from '../auth/RequirePayoutsAccountingAdminUser';
import { MaterialsInvoiceStatus, ProjectTransactionStatus } from 'types';
import { Quotes } from '@/components/Quotes/Quotes';
import { SubtitleBox } from '@/components/Account/AccountFundingSummary.utils';
import { isPalmettoFinanceUser } from '@/components/auth/RequirePricingAdminUser';
import { useAuth } from '@/components/auth/authProvider';

export const AccountFundingDetails = ({ account }: { account: any }) => {
  const { id } = useParams<{ id: string }>();
  const { data: transactions, isLoading: isTransactionsLoading } = useGetProjectTransactionsQuery({
    projectExternalId: id,
  });
  const { claims } = useAuth();
  const { data: project, isLoading: isProjectLoading } = useGetProjectByExternalIdQuery(id || skipToken);
  const isLoading = isTransactionsLoading || isProjectLoading;
  const { data: payeePlan } = useGetPaymentPlanQuery(
    { id: `${project?.payeePlanId}` },
    {
      skip: !project?.payeePlanId,
    },
  );

  const orgPlanDiffersAccountPlan = useMemo(() => {
    if (!project?.planId) {
      return false;
    }
    return project.planId !== project?.payeePlanId;
  }, [project]);

  const showMissingInvoiceWarning = useMemo(() => {
    if (!project) return false;
    if (!project.materials) return false;
    return project.materials?.invoiceStatus === MaterialsInvoiceStatus.missing;
  }, [project]);

  const hideColumns = useMemo(() => {
    const columns = ['projectName'];
    if (
      !isPalmettoFinanceUser(claims) ||
      !claims?.permissions?.includes(UserPermissions.lightReachAccountingAdmin)
    ) {
      columns.push('payeeName');
      columns.push('isManual');
    }
    return columns;
  }, [claims]);

  const {
    isOpen: isAddProjectTransactionModalOpen,
    handleOpen: handleOpenAddProjectTransactionModal,
    handleClose: handleCloseAddProjectTransactionModal,
  } = useOpenClose();
  const [createTransaction] = useCreateProjectTransactionMutation();

  const handleAddTransaction = useCallback(async (values: CreateProjectTransactionPayload) => {
    try {
      await createTransaction({
        ...values,
        ...(values.clawbackCategory
          ? { description: `${values.clawbackCategory} - ${values.description ?? ''}` }
          : {}),
      }).unwrap();
      handleCloseAddProjectTransactionModal();
      toast.success('Transaction added successfully');
    } catch (error) {
      console.log(error);
      toast.error('Could not add transaction');
    }
  }, []);

  const [deleteTransaction] = useDeleteProjectTransactionMutation();

  const transactionsWithContextMenu = useMemo(() => {
    return transactions?.map((transaction) => ({
      ...transaction,
      contextMenu:
        [
          ProjectTransactionStatus.pending,
          ProjectTransactionStatus.open,
          ProjectTransactionStatus.historical,
          ProjectTransactionStatus.adjustment,
        ].includes(transaction.status) &&
        isPalmettoFinanceUser(claims) &&
        claims?.permissions?.includes(UserPermissions.lightReachAccountingAdmin)
          ? [
              {
                label: 'Delete Transaction',
                onClick: async () => {
                  try {
                    if (transaction.id) {
                      const confirmed = window.confirm(
                        'Deleting this transaction will cause all open or pending transactions to be recalculated.  This action cannot be undone.',
                      );

                      if (!confirmed) return;
                      await deleteTransaction({ transactionId: transaction.id }).unwrap();
                      toast.success('Transaction deleted successfully');
                    }
                  } catch (_e: any) {
                    toast.error('Error deleting transaction');
                  }
                },
                tone: 'danger' as ButtonTone,
              },
            ]
          : undefined,
    }));
  }, [transactions]);

  return (
    <>
      <Helmet>
        <title>Account Funding</title>
      </Helmet>
      <MainContainer maxWidth="100" padding="0 4xl" gap="sm">
        <AddProjectTransactionModal
          isOpen={isAddProjectTransactionModalOpen}
          handleClose={handleCloseAddProjectTransactionModal}
          modalTitle="Add Payout"
          submitButtonText="Add Payout"
          modalId="addPaymentModal"
          handleSubmit={handleAddTransaction}
          batchDate={undefined}
          payeeType={undefined}
          accountId={id}
          hideAccountSelection={true}
        />
        {!isLoading && transactions && project && (
          <>
            {showMissingInvoiceWarning && (
              <Alert
                variant="warning"
                hasIcon
                title="Payment for this account cannot be issued until CED material invoices are received. Please reach out to CED to finalize the invoice."
              />
            )}

            <AccountFundingSummary
              transactions={transactions}
              project={project}
              orgPlanDiffersAccountPlan={orgPlanDiffersAccountPlan}
              orgPlanName={payeePlan?.name || ''}
            />
            <Card padding="sm">
              <Card.Header>
                <Box direction="row" justifyContent="space-between">
                  <SubtitleBox>TRANSACTIONS</SubtitleBox>
                  <RequirePayoutsAccountingAdminUser>
                    <Box direction="row" childGap="sm" alignItems="center">
                      <Button
                        style={{ maxWidth: '200px' }}
                        iconPrefix="add"
                        size="sm"
                        variant="secondary"
                        tone="neutral"
                        disabled={isLoading}
                        onClick={handleOpenAddProjectTransactionModal}
                      >
                        Add Transaction
                      </Button>
                    </Box>
                  </RequirePayoutsAccountingAdminUser>
                </Box>
              </Card.Header>
              <Box margin="0 lg 0 lg">
                <FilterableProjectTransactionTable
                  transactions={transactionsWithContextMenu ?? []}
                  hideColumns={hideColumns}
                  hideSummary
                />
              </Box>
            </Card>
            <Card padding="sm">
              <Card.Header>
                <Box direction="row" justifyContent="space-between" fontSize="md">
                  <SubtitleBox>QUOTES</SubtitleBox>
                </Box>
              </Card.Header>
              <Quotes hideHeader={true} />
            </Card>
          </>
        )}
        {!isLoading && (!transactions || !project) && (
          <Box textAlign={'center'}>No funding exists for this account.</Box>
        )}
      </MainContainer>
    </>
  );
};
