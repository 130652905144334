import { ActivationPackage, DeepPartial } from 'types';
import { api } from './api';

export const activationPackageApi = api.injectEndpoints({
  endpoints: (build) => ({
    saveActivationPackage: build.mutation<
      any,
      { accountId: string; activationPackage: DeepPartial<ActivationPackage> }
    >({
      query: ({ accountId, activationPackage }) => ({
        url: `v2/accounts/${accountId}/activation-package/save`,
        method: 'POST',
        body: { ...activationPackage },
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: 'Accounts', id: arg?.accountId }],
    }),
    submitActivationPackage: build.mutation<any, { accountId: string; activationPackage: ActivationPackage }>({
      query: ({ accountId, activationPackage }) => ({
        url: `v2/accounts/${accountId}/activation-package/submit`,
        method: 'POST',
        body: { ...activationPackage },
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: 'Accounts', id: arg?.accountId },
        { type: 'Queues', accountId: arg?.accountId },
        { type: 'AccountMilestones', id: arg?.accountId },
      ],
    }),
    submitPtoPackage: build.mutation<any, { accountId: string; ptoPackage: Pick<ActivationPackage, 'ptoGrantedDate'> }>({
      query: ({ accountId, ptoPackage }) => ({
        url: `v2/accounts/${accountId}/activation-package/pto/submit`,
        method: 'POST',
        body: { ...ptoPackage },
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: 'Accounts', id: arg?.accountId },
        { type: 'Queues', accountId: arg?.accountId },
        { type: 'AccountMilestones', id: arg?.accountId },
      ],
    }),
  }),
});

export const { useSaveActivationPackageMutation, useSubmitActivationPackageMutation, useSubmitPtoPackageMutation } = activationPackageApi;
