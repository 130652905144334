import PageHeader from '@/components/PageHeader';
import { PaymentPlanTable } from '../PaymentPlanTable';
import { useGetPaymentPlanOrgsQuery, useGetPaymentPlanQuery } from '@/services/paymentPlans';
import { Box, Card, Table } from '@palmetto/palmetto-components';
import { NavLink, useParams } from 'react-router-dom';

export const PaymentPlan = () => {
  const { programType = '', id = '' } = useParams<{ programType: any; id: string }>();
  const { data: paymentPlan, isLoading: isLoadingPaymentPlan } = useGetPaymentPlanQuery({
    id,
  });

  const { data: paymentPlanOrgs = [], isLoading: isLoadingPaymentPlanOrg } = useGetPaymentPlanOrgsQuery({
    id,
  });

  const paymentPlanOrgColumns = [
    {
      heading: 'Organization',
      dataKey: 'name',
      render: (cell?: any) => (
        <Box fontWeight="medium" as="span" display="inline">
          {cell}
        </Box>
      ),
    },
  ];

  return (
    <>
      {!isLoadingPaymentPlan && paymentPlan && (
        <Box direction={'column'} childGap={'xs'}>
          <Box style={{ marginLeft: '0' }}>
            <NavLink
              to={`/admin/payouts/${programType}/payment-plans/`}
              style={{ textDecoration: 'none', fontWeight: '500' }}
              end
            >
              {({ isActive }) => (
                <Box
                  as="span"
                  className={isActive ? 'tab-active' : ''}
                  hover={{ color: 'primary' }}
                  style={{ transitionDuration: '0s' }}
                >
                  Payment Plans
                </Box>
              )}
            </NavLink>
          </Box>
          <PageHeader title={paymentPlan?.name}></PageHeader>
          <Card>
            <Card.Header>
              <Box direction="row" justifyContent="space-between" fontSize="md">
                <Box>{'Payment Plan'}</Box>
              </Box>
            </Card.Header>
            <PaymentPlanTable programType={programType} paymentPlans={[paymentPlan]} showFirstColumn={false} />
          </Card>
        </Box>
      )}
      <br></br>
      <Box direction={'column'} childGap={'lg'}>
        <Card>
          <Card.Header>
            <Box direction="row" justifyContent="space-between" fontSize="md">
              <Box>{'Mappings'}</Box>
            </Box>
          </Card.Header>
          {!isLoadingPaymentPlanOrg && paymentPlanOrgs?.length > 0 ? (
            <Table rowKey="id" columns={paymentPlanOrgColumns} rows={paymentPlanOrgs} />
          ) : (
            <Box padding="xl" textAlign="center">
              There are no organizations currently using this payment plan
            </Box>
          )}
        </Card>
      </Box>
    </>
  );
};
