import { Meta } from './Entities';
import { ObjectId } from 'mongodb';

export interface FundDocument {
  id: ObjectId;
  type: FundType;
  key: string; // antillean-taxEquity
  description: string;
  netSuiteSubsidiaryId: number;
  tranches?: Array<Tranche>;
  history?: Array<FundHistory>;
  meta?: Meta;
}

export interface FundHistory {
  date: Date;
  event: FundEvent;
  user: string;
  tranche: Tranche;
  accountCounter?: number ;
}

export enum FundType {
  taxEquity = 'taxEquity',
  debt = 'debt',
  strandedAsset = 'strandedAsset',
  jointStrandedAsset = 'jointStrandedAsset',
}

export enum FundEvent {
  updateTranche = 'updateTranche',
  createTranche = 'createTranche',
  addAccount = 'addAccount',
  reassignAccount = 'reassignAccount',
  removeAccount = 'removeAccount',
}

export enum TrancheStatus {
  open = 'open',
  closed = 'closed',
}

export interface Tranche {
  name: string;
  submittedDate: Date;
  trancheDate?: Date;
  trancheStatus?: TrancheStatus;
  trancheDataDownloadDate?: Date;
}

export interface TrancheSummary {
  name: string;
  submittedDate: Date;
  trancheDate?: Date;
  accountCount: number;
  trancheDataDownloadDate?: Date;
}

export interface FundListItem {
  fundId: ObjectId;
  fundKey: string;
  fundType: FundType;
  description: string;
  trancheCount: number;
}

export interface FundSummary {
  fundKey: string;
  fundType: FundType;
  description: string;
  netSuiteSubsidiaryId: number;
  createdDate: Date;
  tranches: Array<TrancheSummary>;
}

export enum TranchedAccountSearchField {
  address1 = 'address.address1',
  address2 = 'address.address2',
  addressCity = 'address.city',
  addressState = 'address.state',
  addressZIP = 'address.zip',
  applicantEmail = 'applicants.email',
  applicantFirstName = 'applicants.firstName',
  applicantLastName = 'applicants.lastName',
}

export interface UpdateTrancheRequestBody {
  trancheDataDownloadDate?: string;
  submittedDate: string;
  trancheDate?: string;
  isClosed?: boolean;
}
