import { Badge, BadgeVariant, Box, Button, Details, toast, useOpenClose } from '@palmetto/palmetto-components';
import { AdderType, QuoteStatus } from '../../types/Quotes';
import { useVoidQuoteMutation } from '../../services/quotes';
import { currencyFormatter, kwhPriceFormatter } from '../../helpers/currencyFormatter';
import RequirePermissions from '../auth/requirePermissions';
import { isErrorWithData } from '../../services/helpers';
import { AdditionalCostAdderType, ConstructionAdderType, ProgramType, Quote, UserPermissions } from 'types';
import { useSendContractMutation } from '../../services/contract';
import { getAdderTypeLabel } from '../../helpers/addersHelpers';
import {
  getDomesticElements,
  getEpcRatePPWLabel,
  getEpcTotalPaymentLabel,
  ListItemElement,
  ListItemElementWithMultipleProps,
} from './QuoteListItem.utils';

const getAdderLabel = (adder: AdderType, backupBatteryDetails: any): string => {
  if (adder === AdderType.backupBattery && backupBatteryDetails?.pricingMethod === 'kwhRate') {
    return getAdderTypeLabel(AdderType.backupBatteryKwh) ?? adder;
  }

  return getAdderTypeLabel(adder) ?? adder;
};

interface QuoteListItemProps {
  item: Quote;
}
export function QuoteListItem({ item }: QuoteListItemProps): JSX.Element {
  const {
    totalAmountPaid,
    kwhPrice,
    systemPricingDetails,
    totalSystemCost,
    pricePerWatt,
    productName,
    status,
    adders,
    additionalCostAdders,
    backupBatteryDetails,
    programType,
  } = item;
  const showVoidButton = status === QuoteStatus.active || status === QuoteStatus.contracted;
  const [voidQuote, { isLoading: isVoidingQuote }] = useVoidQuoteMutation({
    fixedCacheKey: 'useVoidQuoteMutationKey',
  });
  const [_sendContract, { isLoading: isSendingContract }] = useSendContractMutation({
    fixedCacheKey: 'useSendContractMutationKey',
  });
  const { isOpen: isDetailsOpen, handleToggle: handleDetailsToggle } = useOpenClose({
    defaultIsOpen: showVoidButton,
  });
  const quoteStatus: {
    [key in QuoteStatus]: {
      label: string;
      variant: BadgeVariant;
    };
  } = {
    [QuoteStatus.contracted]: {
      label: 'Contracted',
      variant: 'success',
    },
    [QuoteStatus.active]: {
      label: 'Active',
      variant: 'success',
    },
    [QuoteStatus.voided]: {
      label: 'Voided',
      variant: 'warning',
    },
  };
  const getAdderList = (adders?: string[]) => {
    return adders ? adders.map((adder) => getAdderLabel(adder as AdderType, backupBatteryDetails)).join(', ') : '';
  };
  const handleVoidQuote = async () => {
    const toastId = toast.loading('Voiding quote');

    try {
      const confirmed = window.confirm('Are you sure you want to Void this Quote? This action cannot be undone.');

      if (!confirmed) return;
      await voidQuote({ accountId: item.accountId, quoteId: item.id }).unwrap();
      toast.success('Quote voided successfully');
    } catch (e) {
      if (isErrorWithData(e)) {
        const errorMessage = e.data.message;
        toast.error(errorMessage);
      } else {
        console.error(e);
        toast.error('Error voiding quote');
      }
    } finally {
      toast.dismiss(toastId);
    }
  };

  const hasBackupBattery = adders ? adders.includes(AdderType.backupBattery) : false;
  const hasBackupBatteryKWH = adders ? adders.includes(AdderType.backupBatteryKwh) : false;
  const hasSolarReadiness = adders ? adders.includes(ConstructionAdderType.solarReadiness) : false;
  const hasArbitrageBattery = adders?.includes(AdderType.arbitrageBattery);
  const isHVACQuote = programType === ProgramType.hvac;
  const totalBackupBatteryCost = additionalCostAdders?.find(
    (adder: any) => adder.type === AdderType.backupBattery,
  )?.cost;
  const totalAdderCost =
    additionalCostAdders?.reduce((acc: number, adder: any) => {
      acc += adder.cost;
      return acc;
    }, 0) ?? 0;
  const solarReadinessCost = hasSolarReadiness
    ? additionalCostAdders.find((element) => element.type === AdditionalCostAdderType.solarReadiness)?.cost || 0
    : 0;
  let title = productName;
  if (!isHVACQuote) {
    title += `, ${kwhPriceFormatter.format(kwhPrice)}/kWh`;
  }
  const epcTotalPaymentLabel = getEpcTotalPaymentLabel({
    hasArbitrageBattery,
    hasBackupBatteryKWH,
    isHVACQuote,
  });
  const epcRatePPW = getEpcRatePPWLabel({
    hasArbitrageBattery,
    hasBackupBatteryKWH,
  });

  const { epcTotalPaymentDomesticContentElements, epcRatePPWDomesticContentElements, totalEPCPaymentElements } =
    getDomesticElements({
      quote: item,
      totalSystemCost,
      totalAdderCost,
      pricePerWatt,
    });

  return (
    <Details isOpen={isDetailsOpen} gap="sm" borderWidth="xs 0 0 0" borderColor="separator">
      <Details.Summary
        isDetailsOpen={isDetailsOpen}
        onToggle={showVoidButton ? undefined : handleDetailsToggle}
        display="inline"
        cursor={'pointer'}
        width="100"
      >
        <Box width="100" gap="5xl-tablet 5xl-desktop" columnGap={'md'} direction="row" padding={'sm lg'}>
          <Box width="lg">
            <Badge
              message={quoteStatus[status as keyof typeof QuoteStatus]?.label}
              variant={quoteStatus[status as keyof typeof QuoteStatus]?.variant}
              style={{ width: 'fit-content' }}
            />
          </Box>
          <Box gap="xs" flex="auto">
            <Box>{title || '---'}</Box>
            <Box fontSize="xs" color="body-secondary">
              {getAdderList(adders)}
            </Box>
          </Box>
          <Box display={'flex'}>
            {showVoidButton ? (
              <RequirePermissions
                permissions={[UserPermissions.admin, UserPermissions.editor]}
                checkAllPermissions={false}
              >
                <Button
                  size="sm"
                  variant="secondary"
                  tone="danger"
                  disabled={isVoidingQuote || isSendingContract}
                  onClick={handleVoidQuote}
                >
                  Void
                </Button>
              </RequirePermissions>
            ) : (
              <Box fontSize="xs">{isDetailsOpen ? 'Hide Details' : 'Show Details'}</Box>
            )}
          </Box>
        </Box>
      </Details.Summary>

      <Box padding="0 0 lg 0">
        {!isHVACQuote && (
          <ListItemElement
            label={
              hasArbitrageBattery
                ? 'Solar with Arbitrage Battery Rate ($/kWh)'
                : hasBackupBatteryKWH
                  ? 'Solar with Backup Battery Rate ($/kWh)'
                  : 'Solar-Only Rate ($/kWh)'
            }
            value={kwhPriceFormatter.format(kwhPrice)}
          />
        )}
        {
          <ListItemElement
            label={
              hasArbitrageBattery
                ? 'Estimated Solar with Arbitrage Battery Year-1 Monthly Payment'
                : hasBackupBatteryKWH
                  ? 'Estimated Solar with Backup Battery Year-1 Monthly Payment'
                  : programType !== ProgramType.hvac
                    ? 'Estimated Solar-Only Year-1 Monthly Payment'
                    : 'Estimated Year-1 Monthly Payment'
            }
            value={currencyFormatter.format(systemPricingDetails[0].monthlyPayment)}
          />
        }
        {!isHVACQuote &&
          (epcRatePPWDomesticContentElements.length ? (
            <ListItemElementWithMultipleProps baseLabel={epcRatePPW} values={epcRatePPWDomesticContentElements} />
          ) : (
            <ListItemElement label={epcRatePPW} value={currencyFormatter.format(pricePerWatt)} />
          ))}
        {hasSolarReadiness && (
          <ListItemElement label="Solar Readiness" value={currencyFormatter.format(solarReadinessCost)} />
        )}
        {epcTotalPaymentDomesticContentElements.length ? (
          <ListItemElementWithMultipleProps
            baseLabel={epcTotalPaymentLabel}
            values={epcTotalPaymentDomesticContentElements}
          />
        ) : (
          <ListItemElement
            label={epcTotalPaymentLabel}
            value={currencyFormatter.format(totalSystemCost - totalAdderCost)}
          />
        )}
        {hasBackupBattery && (
          <>
            <ListItemElement
              label="Estimated Battery Year-1 Monthly Payment"
              value={currencyFormatter.format(systemPricingDetails[0].backupBatteryPayment || 0)}
            />
            {totalBackupBatteryCost && (
              <ListItemElement
                label="Backup Battery Total Cost"
                value={currencyFormatter.format(totalBackupBatteryCost)}
              />
            )}
          </>
        )}
        {hasBackupBattery &&
          (totalEPCPaymentElements.length ? (
            <ListItemElementWithMultipleProps baseLabel={'Total EPC Payment'} values={totalEPCPaymentElements} />
          ) : (
            <ListItemElement label="Total EPC Payment" value={currencyFormatter.format(totalSystemCost)} />
          ))}
        <ListItemElement
          label={!isHVACQuote ? 'Total Solar Lease Monthly Payments' : 'Total Lease Monthly Payments'}
          value={currencyFormatter.format(totalAmountPaid)}
        />
      </Box>
    </Details>
  );
}
