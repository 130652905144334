export enum domesticContentStatusKeys {
  approvedSolarOnly = 'approvedSolarOnly',
  rejectedSolarOnly = 'rejectedSolarOnly',
  approvedStorageOnly = 'approvedStorageOnly',
  rejectedStorageOnly = 'rejectedStorageOnly',
  approvedSolarAndStorage = 'approvedSolarAndStorage',
  rejectedSolarAndStorage = 'rejectedSolarAndStorage',
}

export enum DomesticContentApprovalReasons {
  approvedSolarOnly = 'Approved Solar Only',
  approvedStorageOnly = 'Approved Storage Only',
  approvedSolarAndStorage = 'Approved Solar + Storage',
}

export enum DomesticContentRejectionReasons {
  rejectedSolarOnly = 'Rejected Solar Only',
  rejectedStorageOnly = 'Rejected Storage Only',
  rejectedSolarAndStorage = 'Rejected Solar + Storage',
}

export enum DomesticContentReviewStatusForSolarOnly {
  approvedSolarOnly = DomesticContentApprovalReasons.approvedSolarOnly,
  rejectedSolarOnly = DomesticContentRejectionReasons.rejectedSolarOnly,
}

export enum DomesticContentReviewStatusForStorageOnly {
  approvedStorageOnly = DomesticContentApprovalReasons.approvedStorageOnly,
  rejectedStorageOnly = DomesticContentRejectionReasons.rejectedStorageOnly,
}

export enum DomesticContentReviewStatusForSolarAndStorage {
  approvedSolarAndStorage = DomesticContentApprovalReasons.approvedSolarAndStorage,
  rejectedSolarAndStorage = DomesticContentRejectionReasons.rejectedSolarAndStorage,
  rejectedStorageOnly = DomesticContentReviewStatusForStorageOnly.rejectedStorageOnly,
  rejectedSolarOnly = DomesticContentReviewStatusForSolarOnly.rejectedSolarOnly,
}

export enum AttestmentTypeLabelMap {
  pvOnly = 'Solar Only',
  storageOnly = 'Storage Only',
  pvAndStorage = 'Solar + Storage',
}
