import { Helmet } from 'react-helmet';
import { Alert, Box, BoxProps, Button, Modal, toast, useOpenClose } from '@palmetto/palmetto-components';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Formik, FormikValues } from 'formik';
import * as yup from 'yup';
import { useCallback } from 'react';
import { useGetAccountQuery } from '../../services/accounts';
import { MainContainer } from '../MainContainer';
import { ReviewPackageActions } from '../../types/ReviewPackage';
import { ProofOfPto } from '../ActivationMilestonePackage/ProofOfPto';
import PageHeader from '../PageHeader';
import AdminStipulationBannerList from '../Stipulations/AdminStipulationBannerList';
import { useSubmitPtoPackageMutation } from '@/services/activationPackage';
import { isErrorWithData } from '@/services/helpers';
import { useGetQueueByAccountIdAndTypeQuery } from '@/services/queues';
import { QueueType, QueueStatus } from 'types';

const boxStyle: BoxProps = {
  direction: {
    base: 'column',
    tablet: 'row',
  },
  alignItems: {
    base: 'stretch',
    tablet: 'flex-end',
  },
  justifyContent: {
    tablet: 'flex-end',
  },
  childGap: 'sm',
  style: { flexShrink: 0 },
  position: 'relative',
  padding: 'lg 0 0 0',
};

const ptoSchema = yup.object({
  ptoGrantedDate: yup.string().required('PTO granted date is required'),
});

export function AdvancePtoForm() {
  const { id: accountId } = useParams<{ id: any }>();
  const navigate = useNavigate();
  const { data: account } = useGetAccountQuery(accountId);
  const { data: ptoQueue } = useGetQueueByAccountIdAndTypeQuery({
    accountId,
    type: QueueType.permissionToOperate,
  });
  const [submit] = useSubmitPtoPackageMutation();
  const hasPtoGreenlight = account?.ptoGreenlight?.greenlightStatus ?? false;

  const showWarning = !hasPtoGreenlight && ptoQueue?.status !== QueueStatus.approved;

  const primaryApplicantName = account?.primaryApplicantName;

  const {
    isOpen: isWarningOpen,
    handleClose: handleCloseWarningModal,
    handleOpen: handleOpenWarningModal,
  } = useOpenClose();

  const submitProofOfPto = useCallback(
    async (values: FormikValues) => {
      try {
        handleCloseWarningModal();
        await submit({
          accountId,
          ptoPackage: {
            ptoGrantedDate: values.ptoGrantedDate,
          },
        }).unwrap();
        toast.success('PTO data submitted');
      } catch (e) {
        console.error(e);
        if (isErrorWithData(e)) {
          const errorMessage = e.data.message;
          toast.error(errorMessage);
        } else {
          toast.error('Error submitting PTO data');
        }
      }
    },
    [handleCloseWarningModal],
  );

  const skip = useCallback(() => {
    navigate(`/accounts/${accountId}/activation-package/${ReviewPackageActions.create}`);
  }, [navigate]);

  const goBack = useCallback(() => {
    navigate(`/accounts/${accountId}`);
  }, [navigate]);

  return (
    <>
      <Helmet>
        <title>Proof of PTO</title>
      </Helmet>
      <AdminStipulationBannerList />
      <PageHeader
        eyebrow={<Link to={`/accounts/${accountId}`}>{primaryApplicantName}</Link>}
        title="Submit Proof of Permission to Operate"
      />
      <MainContainer>
        <Formik
          initialValues={{
            ptoGrantedDate: account?.ptoGrantedDate ?? '',
          }}
          validationSchema={ptoSchema}
          onSubmit={submitProofOfPto}
          enableReinitialize={true}
          validateOnChange={true}
        >
          {({ submitForm, isSubmitting }) => (
            <Box>
              {showWarning && (
                <Alert
                  variant="warning"
                  hasIcon
                  title="PTO Greenlight Not Granted"
                  message={
                    <Box childGap="md">
                      <Box>
                        Starting in Q2, LightReach reserves the right to net fund the tax credit amount from EPC
                        milestone payment if the job achieves PTO from the Utility before Greenlight is given by
                        LightReach.
                      </Box>
                      <Box>To submit other portions of the Activation Package, click the Skip button below.</Box>
                    </Box>
                  }
                />
              )}
              <ProofOfPto showSubmit={true} onSubmit={handleOpenWarningModal} />
              <Box
                {...boxStyle}
                width={{
                  base: '100',
                  tablet: 'auto',
                }}
              >
                <Button onClick={goBack} variant="secondary" tone="neutral" isLoading={isSubmitting}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={skip}
                  isLoading={isSubmitting}
                  iconSuffix={hasPtoGreenlight ? undefined : 'arrow-right'}
                  data-testid="frm-foot-check-cta"
                >
                  {hasPtoGreenlight ? 'Next' : 'Skip'}
                </Button>
              </Box>
              <Modal
                isOpen={isWarningOpen}
                onDismiss={handleCloseWarningModal}
                maxWidth="4xl"
                ariaLabelledBy="warningModalProceed"
              >
                <Modal.Header
                  id="ptoWarningModal"
                  title="PTO Greenlight Warning"
                  onDismiss={handleCloseWarningModal}
                />
                <Modal.Body background="secondary" childGap="lg">
                  <Box
                    fontSize={'sm'}
                    color="body-primary"
                    data-testid="pto-warning-modal-body"
                    justifyContent={'center'}
                    alignItems={'center'}
                    display={'flex'}
                    direction={'row'}
                    aria-label="warning-modal"
                  >
                    I understand that this project has not been granted PTO Greenlight. As our team applied for PTO
                    prior to receiving approval, this project may be subject to a payment net funding.
                  </Box>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="primary"
                    tone="primary"
                    onClick={submitForm}
                    id="warningModalProceed"
                    isLoading={isSubmitting}
                  >
                    <Box>OK</Box>
                  </Button>
                </Modal.Footer>
              </Modal>
            </Box>
          )}
        </Formik>
      </MainContainer>
    </>
  );
}
