import { Box, Button, Card, Column, Table, useBreakpoint, useOpenClose } from '@palmetto/palmetto-components';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ItcModifierFormatType,
  ModifierType,
  ProgramType,
  modifierMap,
  UserPermissions,
  ItcModifierSystemType,
} from 'types';
import { useLazyGetItcModifiersQuery } from '../../../services/pricing';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { kwhPriceFormatter } from '../../../helpers/currencyFormatter';
import RequirePalmettoFinanceUser from '../../auth/RequirePalmettoFinanceUser';
import RequirePermissions from '../../auth/requirePermissions';
import { EditITCModifierModal } from './EditITCModifierModal';
import { percentageFormatter } from '../../../helpers/percentageFormatter';

export const formatITCModifierData = (modifier: any) =>
  modifier?.format === ItcModifierFormatType.percent
    ? `${modifier.value.toFixed(2)}%`
    : `$${modifier?.value.toFixed(2)}/W`;

export const SpecificITCModifier = () => {
  const { isPhone } = useBreakpoint();
  const [selected, setSelected] = useState<any>(null);
  const { programType, modifierType } = useParams<{ programType: string; modifierType: ModifierType }>();
  const [trigger, { data = [], isLoading, isFetching }] = useLazyGetItcModifiersQuery();
  const { isOpen: isEditModalOpen, handleOpen: openEditModal, handleClose: closeEditModal } = useOpenClose();
  const navigate = useNavigate();

  const handleClick = useCallback(
    (row: any) => {
      setSelected(row);
      openEditModal();
    },
    [openEditModal],
  );

  const columnConfig = useMemo(() => {
    switch (modifierType) {
      case ModifierType.energyCommunity:
        return [
          {
            heading: 'Name',
            dataKey: 'name',
            render: (_cell: any, row: any) => (
              <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => handleClick(row)}>
                {row.name}
              </span>
            ),
          },
          {
            heading: 'PV Only',
            render: (_cell: any, row: any) => {
              const pv = row.data.find((d: any) => d.systemType === ItcModifierSystemType.pvOnly);
              if (!pv || !pv?.coalTract) return '';
              return (
                <Box childGap="sm">
                  <Box direction="row" childGap="xs">
                    <Box fontWeight="bold">Coal Closure:</Box> {formatITCModifierData(pv.coalTract)}
                  </Box>
                  <Box direction="row" childGap="xs">
                    <Box fontWeight="bold">Fossil Fuel: </Box>
                    {formatITCModifierData(pv.fossilFuel)}
                  </Box>
                  <Box direction="row" childGap="xs">
                    <Box fontWeight="bold">Both:</Box> {formatITCModifierData(pv.combined)}
                  </Box>
                </Box>
              );
            },
          },
          {
            heading: 'Storage Only',
            render: (_cell: any, row: any) => {
              const st = row.data.find((d: any) => d.systemType === ItcModifierSystemType.storageOnly);
              if (!st || !st?.coalTract) return '';
              return (
                <Box childGap="sm">
                  <Box direction="row" childGap="xs">
                    <Box fontWeight="bold">Coal Closure:</Box> {formatITCModifierData(st.coalTract)}
                  </Box>
                  <Box direction="row" childGap="xs">
                    <Box fontWeight="bold">Fossil Fuel: </Box>
                    {formatITCModifierData(st.fossilFuel)}
                  </Box>
                  <Box direction="row" childGap="xs">
                    <Box fontWeight="bold">Both:</Box> {formatITCModifierData(st.combined)}
                  </Box>
                </Box>
              );
            },
          },
          {
            heading: 'PV and Storage',
            render: (_cell: any, row: any) => {
              const pvstor = row.data.find((d: any) => d.systemType === ItcModifierSystemType.pvAndStorage);
              if (!pvstor || !pvstor?.coalTract) return '';

              return (
                <Box childGap="sm">
                  <Box direction="row" childGap="xs">
                    <Box fontWeight="bold">Coal Closure:</Box> {formatITCModifierData(pvstor.coalTract)}
                  </Box>
                  <Box direction="row" childGap="xs">
                    <Box fontWeight="bold">Fossil Fuel: </Box>
                    {formatITCModifierData(pvstor.fossilFuel)}
                  </Box>
                  <Box direction="row" childGap="xs">
                    <Box fontWeight="bold">Both:</Box> {formatITCModifierData(pvstor.combined)}
                  </Box>
                </Box>
              );
            },
          },
        ];
      case ModifierType.domesticContent:
        return [
          {
            heading: 'Name',
            dataKey: 'name',
            render: (_cell: any, row: any) => (
              <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => handleClick(row)}>
                {row.name}
              </span>
            ),
          },
          {
            heading: 'PV Only',
            render: (_cell: any, row: any) => {
              const pv = row.data.find((d: any) => d.systemType === ItcModifierSystemType.pvOnly);
              if (!pv || !pv?.modifier) return '';
              const netValue = pv.modifier.value - pv.holdback.value;
              const net =
                pv?.modifier?.format === ItcModifierFormatType.percent
                  ? `${netValue.toFixed(2)}%`
                  : `$${netValue.toFixed(2)}/W`;
              return (
                <Box childGap="sm">
                  <Box direction="row" childGap="xs">
                    <Box fontWeight="bold">Modifier:</Box> {formatITCModifierData(pv.modifier)}
                  </Box>
                  <Box direction="row" childGap="xs">
                    <Box fontWeight="bold">Holdback: </Box>
                    {formatITCModifierData(pv.holdback)}
                  </Box>
                  <Box direction="row" childGap="xs">
                    <Box fontWeight="bold">Net:</Box> {net}
                  </Box>
                </Box>
              );
            },
          },
          {
            heading: 'Storage Only',
            render: (_cell: any, row: any) => {
              const st = row.data.find((d: any) => d.systemType === ItcModifierSystemType.storageOnly);
              if (!st || !st?.modifier) return '';
              const netValue = st.modifier.value - st.holdback.value;
              const net =
                st?.modifier?.format === ItcModifierFormatType.percent
                  ? `${netValue.toFixed(2)}%`
                  : `$${netValue.toFixed(2)}/W`;
              return (
                <Box childGap="sm">
                  <Box direction="row" childGap="xs">
                    <Box fontWeight="bold">Modifier:</Box> {formatITCModifierData(st.modifier)}
                  </Box>
                  <Box direction="row" childGap="xs">
                    <Box fontWeight="bold">Holdback: </Box>
                    {formatITCModifierData(st.holdback)}
                  </Box>
                  <Box direction="row" childGap="xs">
                    <Box fontWeight="bold">Net:</Box> {net}
                  </Box>
                </Box>
              );
            },
          },
          {
            heading: 'PV and Storage',
            render: (_cell: any, row: any) => {
              const pvStor = row.data.find((d: any) => d.systemType === ItcModifierSystemType.pvAndStorage);
              if (!pvStor || !pvStor?.modifier) return '';
              const netValue = pvStor.modifier.value - pvStor.holdback.value;
              const net =
                pvStor?.modifier?.format === ItcModifierFormatType.percent
                  ? `${netValue.toFixed(2)}%`
                  : `$${netValue.toFixed(2)}/W`;
              return (
                <Box childGap="sm">
                  <Box direction="row" childGap="xs">
                    <Box fontWeight="bold">Modifier:</Box> {formatITCModifierData(pvStor.modifier)}
                  </Box>
                  <Box direction="row" childGap="xs">
                    <Box fontWeight="bold">Holdback: </Box>
                    {formatITCModifierData(pvStor.holdback)}
                  </Box>
                  <Box direction="row" childGap="xs">
                    <Box fontWeight="bold">Net:</Box> {net}
                  </Box>
                </Box>
              );
            },
          },
        ];
      default:
        return [];
    }
  }, [handleClick, modifierType]);

  useEffect(() => {
    if (modifierType) {
      trigger({ type: modifierType, programType: programType as ProgramType });
    }
  }, [modifierType, trigger]);

  return (
    <>
      <EditITCModifierModal
        isModalOpen={isEditModalOpen}
        closeModal={closeEditModal}
        id={selected?.id}
        name={selected?.name}
      />
      <Card padding="sm">
        <Card.Header>
          <Box direction="row" justifyContent="space-between">
            <Box fontSize="md" fontWeight="medium">
              {modifierMap[modifierType as ModifierType]}
            </Box>
            <RequirePalmettoFinanceUser>
              <RequirePermissions permissions={[UserPermissions.pricingAdmin]}>
                <Button
                  variant="primary"
                  iconPrefix="add"
                  size={{ base: 'sm', desktop: 'md' }}
                  aria-label="create modifier"
                  onClick={() => navigate(`/admin/pricing/${programType}/itc-modifiers/${modifierType}/create`)}
                >
                  {!isPhone && 'Option'}
                </Button>
              </RequirePermissions>
            </RequirePalmettoFinanceUser>
          </Box>
        </Card.Header>
        <Table rowKey="id" columns={columnConfig as Column[]} rows={data} isLoading={isLoading || isFetching} />
      </Card>
    </>
  );
};
