import { useParams } from 'react-router-dom';
import {
  useDeleteOrganizationDomesticContentMutation,
  useGetOrganizationDomesticContentQuery,
} from '../../../../../services/pricing';
import { Box, Button, Card, Column, Table, toast, useBreakpoint, useOpenClose } from '@palmetto/palmetto-components';
import { TableLoading } from '../../../../TableLoading';
import { DomesticContentSetting, ItcModifierFormatType, ItcModifierSystemType } from 'types';
import RequirePricingAdminUser from '@/components/auth/RequirePricingAdminUser';
import { CreateDomesticContent } from './modals/CreateDomesticContent';
import { formatITCModifierData } from '@/components/Pricing/ITCModifiers/SpecificITCModifier';

type DomesticContent = {
  id: string;
  name: string;
  organizationId: string;
  organizationName: string;
  state: string;
  values: DomesticContentSetting[];
};

export const DomesticContent = () => {
  const { alias = '' } = useParams<{ alias: string }>();
  const { isPhone } = useBreakpoint();
  const { isOpen: isModalOpen, handleOpen: openModal, handleClose: closeModal } = useOpenClose();
  const [deleteOrganizationDomesticContentMutation] = useDeleteOrganizationDomesticContentMutation();
  const { data, isLoading, isFetching } = useGetOrganizationDomesticContentQuery(
    { alias },
    {
      skip: !alias,
    },
  );

  const handleArchive = async (id: string) => {
    try {
      await deleteOrganizationDomesticContentMutation({ alias, id }).unwrap();
      toast.success('Domestic Content Archived successfully');
    } catch (e) {
      console.log(e);
      toast.error('Error archiving Domestic Content');
    }
  };

  const columnConfig: Column[] = [
    { heading: 'Name', dataKey: 'name' },
    { heading: 'org', dataKey: 'organizationName' },
    { heading: 'State', dataKey: 'state' },
    {
      heading: 'PV Only',
      render: (_cell: any, row: any) => {
        const pv = row?.values?.find((d: any) => d.systemType === ItcModifierSystemType.pvOnly);
        if (!pv || !pv?.modifier) return '';
        const netValue = pv.modifier.value - pv.holdback.value;
        const net =
          pv?.modifier?.format === ItcModifierFormatType.percent
            ? `${netValue.toFixed(2)}%`
            : `$${netValue.toFixed(2)}/W`;
        return (
          <Box childGap="sm">
            <Box direction="row" childGap="xs">
              <Box fontWeight="bold">Modifier:</Box> {formatITCModifierData(pv.modifier)}
            </Box>
            <Box direction="row" childGap="xs">
              <Box fontWeight="bold">Holdback: </Box>
              {formatITCModifierData(pv.holdback)}
            </Box>
            <Box direction="row" childGap="xs">
              <Box fontWeight="bold">Net:</Box> {net}
            </Box>
          </Box>
        );
      },
    },
    {
      heading: 'Storage Only',
      render: (_cell: any, row: any) => {
        const st = row?.values?.find((d: any) => d.systemType === ItcModifierSystemType.storageOnly);
        if (!st || !st?.modifier) return '';
        const netValue = st.modifier.value - st.holdback.value;
        const net =
          st?.modifier?.format === ItcModifierFormatType.percent
            ? `${netValue.toFixed(2)}%`
            : `$${netValue.toFixed(2)}/W`;
        return (
          <Box childGap="sm">
            <Box direction="row" childGap="xs">
              <Box fontWeight="bold">Modifier:</Box> {formatITCModifierData(st.modifier)}
            </Box>
            <Box direction="row" childGap="xs">
              <Box fontWeight="bold">Holdback: </Box>
              {formatITCModifierData(st.holdback)}
            </Box>
            <Box direction="row" childGap="xs">
              <Box fontWeight="bold">Net:</Box> {net}
            </Box>
          </Box>
        );
      },
    },
    {
      heading: 'PV and Storage',
      render: (_cell: any, row: any) => {
        const pvStor = row?.values?.find((d: any) => d.systemType === ItcModifierSystemType.pvAndStorage);
        if (!pvStor || !pvStor?.modifier) return '';
        const netValue = pvStor.modifier.value - pvStor.holdback.value;
        const net =
          pvStor?.modifier?.format === ItcModifierFormatType.percent
            ? `${netValue.toFixed(2)}%`
            : `$${netValue.toFixed(2)}/W`;
        return (
          <Box childGap="sm">
            <Box direction="row" childGap="xs">
              <Box fontWeight="bold">Modifier:</Box> {formatITCModifierData(pvStor.modifier)}
            </Box>
            <Box direction="row" childGap="xs">
              <Box fontWeight="bold">Holdback: </Box>
              {formatITCModifierData(pvStor.holdback)}
            </Box>
            <Box direction="row" childGap="xs">
              <Box fontWeight="bold">Net:</Box> {net}
            </Box>
          </Box>
        );
      },
    },
    {
      heading: '',
      render: (_cell: any, row: any) => (
        <RequirePricingAdminUser>
          <Button
            size="sm"
            variant="secondary"
            tone="neutral"
            onClick={() => {
              handleArchive(row.id);
            }}
          >
            Archive
          </Button>
        </RequirePricingAdminUser>
      ),
    },
  ];
  return (
    <>
      <CreateDomesticContent isModalOpen={isModalOpen} closeModal={closeModal} />
      <Card.Header>
        <RequirePricingAdminUser>
          <Box direction="row" justifyContent="space-between">
            <Box />
            <Button
              as="button"
              variant="primary"
              iconPrefix="add"
              size={{ base: 'sm', desktop: 'md' }}
              aria-label="create adder"
              onClick={() => openModal()}
            >
              {!isPhone && 'Domestic Content'}
            </Button>
          </Box>
        </RequirePricingAdminUser>
      </Card.Header>
      <Box margin="0 lg 0 lg">
        {isFetching || isLoading ? (
          <TableLoading rows={12} />
        ) : (
          <Table
            rowKey="id"
            columns={columnConfig as Column[]}
            rows={data?.domesticContentModifiers ?? []}
            isLoading={isLoading || isFetching}
            isScrollable={{ y: true }}
            hasStickyHeader
          />
        )}
      </Box>
    </>
  );
};
