import { Helmet } from 'react-helmet';
import { Box, Button, RadioGroup, toast, useOpenClose } from '@palmetto/palmetto-components';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useGetAccountQuery } from '../../services/accounts';

import PageHeader from '../PageHeader';
import { useEffect, useState } from 'react';
import { useCreateSystemDesignMutation } from '@/services/systemDesign';
import { DomesticContentAttestmentModal } from './DomesticContentAttestmentModal';
import { isDomesticContentRequirementRejectedForAccount } from '@/helpers/isDomesticContentRequirementRejectedForAccount';
import { AdderType, DocumentRejectionReasons, ItcModifierSystemType, QueueType, QuoteStatus } from 'types';
import { useGetQueueByAccountIdAndTypeQuery } from '@/services/queues';
import { useGetQuotesQuery } from '../../services/quotes';
import { DomesticContentRejectionReasons } from 'types';

export const DomesticContentAttestmentModalLabels = {
  pvOnly: {
    label: 'Submit for Solar DC Review',
    description:
      'I confirm that this project meets LightReach Domestic Content guidance for solar components to the best of my knowledge.',
  },
  storageOnly: {
    label: 'Submit for Storage DC Review',
    description:
      'I confirm that this project meets LightReach Domestic Content guidance for storage components to the best of my knowledge.',
  },
  pvAndStorage: {
    label: 'Submit for Solar+Storage DC Review',
    description:
      'I confirm that this project meets LightReach Domestic Content guidance for both solar and storage components to the best of my knowledge.',
  },
  optOut: {
    label: 'Opt out of DC Boost for this Project',
    description:
      'I understand that this project will be eligible for Milestone Payments based off the non-Domestic Content value.',
  },
};
export type DcAttestmentType = ItcModifierSystemType | 'optOut';

export const DcAttestmentType = {
  ...ItcModifierSystemType,
  optOut: 'optOut' as const,
} as const;

export function DomesticContentValueConfirmation() {
  const { id: accountId } = useParams<{ id: any }>();
  const navigate = useNavigate();
  const { data: account }: any = useGetAccountQuery(accountId);

  const [createSystemDesign, { isLoading: isCreatingSystemDesign }] = useCreateSystemDesignMutation();
  const { data: domesticContentQueue } = useGetQueueByAccountIdAndTypeQuery({
    accountId: accountId,
    type: QueueType.domesticContent,
  });

  const [selectedAttestmentType, setSelectedAttestmentType] = useState<DcAttestmentType | null>(null);
  const [attestmentModalConfirmationLabel, setAttestmentModalConfirmationLabel] = useState('');
  const [attestmentModalConfirmationDescription, setAttestmentModalConfirmationDescription] = useState('');
  const [isResubmitDomesticContentEnabled, setIsResubmitDomesticContentEnabled] = useState(false);

  const { data: quotes } = useGetQuotesQuery(
    { id: accountId, programType: account?.programType },
    { skip: !account?.programType },
  );

  const activeQuote = quotes?.find((quote) => quote.status === QuoteStatus.active);
  const hasStorage = activeQuote?.adders?.find(
    (adder: any) =>
      adder === AdderType.arbitrageBattery ||
      adder === AdderType.backupBattery ||
      adder === AdderType.backupBatteryKwh,
  );

  const attestmentOptions = hasStorage
    ? [
        { id: 'pvOnly', value: DcAttestmentType.pvOnly, label: DomesticContentAttestmentModalLabels.pvOnly.label },
        {
          id: 'storageOnly',
          value: DcAttestmentType.storageOnly,
          label: DomesticContentAttestmentModalLabels.storageOnly.label,
        },
        {
          id: 'pvAndStorage',
          value: DcAttestmentType.pvAndStorage,
          label: DomesticContentAttestmentModalLabels.pvAndStorage.label,
        },
        { id: 'optOut', value: DcAttestmentType.optOut, label: DomesticContentAttestmentModalLabels.optOut.label },
      ]
    : [
        { id: 'pvOnly', value: DcAttestmentType.pvOnly, label: DomesticContentAttestmentModalLabels.pvOnly.label },
        { id: 'optOut', value: DcAttestmentType.optOut, label: DomesticContentAttestmentModalLabels.optOut.label },
      ];

  const handleAttestmentTypeChange = (value: string) => {
    const type = value as DcAttestmentType;
    setSelectedAttestmentType(type);
    const { label, description } = DomesticContentAttestmentModalLabels[type];
    setAttestmentModalConfirmationLabel(label);
    setAttestmentModalConfirmationDescription(description);
  };

  const {
    isOpen: isAttestmentModalOpen,
    handleClose: handleCloseAttestmentModal,
    handleOpen: handleOpenAttestmentModal,
  } = useOpenClose();

  const handleOnDomesticContentConfirmation = async () => {
    if (!selectedAttestmentType) {
      toast.error('Please select an attestment type');
      return;
    }

    try {
      await createSystemDesign({
        systemDesign: {
          ...account.systemDesign,
          domesticContentAttestment: {
            valueConfirmation: selectedAttestmentType !== DcAttestmentType.optOut,
            ...(selectedAttestmentType !== DcAttestmentType.optOut && { attestmentType: selectedAttestmentType }),
            ...(isResubmitDomesticContentEnabled && { resubmission: true }),
          },
        },
        accountId: account.id,
      }).unwrap();

      if (isResubmitDomesticContentEnabled) {
        navigate(`/accounts/${accountId}/installation-package/edit`, { replace: true });
      } else {
        navigate(`/accounts/${accountId}/installation-package/create`, { replace: true });
      }
    } catch (error) {
      toast.error((error as any)?.data?.message);
    }
  };

  const getRejectionReason = (reasonKey: string | undefined): string => {
    if (!reasonKey) return DocumentRejectionReasons.other;
    return (
      DocumentRejectionReasons[reasonKey as keyof typeof DocumentRejectionReasons] ||
      DomesticContentRejectionReasons[reasonKey as keyof typeof DomesticContentRejectionReasons] ||
      DocumentRejectionReasons.other
    );
  };

  useEffect(() => {
    if (!account || !accountId) return;

    const isDomesticContentRequirementRejected = isDomesticContentRequirementRejectedForAccount(account);

    if (isDomesticContentRequirementRejected) {
      setIsResubmitDomesticContentEnabled(true);
    }
  }, [accountId, navigate, account]);

  return (
    <>
      <Helmet>
        <title>Domestic Content Value Confirmation</title>
      </Helmet>

      <PageHeader
        eyebrow={<Link to={`/accounts/${accountId}`}>{account?.primaryApplicantName}</Link>}
        title={'Domestic Content Value Confirmation'}
      />

      <Box
        childGap={{
          base: 'lg',
        }}
        padding={{
          base: 'md',
          desktop: '0',
        }}
        width={{ base: '100', desktop: '50' }}
        display="block"
      >
        <Box padding="lg 0 0 0">
          {isResubmitDomesticContentEnabled ? (
            <Box fontSize="sm" childGap="md" color="body-secondary">
              <p>
                LightReach review determined that this project does not satisfy the Domestic Content equipment
                thresholds for the following reason(s):{' '}
              </p>
              <Box childGap="2xs" as="ul">
                {domesticContentQueue?.rejectionReasons?.map((reason: any, index: number) => (
                  <Box key={index}>
                    <li>{getRejectionReason(reason?.reasons[0])}</li>
                    {reason?.note && <Box>{reason.note}</Box>}
                  </Box>
                ))}
              </Box>
              <RadioGroup
                title="Domestic Content Attestation"
                name="selectDcAttestmentType"
                value={selectedAttestmentType || ''}
                options={attestmentOptions.map((option) => ({
                  ...option,
                  label: <Box fontSize="md">{option.label}</Box>,
                }))}
                onChange={(event) => handleAttestmentTypeChange(event.target.value)}
                size="lg"
              />
              <Box as="p" color="body-secondary" fontSize="sm">
                I may re-submit for Domestic Content review again, but by doing so, I understand that the entire
                Installation Package will be rejected if Domestic Content is not met. I understand that I must
                provide additional proof as to why I believe this project does satisfy the LightReach Domestic
                Content requirements in the "Domestic Content Documents" section of the Installation Package.
              </Box>
            </Box>
          ) : (
            <Box childGap="md">
              <RadioGroup
                name="selectDcAttestmentType"
                value={selectedAttestmentType || ''}
                options={attestmentOptions.map((option) => ({
                  ...option,
                  label: <Box fontSize="md">{option.label}</Box>,
                }))}
                onChange={(event) => handleAttestmentTypeChange(event.target.value)}
                size="lg"
              />
              <Box as="p" color="body-secondary" fontSize="sm">
                {selectedAttestmentType === DcAttestmentType.optOut
                  ? 'If submitting this Install Package and opting out of Domestic Content review, I understand that the project will NOT be reviewed for Domestic Content and this project will be paid out at the Non-DC values.'
                  : 'If submitting for Domestic Content Review, based on the final system design, we believe this project will satisfy the LightReach Domestic Content threshold for the current IRA Program Year. By submitting for Domestic Content value, I understand that the entire Installation Package will be rejected if Domestic Content is not met.'}
              </Box>
            </Box>
          )}
        </Box>

        <Box
          direction={{
            base: 'column',
            tablet: 'row',
          }}
          justifyContent={{
            tablet: 'flex-end',
          }}
          childGap="sm"
        >
          <Button
            as="a"
            navigate={() => navigate(`/accounts/${accountId}`)}
            variant="secondary"
            tone="neutral"
            size="md"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            size="md"
            onClick={() => handleOpenAttestmentModal()}
            isLoading={isCreatingSystemDesign}
            isDisabled={!selectedAttestmentType}
          >
            Confirm
          </Button>
        </Box>
        <DomesticContentAttestmentModal
          isOpen={isAttestmentModalOpen}
          onDismiss={handleCloseAttestmentModal}
          onAttestmentConfirmed={handleOnDomesticContentConfirmation}
          confirmationLabel={attestmentModalConfirmationLabel}
          confirmationDescription={attestmentModalConfirmationDescription}
          isLoading={isCreatingSystemDesign}
        />
      </Box>
    </>
  );
}
