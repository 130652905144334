import { DateTime } from 'luxon';
import { Box, Button, Modal, useOpenClose, toast, TextInput } from '@palmetto/palmetto-components';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { useEditAccountMutation, useGetAccountQuery } from '../../services/accounts';
import DateTimeDisplay from '../DateTime';
import { DATE_FORMAT, convertToISODateString } from '../../helpers/dates';

interface ManageProjectDatesProps {
  handleClose: () => void;
  accountData: any;
  accountId: string;
}

const ManageProjectDates = ({ handleClose, accountData, accountId }: ManageProjectDatesProps) => {
  const [editAccount] = useEditAccountMutation();
  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    try {
      const updateAccountData: Record<string, any> = {
        permitSubmittedDate: {
          ...accountData?.permitSubmittedDate,
          palmetto: values.permitSubmittedDate && convertToISODateString(values.permitSubmittedDate),
        },
        permitApprovedDate: {
          ...accountData?.permitApprovedDate,
          palmetto: values.permitApprovedDate && convertToISODateString(values.permitApprovedDate),
        },
        installScheduledDate: {
          ...accountData?.installScheduledDate,
          palmetto: values.installScheduledDate && convertToISODateString(values.installScheduledDate),
        },
        expectedPTODate: {
          ...accountData?.expectedPTODate,
          palmetto: values.expectedPTODate && convertToISODateString(values.expectedPTODate),
        },
      };

      await editAccount({
        account: { ...updateAccountData },
        id: accountId,
      }).unwrap();

      toast.success('Project Dates Saved');
      handleClose();
    } catch (e: any) {
      toast.error(e?.data?.message || 'Error saving account project dates');
    }
    setSubmitting(false);
  };

  const initialValues = useMemo(() => {
    return {
      permitSubmittedDate: accountData?.permitSubmittedDate?.palmetto
        ? DateTime.fromISO(accountData?.permitSubmittedDate?.palmetto).toUTC().toFormat(DATE_FORMAT)
        : undefined,
      permitApprovedDate: accountData?.permitApprovedDate?.palmetto
        ? DateTime.fromISO(accountData?.permitApprovedDate?.palmetto).toUTC().toFormat(DATE_FORMAT)
        : undefined,
      installScheduledDate: accountData?.installScheduledDate?.palmetto
        ? DateTime.fromISO(accountData?.installScheduledDate?.palmetto).toUTC().toFormat(DATE_FORMAT)
        : undefined,
      expectedPTODate: accountData?.expectedPTODate?.palmetto
        ? DateTime.fromISO(accountData?.expectedPTODate?.palmetto).toUTC().toFormat(DATE_FORMAT)
        : undefined,
    };
  }, [accountData]);

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize={true}>
      {({ isSubmitting, values, setFieldValue }) => (
        <Form noValidate id="manageProjectDatesForm">
          <Modal.Body background="secondary">
            <Box childGap="lg">
              <TextInput
                id="permitSubmittedDate"
                value={values?.permitSubmittedDate}
                label="Permit Submitted Date"
                name="permitSubmittedDate"
                onChange={(event) => setFieldValue('permitSubmittedDate', event.target.value)}
                inputMask="date"
                type="tel"
                placeholder="MM-DD-YYYY"
              />
              <TextInput
                id="permitApprovedDate"
                value={values?.permitApprovedDate}
                label="Permit Approved Date"
                name="permitApprovedDate"
                onChange={(event) => setFieldValue('permitApprovedDate', event.target.value)}
                inputMask="date"
                type="tel"
                placeholder="MM-DD-YYYY"
              />
              <TextInput
                id="installScheduledDate"
                value={values?.installScheduledDate}
                label="Install Scheduled Date"
                name="installScheduledDate"
                onChange={(event) => setFieldValue('installScheduledDate', event.target.value)}
                inputMask="date"
                type="tel"
                placeholder="MM-DD-YYYY"
              />
              <TextInput
                id="expectedPTODate"
                value={values?.expectedPTODate}
                label="Expected PTO Date"
                name="expectedPTODate"
                onChange={(event) => setFieldValue('expectedPTODate', event.target.value)}
                inputMask="date"
                type="tel"
                placeholder="MM-DD-YYYY"
              />
            </Box>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" tone="neutral" size="md" onClick={handleClose} isDisabled={isSubmitting}>
              Cancel
            </Button>
            <Button size="md" type="submit" variant="primary" form="manageProjectDatesForm" isLoading={isSubmitting}>
              Save
            </Button>
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  );
};

const ProjectDates = () => {
  const { id } = useParams<{ id: any }>();
  const { data: accountData, isLoading }: any = useGetAccountQuery(id);
  const { isOpen: isModifyOpen, handleClose: handleManageClose, handleToggle: handleManageToggle } = useOpenClose();

  return (
    <>
      <Modal
        isOpen={isModifyOpen}
        onDismiss={() => {
          handleManageClose();
        }}
        maxWidth="4xl"
        ariaLabelledBy="modifyProjectDatesHeader"
        fullScreenMobile
      >
        <Modal.Header
          id="manageProjectDatesHeader"
          title="Project Dates"
          onDismiss={() => {
            handleManageClose();
          }}
        />
        <ManageProjectDates
          handleClose={() => {
            handleManageClose();
          }}
          accountData={accountData || {}}
          accountId={id}
        />
      </Modal>

      {!isLoading && (
        <Box direction="column" width="100" fontSize="xs">
          <Box
            direction={{ base: 'column', tablet: 'row' }}
            borderColor="separator"
            borderWidth="0 0 xs 0"
            justifyContent="space-between"
            alignItems="center"
            padding="xs 0"
          >
            <Box fontWeight="medium">Permit Submitted</Box>
            <Button
              onClick={handleManageToggle}
              variant="tertiary"
              tone="neutral"
              size="xs"
              aria-label="manage permit submitted date"
              isLoading={isLoading}
            >
              {accountData?.permitSubmittedDate?.palmetto ? (
                <DateTimeDisplay
                  displayLocalDateTime={true}
                  value={accountData?.permitSubmittedDate?.palmetto}
                  excludeTime={true}
                  dateFormat="MM-dd-yyyy"
                />
              ) : (
                'Set Date'
              )}
            </Button>
          </Box>
          <Box
            direction={{ base: 'column', tablet: 'row' }}
            borderColor="separator"
            borderWidth="0 0 xs 0"
            justifyContent="space-between"
            alignItems="center"
            padding="xs 0"
          >
            <Box fontWeight="medium">Permit Approved</Box>
            <Button
              onClick={handleManageToggle}
              variant="tertiary"
              tone="neutral"
              size="xs"
              aria-label="manage permit approved date"
              isLoading={isLoading}
            >
              {accountData?.permitApprovedDate?.palmetto ? (
                <DateTimeDisplay
                  displayLocalDateTime={true}
                  value={accountData?.permitApprovedDate?.palmetto}
                  excludeTime={true}
                  dateFormat="MM-dd-yyyy"
                />
              ) : (
                'Set Date'
              )}
            </Button>
          </Box>
          <Box
            direction={{ base: 'column', tablet: 'row' }}
            borderColor="separator"
            borderWidth="0 0 xs 0"
            justifyContent="space-between"
            alignItems="center"
            padding="xs 0"
          >
            <Box fontWeight="medium">Install Scheduled</Box>
            <Button
              onClick={handleManageToggle}
              variant="tertiary"
              tone="neutral"
              size="xs"
              aria-label="manage install scheduled date"
              isLoading={isLoading}
            >
              {accountData?.installScheduledDate?.palmetto ? (
                <DateTimeDisplay
                  displayLocalDateTime={true}
                  value={accountData?.installScheduledDate?.palmetto}
                  excludeTime={true}
                  dateFormat="MM-dd-yyyy"
                />
              ) : (
                'Set Date'
              )}
            </Button>
          </Box>
          <Box
            direction={{ base: 'column', tablet: 'row' }}
            borderColor="separator"
            borderWidth="0 0 xs 0"
            justifyContent="space-between"
            alignItems="center"
            padding="xs 0"
          >
            <Box fontWeight="medium">Expected PTO Date</Box>
            <Button
              onClick={handleManageToggle}
              variant="tertiary"
              tone="neutral"
              size="xs"
              aria-label="manage install scheduled date"
              isLoading={isLoading}
            >
              {accountData?.expectedPTODate?.palmetto ? (
                <DateTimeDisplay
                  displayLocalDateTime={true}
                  value={accountData?.expectedPTODate?.palmetto}
                  excludeTime={true}
                  dateFormat="MM-dd-yyyy"
                />
              ) : (
                'Set Date'
              )}
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};

export default ProjectDates;
