import { Box, BoxProps, Icon, Popover } from '@palmetto/palmetto-components';
import { useState } from 'react';

export const GetToolTipText = (organizationPlanName?: string) => {
  const baseText = 'The account plan is different from the current org payment plan';
  const orgPlanText = organizationPlanName ? ' (current organization plan: ' + organizationPlanName + ')' : '';
  return `${baseText}${orgPlanText}.`;
};

export function HoverOnlyTooltipComponent({
  content,
  onClose,
  children,
  dislplayedContainerStyle,
  tooltipContainerStyle,
  contentStyle,
}: {
  content: string | null;
  onClose: () => void;
  children: any;
  dislplayedContainerStyle?: BoxProps;
  tooltipContainerStyle?: BoxProps;
  contentStyle?: BoxProps;
}) {
  const hasContent = !!content;
  const [open, setIsOpen] = useState(false);
  const popupCalculatedContainerStyle = {
    background: 'white',
    padding: '1em',
    fontSize: '16px',
    textAlign: 'left',
    color: '#44474B',
    ...(tooltipContainerStyle || {}),
  };
  const contentContainerProps = {
    padding: '0',
    width: '490px',
    shadow: 'lg',
    radius: 'sm',
    overflow: 'hidden',
    background: 'white',
    ...(contentStyle || {}),
  };
  return (
    <Popover
      content={
        <Box as="div" style={(popupCalculatedContainerStyle || {}) as any} className="tooltip-container">
          {content}
        </Box>
      }
      isOpen={!hasContent ? false : open}
      placement="bottom-end"
      offsetFromTarget={8}
      contentContainerProps={contentContainerProps as any}
      onClickOutside={onClose}
    >
      <Box
        onMouseEnter={() => {
          setIsOpen(true);
        }}
        onMouseLeave={() => {
          setIsOpen(false);
        }}
        style={(dislplayedContainerStyle || {}) as any}
      >
        {children}
      </Box>
    </Popover>
  );
}

export const TransactionPlanIdsDifferWarningIcon = ({ organizationPlanName }: { organizationPlanName?: string }) => {
  return (
    <HoverOnlyTooltipComponent
      content={GetToolTipText(organizationPlanName)}
      onClose={() => {}}
      dislplayedContainerStyle={{
        display: 'inline',
      }}
      contentStyle={{
        fontWeight: 'regular',
      }}
    >
      <Icon name="c-warning" />
    </HoverOnlyTooltipComponent>
  );
};
