import { ProjectTransactionClientData } from '@/types/Payouts';
import { getTransactionColumns } from './ProjectTransactionTable';
import { ProjectTransactionDocumentWithOrganizationData } from 'types';
import { Box } from '@palmetto/palmetto-components';
import { Link } from 'react-router-dom';
import { TransactionPlanIdsDifferWarningIcon } from '../Tooltip';

interface ProjectTransactionTableProps {
  handleRowSelected?(ids: string[], selected: boolean): void;
}

export const useGetUserDetailsTransactionColumns = () => {
  const getUserDetailsTransactionColumns = (
    hideColumns: string[],
    handleRowSelected: ProjectTransactionTableProps['handleRowSelected'],
    selectedIds: string[],
    transactions: ProjectTransactionClientData[],
  ) => {
    let baseColumns = getTransactionColumns(hideColumns, handleRowSelected, selectedIds, transactions);
    baseColumns = baseColumns.map((column) => {
      if (column?.dataKey === 'projectName') {
        return {
          ...column,
          render: (cell?: any, row?: ProjectTransactionDocumentWithOrganizationData) => {
            let plansDiffer: boolean;
            if (!row?.planId) {
              plansDiffer = false;
            } else {
              plansDiffer = row?.planId.toString() !== row?.payeePlanId?.toString();
            }
            return (
              <Box fontWeight="medium" as="span" display="inline">
                <Link to={`/accounts/${row?.projectExternalId}/funding`} target="_blank">
                  {cell}{' '}
                  {plansDiffer ? (
                    <TransactionPlanIdsDifferWarningIcon organizationPlanName={row?.organizationPlanName} />
                  ) : null}
                </Link>
              </Box>
            );
          },
        };
      }
      return column;
    });
    return baseColumns;
  };

  return {
    getUserDetailsTransactionColumns,
  };
};
