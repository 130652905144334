import { Helmet } from 'react-helmet';
import { MainContainer } from '../MainContainer';
import { Badge, Box } from '@palmetto/palmetto-components';
import PageHeader from '../PageHeader';
import { FundType } from 'types';
import { useGetFundQuery } from '../../services/funds';
import { Link, NavLink, Route, Routes, useParams } from 'react-router-dom';
import { FundOverview } from './FundOverview';
import { FundHistory } from './FundHistory';

export function Fund() {
  const { key } = useParams<{ key: any }>();
  const { data: fund = {} } = useGetFundQuery(key);

  const fundTypeDisplayName = {
    [FundType.strandedAsset]: 'Stranded Asset',
    [FundType.taxEquity]: 'Tax Equity',
    [FundType.debt]: 'Debt',
    [FundType.jointStrandedAsset]: 'Joint Stranded Asset',
  };

  return (
    <>
      <Helmet>
        <title>Fund</title>
      </Helmet>
      <MainContainer>
        <PageHeader
          eyebrow={<Link to="/admin/funds">Funds</Link>}
          title={fund?.description ?? '...'}
          description={
            fund && (
              <Box direction="row">
                <Badge
                  variant="info"
                  message={fundTypeDisplayName[fund.fundType as FundType]}
                  size={{ base: 'md', desktop: 'lg' }}
                />
              </Box>
            )
          }
        />
        <Box direction="row" borderWidth="0 0 xs 0" borderColor="separator">
          <NavLink to={`/admin/funds/${key}`} style={{ textDecoration: 'none', fontWeight: '500' }} end>
            {({ isActive }) => (
              <Box
                as="span"
                padding="md"
                className={isActive ? 'tab-active' : ''}
                hover={{ color: 'primary' }}
                style={{ transitionDuration: '0s' }}
              >
                Overview
              </Box>
            )}
          </NavLink>
          <NavLink to={`/admin/funds/${key}/history`} style={{ textDecoration: 'none', fontWeight: '500' }}>
            {({ isActive }) => (
              <Box
                as="span"
                padding="md"
                className={isActive ? 'tab-active' : ''}
                hover={{ color: 'primary' }}
                style={{ transitionDuration: '0s' }}
              >
                History
              </Box>
            )}
          </NavLink>
        </Box>
        <Routes>
          <Route path="/" element={<FundOverview />} />
          <Route path="/history" element={<FundHistory />} />
        </Routes>
      </MainContainer>
    </>
  );
}
