import { CreateProjectTransactionPayload, PayeeType, ProgramType, ProjectTransactionStatus } from 'types';
import {
  useGetProjectTransactionsQuery,
  useAddTransactionToBatchMutation,
  useCreateProjectTransactionMutation,
} from '@/services/payouts';
import { Box, Button, Card, toast, useOpenClose } from '@palmetto/palmetto-components';
import { FilterableProjectTransactionTable } from '../FilterableProjectTransactionTable';
import { useCallback, useState } from 'react';
import { AddTransactionToBatchModal } from './AddTransactionToBatchModal';
import { isErrorWithData, isErrorWithErrors } from '@/services/helpers';
import { AddProjectTransactionModal } from './AddProjectTransactionModal';

export const UnbatchedTransactions = ({ programType }: { programType: ProgramType }) => {
  const { data: transactions, isLoading: isTransactionsLoading } = useGetProjectTransactionsQuery({
    programType,
    status: ProjectTransactionStatus.open,
  });
  const [addTransactionToBatch, { isLoading }] = useAddTransactionToBatchMutation();
  const {
    isOpen: isAddProjectTransactionToBatchModalOpen,
    handleOpen: handleOpenAddProjectTransactionToBatchModal,
    handleClose: handleCloseAddProjectTransactionToBatchModal,
  } = useOpenClose();

  const {
    isOpen: isAddProjectTransactionModalOpen,
    handleOpen: handleOpenAddProjectTransactionModal,
    handleClose: handleCloseAddProjectTransactionModal,
  } = useOpenClose();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [createTransaction] = useCreateProjectTransactionMutation();
  const handleAddTransactionToBatch = useCallback(async () => {
    try {
      // todo should there be a check for if there are no selectedIds?
      await addTransactionToBatch({ transactionIds: selectedIds }).unwrap();
      handleCloseAddProjectTransactionModal();
      toast.success('Transaction added successfully');
    } catch (error) {
      if (isErrorWithErrors(error)) {
        const errorMessage = error.data.message;
        toast.error(errorMessage);
      } else if (isErrorWithData(error)) {
        const errorMessage = error.data.message;
        toast.error(errorMessage);
      } else {
        console.error(error);
        toast.error('Could not add transaction(s) to batch');
      }
    }
  }, [selectedIds, addTransactionToBatch, handleCloseAddProjectTransactionModal]);

  const handleAddTransaction = useCallback(async (values: CreateProjectTransactionPayload) => {
    try {
      await createTransaction({
        ...values,
        ...(values.clawbackCategory
          ? { description: `${values.clawbackCategory} - ${values.description ?? ''}` }
          : {}),
      }).unwrap();
      handleCloseAddProjectTransactionModal();
      toast.success('Transaction added successfully');
    } catch (error) {
      console.log(error);
      toast.error('Could not add transaction');
    }
  }, []);

  const handleRowSelected = useCallback(
    (ids: string[], selected: boolean) => {
      if (selected) {
        setSelectedIds([...new Set([...selectedIds, ...ids])]);
      } else {
        setSelectedIds(selectedIds.filter((selectedId) => !ids.includes(selectedId)));
      }
    },
    [selectedIds, setSelectedIds],
  );
  return (
    <>
      <AddTransactionToBatchModal
        isOpen={isAddProjectTransactionToBatchModalOpen}
        handleClose={handleCloseAddProjectTransactionToBatchModal}
        modalTitle="Add Transaction to Batch"
        submitButtonText="Add To Batch"
        modalId="addTransactionModal"
        handleSubmit={handleAddTransactionToBatch}
        isAddingBatchToTransaction={isLoading}
      />

      <AddProjectTransactionModal
        isOpen={isAddProjectTransactionModalOpen}
        handleClose={handleCloseAddProjectTransactionModal}
        modalTitle="Add Payout"
        submitButtonText="Add Payout"
        modalId="addPaymentModal"
        handleSubmit={handleAddTransaction}
        batchDate={undefined}
        payeeType={undefined as unknown as PayeeType}
      />

      <Card padding="sm" marginTop="0">
        <Card.Header>
          <Box direction="row" justifyContent="space-between" alignItems="center">
            <Box direction="row" childGap="sm"></Box>
            <Box direction="row" childGap="sm" alignItems="center">
              {selectedIds?.length > 0 && (
                <Button
                  size="sm"
                  prefix="trash"
                  variant="primary"
                  onClick={handleOpenAddProjectTransactionToBatchModal}
                >
                  Add transaction{selectedIds.length > 1 ? 's' : ''} to Batch
                </Button>
              )}

              <Button
                iconPrefix="add"
                size="sm"
                variant="secondary"
                tone="neutral"
                disabled={isLoading}
                onClick={handleOpenAddProjectTransactionModal}
              >
                Add Transaction
              </Button>
            </Box>
          </Box>
        </Card.Header>
        <Box margin="0 lg 0 lg">
          {!isTransactionsLoading && transactions && (
            <FilterableProjectTransactionTable
              transactions={transactions}
              handleRowSelected={handleRowSelected}
              selectedIds={selectedIds}
            />
          )}
        </Box>
      </Card>
    </>
  );
};
