import { useState } from 'react';
import { Badge, Box, Button, FontColor, IconName } from '@palmetto/palmetto-components';
import { UserPermissions } from 'types';
import RequirePermissions from '../auth/requirePermissions';
import { PropertyValue } from './PropertyValue';
import { useNavigate } from 'react-router-dom';

export function PropertyListBox({
  heading,
  editPath,
  values,
  shouldFold = false,
  showInternalOnly = false,
}: {
  heading: string;
  editPath?: string;
  values: { label: string; value: any; icon?: IconName; iconColor?: FontColor }[];
  shouldFold?: boolean;
  showInternalOnly?: boolean;
}) {
  const navigate = useNavigate();
  const [isFolded, setIsFolded] = useState(shouldFold);
  const topOfFoldValues = values.slice(0, 6);
  const handleToggle = () => setIsFolded(!isFolded);
  return (
    <Box childGap="lg" radius="md" borderWidth="xs" borderColor="separator" padding="lg" margin="0 0 md 0">
      <Box childGap="lg" alignItems="center" direction="row" justifyContent="space-between">
        <Box as="h3" fontWeight="medium" fontSize="sm">
          {heading}
        </Box>
        {showInternalOnly && <Badge variant="tertiary" message="Internal Only" />}
        {editPath && (
          <RequirePermissions permissions={[UserPermissions.admin, UserPermissions.editor]} checkAllPermissions={false}>
            <Button size="sm" variant="primary" tone="neutral" as="a" navigate={() => navigate(editPath)}>
              Edit
            </Button>
          </RequirePermissions>
        )}
      </Box>

      <Box childGap="xs" fontSize="xs">
        {isFolded &&
          topOfFoldValues.map(({ label, value, icon, iconColor }) => (
            <PropertyValue label={label} value={value} key={label} icon={icon} iconColor={iconColor} />
          ))}
        {!isFolded &&
          values.map(({ label, value, icon, iconColor }) => (
            <PropertyValue label={label} value={value} key={label} icon={icon} iconColor={iconColor} />
          ))}
        {shouldFold && (
          <Box onClick={handleToggle} style={{ textDecoration: 'underline' }}>
            {isFolded ? 'Show More' : 'Show Less'}
          </Box>
        )}
      </Box>
    </Box>
  );
}
