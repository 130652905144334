import * as yup from 'yup';
import { Box, Button, FormikTextInput, Modal, toast } from '@palmetto/palmetto-components';
import { Field, Form, Formik, FormikHelpers, FormikValues } from 'formik';
import { useCallback, useMemo } from 'react';
import { ModifierType, itcModifierMap } from 'types';
import { useCreateItcModifierMutation } from '../../../services/pricing';
import {
  CreateEnergyCommunityModifierForm,
  createEnergyCommunityModifierValidationSchema,
} from './CreateEnergyCommunityModifierForm';
import {
  CreateDomesticContentModifierForm,
  createDomesticContentModifierValidationSchema,
} from './CreateDomesticContentModifierForm';
import { useNavigate, useParams } from 'react-router-dom';
import { MainContainer } from '@/components/MainContainer';
import FormSection from '@/components/Forms/FormSection';

const initialValues = {
  name: '',
};

export const CreateITCModifier = () => {
  const [createModifier] = useCreateItcModifierMutation();
  const { modifierType, programType } = useParams();
  const navigate = useNavigate();
  const onSubmit = useCallback(
    async (values: FormikValues, { setSubmitting, resetForm }: FormikHelpers<FormikValues>) => {
      try {
        const filteredNonEmptyValues: FormikValues = {};
        for (const key in values) {
          if (values[key] !== '') {
            filteredNonEmptyValues[key] = values[key];
          }
        }

        const { name, ...rest } = filteredNonEmptyValues;
        const systemTypeValues = Object.keys(rest).reduce((acc, key) => {
          const [prefix, field] = key.split('-');
          const existingSystemTypeIndex = acc.findIndex((item) => item.systemType === prefix);
          if (existingSystemTypeIndex < 0) {
            acc.push({
              [field]: rest[key],
              systemType: prefix,
            });
          } else {
            acc[existingSystemTypeIndex] = {
              ...acc[existingSystemTypeIndex],
              [field]: rest[key],
            };
          }
          return acc;
        }, [] as any[]);

        await createModifier({
          data: {
            modifierType,
            name,
            programType,
            values: systemTypeValues,
          },
        }).unwrap();

        toast.success('Adder created successfully');
        resetForm();
        navigate(`/admin/pricing/${programType}/itc-modifiers/${modifierType}`);
      } catch (e) {
        console.log(e);
        toast.error('Error creating modifier');
      } finally {
        setSubmitting(false);
      }
    },
    [createModifier, modifierType],
  );

  const validationSchema = useMemo(() => {
    let baseSchema = yup.object({
      name: yup.string().required('Option Name is required'),
    });
    switch (modifierType) {
      case ModifierType.energyCommunity:
        baseSchema = baseSchema.concat(createEnergyCommunityModifierValidationSchema);
        break;
      case ModifierType.domesticContent:
        baseSchema = baseSchema.concat(createDomesticContentModifierValidationSchema);
        break;
    }
    return baseSchema;
  }, [modifierType]);

  const getFormFields = useCallback(
    (prefix: string) => {
      switch (modifierType) {
        case ModifierType.energyCommunity:
          return <CreateEnergyCommunityModifierForm prefix={prefix} />;
        case ModifierType.domesticContent:
          return <CreateDomesticContentModifierForm prefix={prefix} />;
      }
    },
    [modifierType],
  );

  return (
    <MainContainer>
      <Box as="h1" fontSize="xl" fontWeight="bold" mb="4">
        Create {itcModifierMap[modifierType as keyof typeof itcModifierMap]} Modifier
      </Box>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit as any}
        enableReinitialize
        validateOnBlur
      >
        {({ resetForm, isSubmitting }) => {
          return (
            <Form>
              <FormSection title="Option Name">
                <Field component={FormikTextInput} name="name" id="name" isRequired />
              </FormSection>
              <FormSection title="PV Only">{getFormFields('pvOnly')}</FormSection>
              <FormSection title="Storage Only">{getFormFields('storageOnly')}</FormSection>
              <FormSection title="PV and Storage">{getFormFields('pvAndStorage')}</FormSection>
              <Box
                direction={{
                  base: 'column',
                  tablet: 'row',
                }}
                alignItems={{
                  base: 'stretch',
                  tablet: 'flex-end',
                }}
                justifyContent={{
                  tablet: 'flex-end',
                }}
                childGap="sm"
                style={{ flexShrink: 0 }}
                padding="lg 0 0 0"
              >
                <Button
                  as="button"
                  onClick={() => {
                    resetForm();
                    navigate(`/admin/pricing/${programType}/itc-modifiers/${modifierType}`);
                  }}
                  variant="secondary"
                  tone="neutral"
                  size="md"
                  isLoading={isSubmitting}
                >
                  Cancel
                </Button>
                <Button variant="primary" size="md" type="submit" isLoading={isSubmitting}>
                  Add Option
                </Button>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </MainContainer>
  );
};
