import {
  Alert,
  Box,
  Button,
  CheckboxInput,
  Column,
  FormLabel,
  Icon,
  RadioGroup,
  SelectInput,
  Table,
  TextInput,
  toast,
} from '@palmetto/palmetto-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { ConstructionAdderType, ProgramType, VendorEquipmentTypes } from 'types';
import PageHeader from '../PageHeader';
import { useGetDOEPRPricingQuery, useGetPricingQuery, useGetProductPriceCapsQuery } from '../../services/pricing';
import uniqBy from 'lodash/uniqBy';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useGetAccountQuery } from '../../services/accounts';
import { currencyFormatter } from '../../helpers/currencyFormatter';
import { useCreateQuoteMutation, useGetQuotesQuery } from '../../services/quotes';
import FormSection from '../Forms/FormSection';
import { QuoteStatus } from '../../types/Quotes';
import { useGetAvailableAddersQuery } from '../../services/availableAdders';
import { isErrorWithData } from '../../services/helpers';
// import { GlideECEligibility, energyCommunityMap } from 'types';
import { percentageFormatter } from '../../helpers/percentageFormatter';

import './quoteTable.css';
import { MainContainer } from '../MainContainer';
import { getAdderValue, isAdderAvailable } from '../../helpers/availableAdders';
import { useGetApprovedVendorsByTypeQuery } from '../../services/approvedVendors';
import { mapAvlManufacturerOptions, mapAvlModelNumberOptions } from '../SystemDesign/AvlFormUtils';

type QuoteTableData = {
  productId: string;
  kwhRate: string;
  firstMonthlyPayment: string;
  solarOnlyEPCPayment: string;
  totalEPCPayment?: string;
  backupBatteryMonthlyPayment?: string;
  totalMonthlyPayment?: string;
};

const columnConfig = [
  {
    heading: 'Solar-only Rate ($/kWh)',
    headerClassName: 'wrap',
    dataKey: 'kwhRate',
  },
  {
    heading: 'Estimated solar-only year-1 Mo. Payment',
    dataKey: 'firstMonthlyPayment',
    headerClassName: 'wrap',
  },
  {
    heading: 'Solar-only EPC Rate ($/W)',
    dataKey: 'ppw',
    headerClassName: 'wrap',
  },
  {
    heading: 'Solar-only EPC Total Payment',
    dataKey: 'solarOnlyEPCPayment',
    headerClassName: 'wrap',
  },
];

const columnConfigWithDC = [
  ...columnConfig,
  {
    heading: 'Solar-only EPC Rate ($/W) without DC',
    dataKey: 'ppwWithoutDC',
    headerClassName: 'wrap',
  },
  {
    heading: 'Solar-only EPC Total Payment without DC',
    headerClassName: 'wrap',
    dataKey: 'solarOnlyEPCPaymentWithoutDC',
  },
];

const columnConfigWithDCAndBattery = [
  ...columnConfig,
  {
    heading: 'Solar-only EPC Rate ($/W) without DC',
    dataKey: 'ppwWithoutDC',
    headerClassName: 'wrap',
  },
  {
    heading: 'Solar-only EPC Total Payment without DC',
    headerClassName: 'wrap',
    dataKey: 'solarOnlyEPCPaymentWithoutDC',
  },
  {
    heading: 'Estimated Battery Year 1 Monthly Payment',
    headerClassName: 'wrap',
    dataKey: 'backupBatteryPayment',
  },
  {
    heading: 'Total Year 1 Monthly Payment',
    headerClassName: 'wrap',
    dataKey: 'totalMonthlyPayment',
  },
  {
    heading: 'Total EPC Payment',
    headerClassName: 'wrap',
    dataKey: 'totalEPCPayment',
  },
  {
    heading: 'Total EPC Payment without DC',
    headerClassName: 'wrap',
    dataKey: 'totalEPCPaymentWithoutDC',
  },
];

const columnConfigWithBattery = [
  ...columnConfig,
  {
    heading: 'Estimated Battery Year 1 Monthly Payment',
    headerClassName: 'wrap',
    dataKey: 'backupBatteryPayment',
  },
  {
    heading: 'Total Year 1 Monthly Payment',
    headerClassName: 'wrap',
    dataKey: 'totalMonthlyPayment',
  },
  {
    heading: 'Total EPC Payment',
    headerClassName: 'wrap',
    dataKey: 'totalEPCPayment',
  },
];

const batteryCountOptions = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' },
  { value: 9, label: '9' },
  { value: 10, label: '10' },
];

const LeafConnector = () => (
  <Box
    borderColor="separator"
    borderWidth="0 0 sm sm"
    width="25px"
    height="32px"
    display="block"
    margin="0 xs sm lg"
  />
);

function getSolarReadinessCostError(solarReadinessCost: number, maxAmount: number) {
  if (isNaN(solarReadinessCost)) {
    return 'Invalid solar readiness';
  }
  if (solarReadinessCost < 1 || solarReadinessCost > maxAmount) {
    return `Invalid amount: must be greater than 0 and less than or equal to ${maxAmount}`;
  }
  return undefined;
}

export const CreateQuote = () => {
  const { id } = useParams<{ id: any }>();
  const { viewAdderUi = true } = useFlags();
  const navigate = useNavigate();

  const [checkedAdders, setCheckedAdders] = useState<string[]>([]);
  const [tableData, setTableData] = useState<QuoteTableData[] | []>([]);
  const [selectedEscalationRate, setSelectedEscalationRate] = useState<number>();
  const [selectedProduct, setSelectedProduct] = useState('');
  const [isMissingProductId, setMissingProductId] = useState(false);
  const [isMissingSystemDetails, setMissingSystemDetails] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isBackupBatterySelected, setIsBackupBatterySelected] = useState(false);
  const [backupBatteryCost, setBackupBatteryCost] = useState<number>(0);
  const [solarReadinessCost, setSolarReadinessCost] = useState<number>(0);
  const [inputErrors, setInputErrors] = useState<{
    solarReadinessCost?: string;
  }>({});
  const [debouncedBackupBatteryCost, setDebouncedBackupBatteryCost] = useState<number>(0);
  const [selectedManufacturer, setSelectedManufacturer] = useState('');
  const [selectedModel, setSelectedModel] = useState('');
  const [selectedQuantity, setSelectedQuantity] = useState(0);
  const [selectedBatteryPricingMethod, setSelectedBatteryPricingMethod] = useState('');
  const [backupBatteryPriceCap, setBackupBatteryPriceCap] = useState<number>('' as any);

  const { data: accountData, isLoading: accountIsLoading } = useGetAccountQuery(id);
  const { data: availableAdders, isLoading: availableAddersIsLoading } = useGetAvailableAddersQuery(id);
  const { data: quotes, isLoading: quotesIsLoading } = useGetQuotesQuery(
    { id, programType: accountData?.programType },
    { skip: !accountData?.programType },
  );
  const { data: storageOptions, isLoading: storageOptionsIsLoading } = useGetApprovedVendorsByTypeQuery({
    equipmentType: VendorEquipmentTypes.storage,
    programType: accountData?.programType,
  });
  const {
    data: productsST,
    isLoading: pricingIsLoadingST,
    error: pricingLoadingErrorST,
  } = useGetPricingQuery(
    {
      id,
      adders: checkedAdders,
      ...(selectedBatteryPricingMethod === 'backupBatteryIncluded'
        ? { backupBatteryCost: debouncedBackupBatteryCost }
        : {}),
      includeDomesticContentPricing: true,
    },
    {
      skip: typeof accountData?.programType === 'undefined' || accountData?.programType === ProgramType.doePr,
    },
  );
  const {
    data: productsWithoutDcST,
    isLoading: pricingIsLoadingWithoutDcST,
    error: pricingLoadingErrorWithoutDcST,
  } = useGetPricingQuery(
    {
      id,
      adders: checkedAdders,
      ...(selectedBatteryPricingMethod === 'backupBatteryIncluded'
        ? { backupBatteryCost: debouncedBackupBatteryCost }
        : {}),
      includeDomesticContentPricing: false,
    },
    {
      skip: typeof accountData?.programType === 'undefined' || accountData?.programType === ProgramType.doePr,
    },
  );
  const {
    data: productsPR,
    isLoading: pricingIsLoadingPR,
    error: pricingLoadingErrorPR,
  } = useGetDOEPRPricingQuery(
    {
      id,
      adders: checkedAdders,
      backupBatteryManufacturer: (selectedManufacturer as any)?.value,
      backupBatteryModelNumber: (selectedModel as any)?.value,
      backupBatteryQuantity: (selectedQuantity as any)?.value,
      solarReadinessCost,
    },
    {
      skip: typeof accountData?.programType === 'undefined' || accountData?.programType !== ProgramType.doePr,
    },
  );

  const standardSolarProducts = useMemo(() => {
    if (!productsST || !productsWithoutDcST) return [];

    const combinedProducts = productsST.reduce((acc: any, product: any) => {
      const matchingProduct = productsWithoutDcST.find((p: any) => p.productId === product.productId);
      if (!matchingProduct) return acc;
      return [
        ...acc,
        {
          ...product,
          ppwWithoutDC: matchingProduct.ppwRate,
          solarOnlyEPCPaymentWithoutDC: matchingProduct.solarOnlyEPCPayment,
          totalEPCPaymentWithoutDC: matchingProduct.totalEPCPayment,
        },
      ];
    }, []);
    return combinedProducts;
  }, [productsST, productsWithoutDcST]);

  const showDCComparison = useMemo(() => {
    return productsST?.find((product) => product.domesticContentEnabled);
  }, [productsST]);

  const products: any[] | undefined =
    accountData?.programType === ProgramType.doePr ? productsPR : standardSolarProducts;
  const pricingIsLoading: boolean = pricingIsLoadingST || pricingIsLoadingWithoutDcST || pricingIsLoadingPR;
  const pricingLoadingError = pricingLoadingErrorST || pricingLoadingErrorWithoutDcST || pricingLoadingErrorPR;

  useEffect(() => {
    if (accountData?.programType === ProgramType.doePr && checkedAdders.length < 1) {
      handleBackupBatteryToggle({
        isSelected: true,
        checkedAdders: checkedAdders,
        setCheckedAdders: setCheckedAdders,
      });
    }
  }, [accountData?.programType, checkedAdders]);

  const checkForInputErrors = useCallback(() => {
    const errors = {
      solarReadinessCost: checkedAdders.includes('solarReadiness')
        ? getSolarReadinessCostError(
            solarReadinessCost,
            getAdderValue(ConstructionAdderType.solarReadiness, availableAdders?.availableAdders) || 0,
          )
        : undefined,
    };
    return errors;
  }, [solarReadinessCost]);

  const { data: backupBatteryPriceCaps } = useGetProductPriceCapsQuery({
    accountId: id,
    productType: 'backupBattery',
  });
  const [createQuote] = useCreateQuoteMutation();

  const storageManufacturerOptions = useMemo(() => {
    return storageOptionsIsLoading ? [] : mapAvlManufacturerOptions(storageOptions?.data || []);
  }, [storageOptionsIsLoading, storageOptions]);

  const storageModelOptions = useMemo(() => {
    return !storageOptionsIsLoading && selectedManufacturer
      ? mapAvlModelNumberOptions(storageOptions?.data || [], (selectedManufacturer as any).value)
      : [];
  }, [storageOptionsIsLoading, storageOptions, selectedManufacturer]);

  const backupBatteryPricingOptions = useMemo(() => {
    const options = [];
    const kWhRate = getAdderValue(ConstructionAdderType.backupBatteryKwh, availableAdders?.availableAdders) || 0;
    if (kWhRate) {
      options.push({
        label: `+ $${kWhRate}/kWh`,
        value: 'backupBatteryKwhIncluded',
        id: 'backupBatteryKwhIncluded',
      });
    }
    const totalCostEnabled = availableAdders?.availableAdders?.find(
      (adder: any) => adder.adderType === ConstructionAdderType.backupBattery && adder.enabled,
    );

    if (totalCostEnabled) {
      options.push({
        label: `Total Cost`,
        value: 'backupBatteryIncluded',
        id: 'backupBatteryIncluded',
      });
    }
    return options;
  }, [availableAdders?.availableAdders]);

  const setManufacturer = useCallback(
    (event: any) => {
      setSelectedManufacturer(event.target.value);
    },
    [setSelectedManufacturer],
  );

  useEffect(() => {
    if (debouncedBackupBatteryCost !== backupBatteryCost) {
      const id = setTimeout(() => {
        setDebouncedBackupBatteryCost(backupBatteryCost);
      }, 400);

      return () => {
        clearTimeout(id);
      };
    }
  }, [debouncedBackupBatteryCost, backupBatteryCost, setDebouncedBackupBatteryCost]);

  useEffect(() => {
    if (backupBatteryPricingOptions?.length === 1) {
      setSelectedBatteryPricingMethod(backupBatteryPricingOptions[0].value);
    }
  }, [backupBatteryPricingOptions, backupBatteryPricingOptions?.length]);

  useEffect(() => {
    if (backupBatteryPriceCaps?.length && selectedModel && selectedManufacturer) {
      const selectedModelValue = (selectedModel as any)?.value;
      const selectedManufacturerValue = (selectedManufacturer as any)?.value;
      const backupBatteryPriceCap = backupBatteryPriceCaps.find(
        (cap: any) => cap.model === selectedModelValue && cap.manufacturer === selectedManufacturerValue,
      )?.maxPrice;
      setBackupBatteryPriceCap(backupBatteryPriceCap);
    }
  }, [backupBatteryPriceCaps, selectedManufacturer, selectedModel, selectedQuantity]);

  // const energyCommunity = accountData?.itcAdderQualifications?.ecEligibilityCriteria
  //   ? energyCommunityMap[accountData.itcAdderQualifications?.ecEligibilityCriteria as GlideECEligibility].map(
  //       (v: string) => v,
  //     )
  //   : false;
  const isLoading =
    availableAddersIsLoading || pricingIsLoading || accountIsLoading || quotesIsLoading || storageOptionsIsLoading;
  const quotesWithProductsAndContract = quotes?.reduce((acc, quote) => {
    const product = products?.find((p) => p.productId === quote.productId);

    if (product) {
      return [...acc, { ...quote, product }];
    }
    return acc;
  }, []);

  const hasActiveOrContractedQuotes = quotesWithProductsAndContract?.some(
    (quote: any) => quote.status === QuoteStatus.active || quote.status === QuoteStatus.contracted,
  );

  const sortedUniqueEscalationRates = useMemo(() => {
    if (!products) return [];
    return uniqBy(products, 'escalationRate').sort((a, b) => a.escalationRate - b.escalationRate);
  }, [products]);

  const escalationRadioButtonOptions = useMemo(() => {
    const labels = sortedUniqueEscalationRates.map((rate) => ({
      label: percentageFormatter.format(rate.escalationRate),
      value: rate.escalationRate.toString(),
      id: rate.escalationRate.toString(),
    }));
    return labels.sort((a, b) => a.value - b.value);
  }, [sortedUniqueEscalationRates]);

  const escalationRateAndProductData = useMemo(() => {
    const escalationRateAndProduct: Array<{ escalationRate: number; tableData: QuoteTableData[] }> = [];
    if (accountData && accountData?.systemDesign?.systemSizeKw) {
      setMissingSystemDetails(false);
      sortedUniqueEscalationRates?.forEach((rate) => {
        const productsByEscalationRate = products?.filter(
          (product) => product.escalationRate === rate.escalationRate,
        );
        const kwhRateAndEpcAndFirstMonthlyPayment =
          productsByEscalationRate?.map((product) => ({
            productId: product.productId,
            kwhRate: `$${product.kwhRate}`,
            firstMonthlyPayment: currencyFormatter.format(product.monthlyPayments[0].monthlyPayment),
            ppw: currencyFormatter.format(product.ppwRate),
            ppwWithoutDC: currencyFormatter.format(product.ppwWithoutDC),
            solarOnlyEPCPayment: currencyFormatter.format(product.solarOnlyEPCPayment),
            solarOnlyEPCPaymentWithoutDC: currencyFormatter.format(product.solarOnlyEPCPaymentWithoutDC),
            totalEPCPayment: product.totalEPCPayment ? currencyFormatter.format(product.totalEPCPayment) : undefined,
            totalEPCPaymentWithoutDC: product.totalEPCPaymentWithoutDC
              ? currencyFormatter.format(product.totalEPCPaymentWithoutDC)
              : undefined,
            backupBatteryPayment:
              product.monthlyPayments[0]?.backupBatteryPayment !== undefined
                ? currencyFormatter.format(product.monthlyPayments[0].backupBatteryPayment)
                : undefined,
            totalMonthlyPayment:
              product.monthlyPayments[0]?.totalMonthlyPayment !== undefined
                ? currencyFormatter.format(product.monthlyPayments[0].totalMonthlyPayment)
                : undefined,
          })) || [];
        escalationRateAndProduct.push({
          escalationRate: rate.escalationRate,
          tableData: kwhRateAndEpcAndFirstMonthlyPayment,
        });
      });
      const selectedEscalationRateAndProduct =
        selectedEscalationRate !== undefined
          ? escalationRateAndProduct.find((products) => products.escalationRate === selectedEscalationRate)
          : escalationRateAndProduct[0];
      setSelectedEscalationRate(
        selectedEscalationRateAndProduct?.escalationRate ?? sortedUniqueEscalationRates[0]?.escalationRate ?? 0,
      );
      setTableData(selectedEscalationRateAndProduct?.tableData || []);
    } else {
      setMissingSystemDetails(true);
    }
    return escalationRateAndProduct;
  }, [products, accountData, sortedUniqueEscalationRates, selectedEscalationRate]);

  const handleSubmit = async () => {
    if (!selectedProduct) {
      setMissingProductId(true);
      return;
    } else {
      setMissingProductId(false);
    }
    setSubmitting(true);
    const quoteData = {
      productId: selectedProduct,
      externalReference: accountData?.externalReference || undefined,
    } as any;
    checkedAdders.forEach((adder: string) => (quoteData[adder] = true));
    if (isBackupBatterySelected) {
      quoteData.backupBatteryManufacturer = (selectedManufacturer as any)?.value;
      quoteData.backupBatteryModelNumber = (selectedModel as any)?.value;
      quoteData.backupBatteryQuantity = (selectedQuantity as any)?.value;
      if (checkedAdders.some((adder) => 'backupBatteryIncluded' === adder)) {
        quoteData.backupBatteryCost = backupBatteryCost;
      }
    }
    if (checkedAdders.includes(ConstructionAdderType.solarReadiness)) {
      quoteData.solarReadinessCost = solarReadinessCost;
    }

    const errors = checkForInputErrors();
    if (Object.values(errors).find((element) => !!element)) {
      toast.error('There are unresolved errors on your form. Please fix.');
      setInputErrors(errors);
      setSubmitting(false);
      return;
    }

    try {
      await createQuote({
        quoteData,
        id,
        programType: accountData?.programType,
      }).unwrap();
      toast.success('Quote submitted successfully');
      navigate(`/accounts/${id}`);
    } catch (e: any) {
      console.error(e);
      toast.error(e.data?.message ?? 'Error creating quote');
    } finally {
      setSubmitting(false);
    }
  };

  const handleBackupBatteryToggle = ({ isSelected, checkedAdders, setCheckedAdders }: any) => {
    setIsBackupBatterySelected(isSelected);
    if (!isSelected) {
      setBackupBatteryCost(0);
      setSelectedBatteryPricingMethod('');
      if (checkedAdders.includes('backupBatteryIncluded')) {
        handleAdderToggle({ isSelected: false, id: 'backupBatteryIncluded', checkedAdders, setCheckedAdders });
      }
      if (checkedAdders.includes('backupBatteryKwhIncluded')) {
        handleAdderToggle({ isSelected: false, id: 'backupBatteryKwhIncluded', checkedAdders, setCheckedAdders });
      }
    } else if (backupBatteryPricingOptions.length === 1) {
      setSelectedBatteryPricingMethod(backupBatteryPricingOptions[0].value);
      handleAdderToggle({ isSelected, id: backupBatteryPricingOptions[0].value, checkedAdders, setCheckedAdders });
    }
  };

  const handleAdderToggle = ({ isSelected, id: adder, checkedAdders, setCheckedAdders }: any) => {
    const newAdders = [...checkedAdders];

    if (isSelected) {
      newAdders.push(adder);
      // Apply exclusivity rules for battery adders
      const batteryAdders = ['backupBatteryIncluded', 'backupBatteryKwhIncluded', 'arbitrageBatteryIncluded'];
      const adderAutoRemovalTypes = batteryAdders.filter(
        (adderType) => batteryAdders.includes(adder) && adderType !== adder,
      ); // Only apply the exclusivity rule if the adder is a battery adder
      adderAutoRemovalTypes.forEach((adderType) => {
        if (newAdders.includes(adderType)) {
          const index = newAdders.findIndex((ch: any) => ch === adderType);
          newAdders.splice(index, 1);
        }
      });
    } else {
      const index = checkedAdders.findIndex((ch: any) => ch === adder);
      if (index > -1) newAdders.splice(index, 1);
    }
    if (newAdders.includes('arbitrageBatteryIncluded')) {
      setIsBackupBatterySelected(false);
      setBackupBatteryCost(0);
      setSelectedBatteryPricingMethod('');
    }
    setCheckedAdders([...newAdders]);
  };

  const handleSelectedEscalationRate = (escalationRate: number) => {
    setSelectedEscalationRate(escalationRate);
    setSelectedProduct('');
    const selectedRateData = escalationRateAndProductData?.find((thing) => thing.escalationRate === escalationRate);
    setTableData(selectedRateData?.tableData || []);
  };
  const handleSelectedProduct = (productId: string) => {
    setSelectedProduct(productId);
  };

  const selectProductColumn = {
    heading: '',
    dataKey: 'productId',
    sticky: 'right',
    render: (_cell: any, row: QuoteTableData) => (
      <Button
        as="button"
        onClick={() => handleSelectedProduct(row.productId)}
        variant={selectedProduct !== row.productId ? 'secondary' : 'primary'}
      >
        Select
      </Button>
    ),
  };

  const getColumnConfig = (): Column[] =>
    !!checkedAdders.find((adder) => adder === 'backupBatteryIncluded') && backupBatteryCost
      ? showDCComparison
        ? ([...columnConfigWithDCAndBattery, selectProductColumn] as Column[])
        : ([...columnConfigWithBattery, selectProductColumn] as Column[])
      : showDCComparison
        ? ([...columnConfigWithDC, selectProductColumn] as Column[])
        : ([...columnConfig, selectProductColumn] as Column[]);

  return (
    <>
      <Helmet>
        <title>Create Quote</title>
      </Helmet>
      <MainContainer>
        <PageHeader
          eyebrow={
            <Link to={`/accounts/${id}`} className="display-flex align-items-center flex-direction-row">
              <Icon name="caret-left" size="xs" color="primary" />
              <span className="font-color-primary m-left-2xs">{accountData?.friendlyName}</span>
            </Link>
          }
          title="Create Quote"
          description="Choose an escalation rate and pricing option to create a quote"
        />
        {!isLoading && !isMissingSystemDetails && pricingLoadingError && (
          <Box childGap="md">
            <Alert
              variant="warning"
              message={isErrorWithData(pricingLoadingError) && pricingLoadingError.data.message}
            />
          </Box>
        )}
        {!isLoading && isMissingSystemDetails ? (
          <Box childGap="md">
            <Alert variant="warning" message="System Size must be provided before creating a quote" />
            <Box fontWeight="bold" margin="0 0 sm 0">
              <Link to={`/accounts/${id}`}>
                <Icon name="caret-left" size="xs" color="primary" />
                <span className="font-color-primary m-left-2xs">Back to Account</span>
              </Link>
            </Box>
          </Box>
        ) : !isLoading && hasActiveOrContractedQuotes ? (
          <Box childGap="md">
            <Alert
              hasIcon
              variant="warning"
              message=" This account already has an active or contracted quote. It must be voided before a new quote can be
          created."
            />
            <Box fontWeight="bold" margin="0 0 sm 0">
              <Link to={`/accounts/${id}`}>
                <Icon name="caret-left" size="xs" color="primary" />
                <span className="font-color-primary m-left-2xs">Back to Account</span>
              </Link>
            </Box>
          </Box>
        ) : (
          <Box childGap="lg" padding={{ base: '0 lg', desktop: '0' }}>
            <Box
              radius="md"
              borderWidth="xs"
              borderColor="separator"
              direction={{ base: 'column', tablet: 'row' }}
              padding="lg"
              alignItems={{ tablet: 'center' }}
              childGap={{ base: 'sm', tablet: '2xl' }}
            >
              <Box>Solar Energy System</Box>
              <Box direction="row" childGap={{ base: 'lg', desktop: '2xl' }}>
                <Box childGap="2xs">
                  <Box fontSize={{ base: 'md', desktop: 'lg' }} fontWeight="medium">
                    {accountData?.systemDesign?.systemSizeKw} kW
                  </Box>
                  <div>System Size</div>
                </Box>
                <Box childGap="2xs">
                  <Box fontSize={{ base: 'md', desktop: 'lg' }} fontWeight="medium">
                    {accountData?.systemDesign?.systemFirstYearProductionKwh} kWh
                  </Box>
                  <div>Year-1 Est. Production</div>
                </Box>
                {/* <Box childGap="2xs">
                  <Box fontSize={{ base: 'md', desktop: 'lg' }} fontWeight="medium">
                    {energyCommunity ? energyCommunity.join(', ') : 'No'}
                  </Box>
                  <Box direction="row" gap="2xs">
                    Energy Community <Icon name="nature" color={energyCommunity ? 'primary' : 'grey-300'} />
                  </Box>
                </Box> */}
              </Box>
            </Box>
            <FormSection title="Annual Escalation Rate">
              {!isLoading && sortedUniqueEscalationRates?.length > 0 && (
                <RadioGroup
                  name="escalationRate"
                  value={selectedEscalationRate?.toString()}
                  onChange={(event) => handleSelectedEscalationRate(Number(event.target.value))}
                  options={escalationRadioButtonOptions}
                />
              )}
            </FormSection>
            {viewAdderUi &&
              (isAdderAvailable(ConstructionAdderType.arbitrageBattery, availableAdders?.availableAdders) ||
                isAdderAvailable(ConstructionAdderType.backupBattery, availableAdders?.availableAdders) ||
                isAdderAvailable(ConstructionAdderType.backupBatteryKwh, availableAdders?.availableAdders) ||
                isAdderAvailable(ConstructionAdderType.solarReadiness, availableAdders?.availableAdders) ||
                isAdderAvailable(ConstructionAdderType.electricalUpgrade, availableAdders?.availableAdders)) && (
                <FormSection title="Adders" description="Select all that apply">
                  <Box direction="row" wrap style={{ gap: 'var(--size-spacing-lg)' }}>
                    {isAdderAvailable(ConstructionAdderType.arbitrageBattery, availableAdders?.availableAdders) && (
                      <CheckboxInput
                        id="arbitrageBatteryIncluded"
                        name="arbitrageBatteryIncluded"
                        label="Arbitrage Battery"
                        helpText={`(+ $${getAdderValue(ConstructionAdderType.arbitrageBattery, availableAdders?.availableAdders) || 0})`}
                        onChange={(event) =>
                          handleAdderToggle({
                            isSelected: event.target.checked,
                            id: 'arbitrageBatteryIncluded',
                            checkedAdders: checkedAdders,
                            setCheckedAdders: setCheckedAdders,
                          })
                        }
                        isChecked={!!checkedAdders.find((adder) => adder === 'arbitrageBatteryIncluded')}
                        isDisabled={
                          !isAdderAvailable(ConstructionAdderType.arbitrageBattery, availableAdders?.availableAdders)
                        }
                      />
                    )}
                    {isAdderAvailable(ConstructionAdderType.electricalUpgrade, availableAdders?.availableAdders) && (
                      <CheckboxInput
                        id="electricalUpgradeIncluded"
                        name="electricalUpgradeIncluded"
                        label="Electrical Upgrade"
                        helpText={`(+ $${getAdderValue(ConstructionAdderType.electricalUpgrade, availableAdders?.availableAdders) || 0})`}
                        onChange={(event) =>
                          handleAdderToggle({
                            isSelected: event.target.checked,
                            id: 'electricalUpgradeIncluded',
                            checkedAdders: checkedAdders,
                            setCheckedAdders: setCheckedAdders,
                          })
                        }
                        isChecked={!!checkedAdders.find((adder) => adder === 'electricalUpgradeIncluded')}
                        isDisabled={
                          !isAdderAvailable(
                            ConstructionAdderType.electricalUpgrade,
                            availableAdders?.availableAdders,
                          )
                        }
                      />
                    )}
                  </Box>

                  <Box flex="auto" direction="column" gap="md">
                    {isAdderAvailable(ConstructionAdderType.solarReadiness, availableAdders?.availableAdders) && (
                      <Box>
                        <CheckboxInput
                          id="solarReadiness"
                          name="solarReadiness"
                          label="Solar Readiness"
                          helpText={`(+ $${getAdderValue(ConstructionAdderType.solarReadiness, availableAdders?.availableAdders) || 0})`}
                          onChange={(event) =>
                            handleAdderToggle({
                              isSelected: event.target.checked,
                              id: 'solarReadiness',
                              checkedAdders: checkedAdders,
                              setCheckedAdders: setCheckedAdders,
                            })
                          }
                          isChecked={!!checkedAdders.find((adder) => adder === ConstructionAdderType.solarReadiness)}
                        />
                        {checkedAdders.includes(ConstructionAdderType.solarReadiness) && (
                          <Box margin="md 0 0 sm" direction="row" childGap="md">
                            <LeafConnector />
                            <TextInput
                              id="solarReadinessCost"
                              value={solarReadinessCost}
                              type="number"
                              label="Total Cost"
                              prefix="$"
                              placeholder={`$${getAdderValue(ConstructionAdderType.solarReadiness, availableAdders?.availableAdders) || 0} Maximum`}
                              onChange={(event) => {
                                setSolarReadinessCost(parseInt(event.target.value));
                                if (inputErrors.solarReadinessCost) {
                                  setInputErrors({ ...inputErrors, solarReadinessCost: undefined });
                                }
                              }}
                              error={inputErrors.solarReadinessCost}
                              isRequired
                              maxWidth="2xl"
                            />
                          </Box>
                        )}
                      </Box>
                    )}
                    {(isAdderAvailable(ConstructionAdderType.backupBattery, availableAdders?.availableAdders) ||
                      isAdderAvailable(ConstructionAdderType.backupBatteryKwh, availableAdders?.availableAdders) ||
                      accountData?.programType === ProgramType.doePr) && (
                      <CheckboxInput
                        id="backupBatteryIncluded" // The Id and name should not include 'included'
                        name="backupBatteryIncluded"
                        label="Backup Battery"
                        onChange={(
                          event, // the change event should just toggle the state value
                        ) => {
                          handleBackupBatteryToggle({
                            isSelected: event.target.checked,
                            checkedAdders: checkedAdders,
                            setCheckedAdders: setCheckedAdders,
                          });
                        }}
                        isChecked={!!isBackupBatterySelected}
                      />
                    )}
                    {!!isBackupBatterySelected && (
                      <Box childGap="lg">
                        <Box direction="row" padding="0 0 0 sm">
                          <LeafConnector />
                          <Box direction={{ base: 'column', tablet: 'row' }} gap="lg" flex="auto">
                            <SelectInput
                              label="Manufacturer"
                              name="backupBatteryManufacturer"
                              id="backupBatteryManufacturer"
                              value={selectedManufacturer}
                              options={storageManufacturerOptions}
                              isDisabled={isLoading}
                              isRequired
                              onChange={setManufacturer}
                            />
                            <SelectInput
                              label="Model"
                              name="backupBatteryModel"
                              id="backupBatteryModel"
                              value={selectedModel}
                              options={storageModelOptions}
                              isDisabled={isLoading}
                              isRequired
                              onChange={(event) => setSelectedModel(event.target.value)}
                            />
                            <Box width={{ base: 'auto', tablet: '25' }}>
                              <SelectInput
                                label="Quantity"
                                name="backupBatteryQuantity"
                                id="backupBatteryQuantity"
                                options={batteryCountOptions}
                                value={selectedQuantity}
                                isDisabled={isLoading}
                                isRequired
                                onChange={(event) => setSelectedQuantity(event.target.value)}
                              />
                            </Box>
                          </Box>
                        </Box>
                        <Box padding="0 0 0 4xl">
                          {accountData?.programType !== ProgramType.doePr && (
                            <Box>
                              <FormLabel inputId="pricingMethod">Pricing Method</FormLabel>
                              <RadioGroup
                                name="pricingMethod"
                                description="How you would like to price the backup battery"
                                id="pricingMethod"
                                value={selectedBatteryPricingMethod}
                                options={backupBatteryPricingOptions}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                  setSelectedBatteryPricingMethod(event.target.value);
                                  handleAdderToggle({
                                    isSelected: event.target.checked,
                                    id: event.target.id,
                                    checkedAdders: checkedAdders,
                                    setCheckedAdders: setCheckedAdders,
                                  });
                                }}
                              />
                            </Box>
                          )}
                          {selectedBatteryPricingMethod === 'backupBatteryIncluded' &&
                            accountData.programType !== ProgramType.doePr && (
                              <Box margin="md 0 0 sm" direction="row" childGap="md">
                                <LeafConnector />
                                <TextInput
                                  id="required"
                                  value={backupBatteryCost}
                                  type="number"
                                  label="Backup Battery Total Cost"
                                  prefix="$"
                                  placeholder="$ e.g. 10000"
                                  onChange={(event) => setBackupBatteryCost(event.target.value)}
                                  isRequired
                                  helpText={`${backupBatteryPriceCap ? currencyFormatter.format(backupBatteryPriceCap) : ''} max per unit`}
                                  maxWidth="2xl"
                                />
                              </Box>
                            )}
                        </Box>
                      </Box>
                    )}
                  </Box>
                </FormSection>
              )}
            <FormSection title="Pricing Option">
              <Box display="block">
                <Table
                  rowKey="productId"
                  columns={getColumnConfig()}
                  rows={tableData}
                  isLoading={isLoading}
                  isScrollable={{
                    x: true,
                  }}
                />
              </Box>
            </FormSection>
            {isMissingProductId && <Alert variant="danger" message="Select a pricing option" />}
            <Box alignSelf="flex-end" direction="row" childGap="md">
              <Button
                as="a"
                navigate={() => navigate(`/accounts/${id}`)}
                variant="secondary"
                tone="neutral"
                size="md"
                isLoading={isSubmitting}
              >
                Cancel
              </Button>
              <Button variant="primary" isLoading={isSubmitting} as="button" onClick={() => handleSubmit()}>
                Create Quote
              </Button>
            </Box>
          </Box>
        )}
      </MainContainer>
    </>
  );
};
