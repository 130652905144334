import { useState } from 'react';
import { Box, Button, FormikTextareaInput, toast } from '@palmetto/palmetto-components';
import { MainContainer } from '../MainContainer';
import { Helmet } from 'react-helmet';
import { Field, Form, Formik } from 'formik';
import FormSection from '../Forms/FormSection';
import { object, string, Schema } from 'yup';
import { useRunTaskMutation } from '@/services/adminTasks';
import { GrantPtoGreenlightResults } from './GrantPtoGreenlightResults';
import { createAccountIdsValidator } from '@/helpers/validateAccountIds';
import { parseAccountIdsFromString } from '@/helpers/parseAccountIdsFromString';

interface GrantPtoGreenlightFormData {
  accountIds: string;
}

const grantPtoGreenlightSchema = {
  accountIds: string()
    .required('Accounts are required')
    .test('is-valid-mongo-ids', 'Invalid account ids', createAccountIdsValidator()),
};

export const GrantPtoGreenlightFormSchema: Schema<GrantPtoGreenlightFormData> = object()
  .shape(grantPtoGreenlightSchema)
  .noUnknown(true);

export function GrantPtoGreenlight() {
  const [results, setResults] = useState<any>();
  const [runTask] = useRunTaskMutation();

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    const loadingToastId = toast.loading('Processing accounts...');
    try {
      let accountIds = await parseAccountIdsFromString(values.accountIds);
      accountIds = accountIds.filter((id: string) => id.trim().length > 0);

      const ptoGreenlightResults = await runTask({
        task: 'grantPtoGreenlight',
        accountIds,
      }).unwrap();

      setResults(ptoGreenlightResults);
    } catch (e) {
      console.error(e);
      toast.error('Error submitting manual PTO greenlight request');
    } finally {
      toast.dismiss(loadingToastId);
      setSubmitting(false);
    }
  };

  const initialValues = {};

  const handleCloseResults = () => {
    setResults(undefined);
  };

  return (
    <>
      <Helmet>
        <title>Grant Pto Greenlight</title>
      </Helmet>
      <MainContainer>
        {!results ? (
          <Formik
            validationSchema={GrantPtoGreenlightFormSchema}
            initialValues={initialValues}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, submitForm }) => (
              <Form>
                <Box>
                  <Box>
                    <FormSection title="Accounts" description="One account ID per line, or comma separated">
                      <Field
                        name="accountIds"
                        id="accountIds"
                        component={FormikTextareaInput}
                        isDisabled={isSubmitting}
                        rows={10}
                      />
                    </FormSection>
                  </Box>
                </Box>
                <Box
                  direction={{
                    base: 'column',
                    tablet: 'row',
                  }}
                  alignItems={{
                    base: 'stretch',
                    tablet: 'flex-end',
                  }}
                  justifyContent={{
                    tablet: 'flex-end',
                  }}
                  childGap="sm"
                  style={{ flexShrink: 0 }}
                  padding="lg 0 0 0"
                >
                  <Button size="md" variant="primary" type="submit" onClick={submitForm} isLoading={isSubmitting}>
                    Grant PTO Greenlight
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        ) : (
          <GrantPtoGreenlightResults results={results} closeResults={handleCloseResults} />
        )}
      </MainContainer>
    </>
  );
}
