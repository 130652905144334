import { BatchInfo, PayeeType, ProgramType, ProjectTransactionStatus } from 'types';
import { Badge, Box, Button } from '@palmetto/palmetto-components';
import { DateTime } from 'luxon';
import { currencyFormatter } from '@/helpers/currencyFormatter';
import { BatchComponent } from '@/types/Payouts';
import { useNavigate } from 'react-router-dom';
import RequirePalmettoFinanceUser from '../auth/RequirePalmettoFinanceUser';

interface BatchesListProps {
  batches: BatchInfo[];
  originComponent: BatchComponent;
}
export const BatchesList = ({ batches, originComponent }: BatchesListProps) => {
  const actionButtonText = originComponent === BatchComponent.queued ? 'Review' : 'View';
  const navigate = useNavigate();
  return (
    <>
      {batches?.length > 0 ? (
        batches.map((batch) => (
          <Box
            background="primary"
            padding="lg lg"
            shadow="sm"
            radius="sm"
            gap="2xl"
            key={`${batch.id.toString()}-${batch.payeeType}`}
          >
            <Box direction="row" justifyContent="space-between" alignItems="center">
              <Box fontWeight="medium">
                <Box direction="row" alignItems="center">
                  <Box width="240px">{DateTime.fromISO(batch.batchDate).toFormat('cccc LLLL d, yyyy')}</Box>{' '}
                  <Box>
                    <Badge
                      variant={batch.payeeType === PayeeType.organization ? 'primary' : 'secondary'}
                      message={batch.payeeType}
                    />
                  </Box>
                </Box>
                <Box fontSize="xs" as="span" direction="row" gap="md">
                  Payout Date
                </Box>
              </Box>

              <Box direction="row" alignItems="center" childGap="lg">
                <Box direction="column" alignItems="flex-end">
                  <RequirePalmettoFinanceUser>
                    <Box fontSize="md" fontWeight="bold">
                      {batch.payees}
                    </Box>
                    <Box fontSize="xs">Payees</Box>
                  </RequirePalmettoFinanceUser>
                </Box>

                <Box direction="column" alignItems="flex-end">
                  <Box fontSize="md" fontWeight="bold">
                    {batch.transactions}
                  </Box>
                  <Box fontSize="xs">Transactions</Box>
                </Box>

                <Box direction="column" alignItems="flex-end">
                  <Box fontSize="md" fontWeight="bold">
                    {currencyFormatter.format(batch.amount)}
                  </Box>
                  <Box fontSize="xs">Total Payout Amount</Box>
                </Box>
                <Button
                  navigate={() =>
                    navigate(
                      `/admin/payouts/group-batches/${batch.batchDate}?payeeType=${batch.payeeType}${originComponent === BatchComponent.queued ? '&status=' + ProjectTransactionStatus.pending : ''}`,
                    )
                  }
                >
                  {actionButtonText}
                </Button>
              </Box>
            </Box>
          </Box>
        ))
      ) : (
        <Box padding="lg" background="primary" borderWidth="1" borderColor="separator">
          No Batches available.
        </Box>
      )}
    </>
  );
};
