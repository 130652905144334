import { Box, Modal, Tabs, TabPanels } from '@palmetto/palmetto-components';
import { useCallback, useEffect, useState } from 'react';
import { CreditReportSummary } from '../types/CreditReports';
import { useLazyGetCreditReportDetailsQuery } from '../services/creditReports';
import { useParams } from 'react-router-dom';
import getFriendlyNameForReportType from '../helpers/getFriendlyNameForReportType';

interface FullCreditReportModalProps {
  isModalOpen: boolean;
  closeModal: any;
  summary: CreditReportSummary | null;
  applicantId?: string;
}

const providerLabelsMap: Record<string, string> = {
  equifax: 'Equifax',
  experian: 'Experian',
  transunion: 'TransUnion',
  transunionDirect: 'TransUnion',
};

const FullCreditReportModal = (props: FullCreditReportModalProps) => {
  const { isModalOpen, closeModal, summary, applicantId } = props;
  const [trigger, { data, error, isFetching }] = useLazyGetCreditReportDetailsQuery();

  const { id } = useParams<{ id: any }>();

  const [selectedTab, setSelectedTab] = useState<number>(0);

  const handleTabSelection = useCallback(
    (_: any, index: number) => {
      setSelectedTab(index);
    },
    [setSelectedTab],
  );

  useEffect(() => {
    if (isModalOpen && summary) {
      setSelectedTab(0);
      trigger({ accountId: id, applicationId: summary.applicationId || '', applicantId });
    }
  }, [isModalOpen, summary, trigger, id, applicantId]);
  return (
    <Modal ariaLabelledBy="titleBasic" isOpen={isModalOpen} onDismiss={closeModal}>
      <Modal.Header
        id="titleBasic"
        title={`${summary?.applicantName} - ${getFriendlyNameForReportType(summary?.reports[0]?.type)}`}
        onDismiss={closeModal}
      />
      <Modal.Body padding="0 lg lg">
        {isFetching && <div>Loading...</div>}
        {!isFetching && !error && (
          <Box childGap="sm">
            <Tabs value={selectedTab} onChange={handleTabSelection} background="primary">
              {data?.map((report: any) => (
                <Tabs.Item key={`${report.provider}-${report.id}`}>{providerLabelsMap[report.provider]}</Tabs.Item>
              ))}
            </Tabs>
            <TabPanels value={selectedTab}>
              {data?.map((report: any) => (
                <Box key={report.id}>
                  <Box background="tertiary" padding="sm">
                    <code>
                      <pre>{JSON.stringify(report, null, 2)}</pre>
                    </code>
                  </Box>
                </Box>
              ))}
            </TabPanels>
          </Box>
        )}
        {!isFetching && error && <Box>error fetching report details</Box>}
      </Modal.Body>
    </Modal>
  );
};

export default FullCreditReportModal;
