import { Box, Button, Column, Table } from '@palmetto/palmetto-components';
import { Icon } from '@palmetto/palmetto-components';

interface GrantPtoGreenlightResultsProps {
  results: {
    completedAccounts: string[];
    invalidAccounts: any[];
  };
  closeResults: () => void;
}

interface StatusResultProps {
  icon: 'c-check' | 't-warning' | 'remove';
  color: 'success' | 'danger' | 'warning-500';
  text: string;
  accountsTable?: React.ReactNode;
}

const StatusResult = ({ icon, color, text, accountsTable }: StatusResultProps) => (
  <Box direction="row">
    <Box>
      <Icon name={icon} color={color} size="1xl" />
    </Box>
    <Box display="block" width="100" padding="xs 0 0 md" childGap="md">
      <Box as="h3" fontWeight="bold">
        {text}
      </Box>
      {accountsTable}
    </Box>
  </Box>
);

const normalizeAccountLabel = (length: number) => `${length} ${length === 1 ? 'account' : 'accounts'}`;

const invalidAccountsFoundTableColumnConfig: Column[] = [
  { heading: 'ACCOUNT ID', dataKey: 'accountId' },
  { heading: 'Reason', dataKey: 'reason' },
];

export function GrantPtoGreenlightResults({ results, closeResults }: GrantPtoGreenlightResultsProps) {
  return (
    <Box childGap={'xl'} padding={{ base: 'sm md', desktop: '0 0', tablet: '0 0' }}>
      {results.completedAccounts.length > 0 && (
        <Box>
          <StatusResult
            icon="c-check"
            color="success"
            text={`${normalizeAccountLabel(results.completedAccounts.length)}`}
          />
        </Box>
      )}
      {results.invalidAccounts.length > 0 && (
        <Box>
          <StatusResult
            icon="remove"
            color="danger"
            text={`${normalizeAccountLabel(results.invalidAccounts.length)} failed`}
            accountsTable={
              <Table
                isScrollable={{
                  x: true,
                }}
                rowKey="accountId"
                columns={invalidAccountsFoundTableColumnConfig}
                rows={results.invalidAccounts}
              />
            }
          />
        </Box>
      )}
      <Box
        direction={{
          base: 'column',
          tablet: 'row',
        }}
        alignItems={{
          base: 'stretch',
          tablet: 'flex-end',
        }}
        justifyContent={{
          tablet: 'flex-end',
        }}
        childGap="sm"
        style={{ flexShrink: 0 }}
      >
        <Button size="md" variant="secondary" tone="neutral" aria-label="view found" onClick={closeResults}>
          Close
        </Button>
      </Box>
    </Box>
  );
}
