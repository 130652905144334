import { Helmet } from 'react-helmet';
import {
  generatePath,
  matchPath,
  NavLink,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { Box, SelectInputNative } from '@palmetto/palmetto-components';
import PageHeader from '../../PageHeader';
import { MainContainer } from '@/components';
import { PaymentPlans } from './PaymentPlans';
import { useEffect, useMemo, useState } from 'react';
import { ProgramType, ProgramTypeLabels, UserPermissions } from 'types';
import { PaymentPlan } from './PaymentPlan';
import { Queued } from './Queued';
import { Paid } from './Paid';
import { useAuth } from '@/components/auth/authProvider';
import { useGetOrganizationDetailsQuery } from '@/services/organizations';
import { RequirePayoutsAccountingAdminUser } from '@/components/auth/RequirePayoutsAccountingAdminUser';
import { AccountPayoutEventErrors } from './AccountPayoutEventErrors';
import { RequirePayoutsAccountingSupportUser } from '@/components/auth/RequirePayoutsAccountingSupportUser';
import RequirePermissions from '@/components/auth/requirePermissions';
import { ProjectTransactionsView } from '../ProjectTransactionsView';
import { UnbatchedTransactions } from './UnbatchedTransactions';
import { InvoiceNeeded } from './InvoiceNeeded';
import { BatchView } from '@/components/Payouts/BatchView';

const baseRoute = '/admin/payouts/';
const routes = {
  paymentPlans: ':programType/payment-plans',
  paymentPlan: ':programType/payment-plans/:id',
  queued: 'queued',
  approved: 'approved',
  unbatchedTransactions: ':programType/unbatched-transactions',
  invoiceNeeded: 'invoice-needed',
  eventErrors: 'event-errors',
  groupBatches: 'group-batches/:batchDate',
  batch: 'batches/:batchId',
};

const routesWithProgramType = Object.entries(routes).filter(([_key, value]) => value.includes(':programType'));

const programTypesOptions = Object.entries(ProgramTypeLabels).map(([key, value]) => ({
  label: value,
  value: key,
}));

export function Payouts() {
  const params = useParams();
  const { claims } = useAuth();
  const { data: organizationData } = useGetOrganizationDetailsQuery(
    {
      alias: claims?.org,
    },
    { skip: !claims?.org },
  );

  const [programType, setProgramType] = useState<ProgramType>(
    (params?.programType as ProgramType) ?? programTypesOptions[0].value,
  );
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (organizationData?.programTypes?.length) {
      const orgProgramType = organizationData.programTypes[0];
      if (programType !== orgProgramType) setProgramType(orgProgramType);
    }
  }, [organizationData]);

  const showProgramTypeSelection = useMemo(() => {
    return (
      !location ||
      routesWithProgramType.some(([_key, value]) => matchPath(`${baseRoute}${value}`, location.pathname))
    );
  }, [location?.pathname]);

  const handleProgramTypeChange = (event: any) => {
    const selectedProgramType: ProgramType = event.target.value;
    setProgramType(selectedProgramType);
    const pathPattern =
      Object.values(routes).find((pattern) => matchPath(`${baseRoute}${pattern}`, location.pathname)) || '';
    const path = generatePath(`${baseRoute}/${pathPattern}`, {
      ...params,
      programType: selectedProgramType,
    });
    navigate(path);
  };

  return (
    <>
      <Helmet>
        <title>Payouts</title>
      </Helmet>
      <MainContainer maxWidth="100">
        <RequirePermissions
          permissions={[UserPermissions.orgAccountingAdmin, UserPermissions.lightReachAccountingAdmin]}
          checkAllPermissions={false}
        >
          {showProgramTypeSelection && (
            <Box maxWidth="2xl">
              <SelectInputNative
                id="required"
                label="Program Type"
                value={programType}
                onChange={handleProgramTypeChange}
                options={programTypesOptions}
                menuPortalTarget={document.body}
              />
            </Box>
          )}
        </RequirePermissions>
        <PageHeader title={`Payments`}></PageHeader>

        <Box direction="row" borderWidth="0 0 xs 0" borderColor="separator">
          <RequirePermissions
            permissions={[UserPermissions.lightReachAccountingAdmin, UserPermissions.orgAccountingAdmin]}
            checkAllPermissions={false}
          >
            <NavLink to="queued" style={{ textDecoration: 'none', fontWeight: '500' }} end>
              {({ isActive }) => (
                <Box
                  as="span"
                  padding="md"
                  className={isActive ? 'tab-active' : ''}
                  hover={{ color: 'primary' }}
                  style={{ transitionDuration: '0s' }}
                >
                  Queued
                </Box>
              )}
            </NavLink>
          </RequirePermissions>
          <RequirePermissions
            permissions={[UserPermissions.lightReachAccountingAdmin, UserPermissions.orgAccountingAdmin]}
            checkAllPermissions={false}
          >
            <NavLink to="approved" style={{ textDecoration: 'none', fontWeight: '500' }} end>
              {({ isActive }) => (
                <Box
                  as="span"
                  padding="md"
                  className={isActive ? 'tab-active' : ''}
                  hover={{ color: 'primary' }}
                  style={{ transitionDuration: '0s' }}
                >
                  Approved
                </Box>
              )}
            </NavLink>
          </RequirePermissions>
          <RequirePayoutsAccountingAdminUser>
            <NavLink
              to={`${programType}/unbatched-transactions`}
              style={{ textDecoration: 'none', fontWeight: '500' }}
              end
            >
              {({ isActive }) => (
                <Box
                  as="span"
                  padding="md"
                  className={isActive ? 'tab-active' : ''}
                  hover={{ color: 'primary' }}
                  style={{ transitionDuration: '0s' }}
                >
                  Unbatched Transactions
                </Box>
              )}
            </NavLink>
          </RequirePayoutsAccountingAdminUser>
          <RequirePermissions
            permissions={[UserPermissions.lightReachAccountingAdmin, UserPermissions.orgAccountingAdmin]}
            checkAllPermissions={false}
          >
            <NavLink to="invoice-needed" style={{ textDecoration: 'none', fontWeight: '500' }} end>
              {({ isActive }) => (
                <Box
                  as="span"
                  padding="md"
                  className={isActive ? 'tab-active' : ''}
                  hover={{ color: 'primary' }}
                  style={{ transitionDuration: '0s' }}
                >
                  Invoice Needed
                </Box>
              )}
            </NavLink>
          </RequirePermissions>
          <RequirePayoutsAccountingAdminUser>
            <NavLink to={`${programType}/payment-plans`} style={{ textDecoration: 'none', fontWeight: '500' }} end>
              {({ isActive }) => (
                <Box
                  as="span"
                  padding="md"
                  className={isActive ? 'tab-active' : ''}
                  hover={{ color: 'primary' }}
                  style={{ transitionDuration: '0s' }}
                >
                  Payment Plans
                </Box>
              )}
            </NavLink>
          </RequirePayoutsAccountingAdminUser>
          <RequirePayoutsAccountingSupportUser>
            <NavLink to="event-errors" style={{ textDecoration: 'none', fontWeight: '500' }} end>
              {({ isActive }) => (
                <Box
                  as="span"
                  padding="md"
                  className={isActive ? 'tab-active' : ''}
                  hover={{ color: 'primary' }}
                  style={{ transitionDuration: '0s' }}
                >
                  Payout Event Errors
                </Box>
              )}
            </NavLink>
          </RequirePayoutsAccountingSupportUser>
        </Box>
        <Routes>
          <Route path={routes.paymentPlans} element={<PaymentPlans programType={programType} />} />
          <Route path={routes.paymentPlan} element={<PaymentPlan />} />
          <Route path={routes.queued} element={<Queued />} />
          <Route path={routes.approved} element={<Paid />} />
          <Route path={routes.unbatchedTransactions} element={<UnbatchedTransactions programType={programType} />} />
          <Route path={routes.invoiceNeeded} element={<InvoiceNeeded />} />
          <Route path={routes.eventErrors} element={<AccountPayoutEventErrors />} />
          <Route path={routes.groupBatches} element={<ProjectTransactionsView />}></Route>
          <Route path={routes.batch} element={<BatchView />}></Route>
        </Routes>
      </MainContainer>
    </>
  );
}
