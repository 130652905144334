import { Helmet } from 'react-helmet';
import { Box, toast, useOpenClose } from '@palmetto/palmetto-components';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useEffect, useState } from 'react';
import { AttestmentData } from 'types';
import { useGetAccountQuery } from '../../services/accounts';
import { MainContainer } from '../MainContainer';
import { InputForm } from './Form';
import { HeaderElement } from './Header';
import { ErrorObject, CheckRequestFormData, ConfirmObject, Origin } from '../../types/ProductionValueCheck';
import { useCreateSystemDesignMutation, useGetCurrentDesignQuery } from '../../services/systemDesign';
import { useGetQuoteSettingsQuery, useGetQuotesQuery } from '../../services/quotes';
import { QuoteStatus } from '../../types/Quotes';
import { isProductionWithinChangeToleranceFE } from '../../helpers/productionValueCheck';
import { AttestmentModal } from './AttestmentModal';
import { formatKWHNumber } from '../../helpers/kwhFormatter';
import { useGetCurrentContractQuery, useVoidContractMutation } from '../../services/contract';
import { ReviewPackageActions } from '../../types/ReviewPackage';

function getNextRoute(origin: Origin, account: any, action = ReviewPackageActions.create) {
  let route: string = `/accounts/${account?.id}`;
  if ([Origin.INSTALL_PACKAGE, Origin.ACCOUNT_VIEW].includes(origin)) {
    const isDomesticContentEligible = account?.itcAdderQualifications?.domesticContentEligible;
    const hasDomesticContentAttestment = Boolean(account?.systemDesign?.domesticContentAttestment);
    if (!hasDomesticContentAttestment && isDomesticContentEligible) {
      route = `/accounts/${account?.id}/domestic-content-value-confirmation`;
    } else {
      route = `/accounts/${account?.id}/installation-package/${action}`;
    }
  }
  if (origin === Origin.PV_SYSTEM_TAB) {
    route = `/accounts/${account?.id}/system-design`;
  }

  return route;
}

function moveForward(
  origin: Origin,
  account: any,
  navigate: any,
  didUpdate: boolean,
  action = ReviewPackageActions.create,
) {
  let route: string = getNextRoute(origin, account, action);
  navigate(route, {
    state: {
      justUpdated1yEstimate: didUpdate,
      shouldRedirect: false,
    },
  });
}

const newProductionSchema = yup.object({
  systemFirstYearProductionKwh: yup
    .mixed()
    .test({
      test: function (value: unknown) {
        if (!value) return true;
        const numberValue = Number(value);
        //We add the following check to avoid scenarios like 252E223, which could
        //evaluate as a number but it's an invalid number still.
        const isValidNumber = /^[0-9.]{1,25}$/.test(String(value));
        if (isNaN(numberValue) || !isValidNumber) {
          return this.createError({ message: 'Production must be a valid number' });
        }
        if (numberValue < 0) {
          return this.createError({ message: 'Production must be a positive number' });
        }
        return true;
      },
    })
    .required('Required for production validation'),
});

export function ProductionValueCheck() {
  const { id: accountId } = useParams<{ id: any }>();
  const navigate = useNavigate();
  const location = useLocation();

  const { data: account }: any = useGetAccountQuery(accountId);
  const { data: currentDesignQueryData }: any = useGetCurrentDesignQuery(
    { id: accountId, programType: account?.programType },
    { skip: !account?.programType },
  );
  const [createSystemDesign] = useCreateSystemDesignMutation();
  const { data: quotes } = useGetQuotesQuery(
    { id: accountId, programType: account?.programType },
    { skip: !account?.programType },
  );
  const { data: quoteSettings } = useGetQuoteSettingsQuery(
    { programType: account?.programType },
    {
      skip: !account?.programType,
    },
  );
  const { data: contract } = useGetCurrentContractQuery(accountId);

  const {
    isOpen: isAttestmentModalOpen,
    handleClose: handleCloseAttestmentModal,
    handleOpen: handleOpenAttestmentModal,
  } = useOpenClose();

  const [voidContract] = useVoidContractMutation();
  const [isVoiding, setIsVoiding] = useState(false);

  const origin = location?.state?.from || null;
  const action = location?.state?.action;
  const contractQuote = (quotes || []).find(
    (element: any) => element.id === contract?.quoteId && element.status === QuoteStatus.active,
  );
  const firstYearContracted = contractQuote?.systemFirstYearProductionKwh || 0;
  const currentDesignProdKwH =
    currentDesignQueryData?.systemFirstYearProductionKwh || account?.systemDesign?.systemFirstYearProductionKwh || 0;

  const [isChecking, setIsChecking] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [formData, setFormData] = useState<CheckRequestFormData>();
  const primaryApplicantName = account?.primaryApplicantName;
  const [errorObject, setErrorObject] = useState<ErrorObject>();
  const [confirmObject, setConfirmObject] = useState<ConfirmObject>();
  const [attestmentData, setAttestmentData] = useState<AttestmentData>();

  useEffect(() => {
    const nextRoute = getNextRoute(origin, account, action);
    if (account?.systemDesign?.attestment && origin !== Origin.INSTALL_PACKAGE && origin !== Origin.PV_SYSTEM_TAB)
      navigate(nextRoute);
  }, [accountId, navigate, account?.systemDesign?.attestment, origin, action]);

  async function handleAttestmentConfirmation(attestmentData: AttestmentData) {
    setAttestmentData(attestmentData);
    //This part controls the execution of the update as soon as the attestment happens.
    if (attestmentData && formData) {
      try {
        await requestUpdate(formData, attestmentData);
      } catch (requestUpdate_e) {
        console.log({ requestUpdate_e });
      } finally {
        handleCloseAttestmentModal();
      }
    }
  }

  async function requestUpdate(data: CheckRequestFormData, attestmentData?: AttestmentData) {
    let didUpdate = false;
    setIsUpdating(true);
    try {
      await createSystemDesign({
        systemDesign: {
          ...(currentDesignQueryData || account.systemDesign),
          systemFirstYearProductionKwh: Number(data.systemFirstYearProductionKwh),
          attestment: attestmentData,
          toleranceException: null,
        },
        accountId: account.id,
      }).unwrap();
      didUpdate = true;
      moveForward(origin, account, navigate, didUpdate, action);
    } catch (requestUpdateError) {
      const potentialError = requestUpdateError as any;
      if (
        quoteSettings &&
        `${(potentialError.data as any).message || ''}`.match(
          'The New Total Year-1 Production Estimate value is not valid: must void the contract and the active quote first.',
        )
      ) {
        const isOnRange = isProductionWithinChangeToleranceFE({
          newProductionKwh: Number(data.systemFirstYearProductionKwh),
          originalProductionKwh: firstYearContracted,
          settings: quoteSettings,
        });
        const percentDiff =
          Number(formatKWHNumber(Number(data.systemFirstYearProductionKwh))) <
          Number(formatKWHNumber(firstYearContracted))
            ? isOnRange.percentDifference * -1
            : isOnRange.percentDifference;
        setErrorObject({
          percentErrorDiff: percentDiff,
          errorKwH: isOnRange.difference,
        });
        toast.error((requestUpdateError as any)?.data?.message);
      } else {
        toast.error('An error has occurred. Please try again later.');
      }
    } finally {
      setIsUpdating(false);
      setConfirmObject(undefined);
    }
  }

  async function requestCheck(data: CheckRequestFormData) {
    setFormData(data);
    setIsChecking(true);
    if (
      +data.systemFirstYearProductionKwh === account.systemDesign.systemFirstYearProductionKwh &&
      account.systemDesign.attestment
    ) {
      moveForward(origin, account, navigate, false, action);
    }
    const hasContracted = firstYearContracted > 0;
    const to = Number(data.systemFirstYearProductionKwh);
    const newEqualsCurrent = currentDesignProdKwH === to;
    const from = hasContracted ? firstYearContracted : currentDesignProdKwH;
    const isOnRange = isProductionWithinChangeToleranceFE({
      newProductionKwh: to,
      originalProductionKwh: from,
      settings: quoteSettings,
    });
    //If we don't have contracted, we default to valid.
    isOnRange.valid = hasContracted ? isOnRange.valid : true;
    const percentDiff =
      Number(formatKWHNumber(to)) < Number(formatKWHNumber(from))
        ? isOnRange.percentDifference * -1
        : isOnRange.percentDifference;
    const percentChanged = percentDiff !== 0;
    const changed = percentChanged || (!percentChanged && !newEqualsCurrent);
    const isValidRange = isOnRange.valid;
    const diffKwH = isOnRange.difference;
    const comesFromInstallPackage = origin === Origin.INSTALL_PACKAGE;
    const comesFromPvSystemTab = origin === Origin.PV_SYSTEM_TAB;

    setIsChecking(false);

    if (!quoteSettings) {
      navigate(getNextRoute(origin, account, action));
      return;
    }
    if (comesFromInstallPackage && confirmObject && !attestmentData) {
      switch (confirmObject.step || 0) {
        case 2:
          return handleOpenAttestmentModal();
        default:
          setConfirmObject({
            diffKwH,
            percentDiff,
            step: 2,
          });
          return handleOpenAttestmentModal();
      }
    }

    if (!isValidRange) {
      setErrorObject({
        percentErrorDiff: percentDiff,
        errorKwH: isOnRange.difference,
      });
      if (!comesFromPvSystemTab) return handleOpenAttestmentModal();
    }

    if (isValidRange && changed && (!hasContracted || !newEqualsCurrent)) {
      if (confirmObject) {
        if (comesFromPvSystemTab) return requestUpdate(data);
        handleOpenAttestmentModal();
      } else {
        setConfirmObject({
          diffKwH,
          percentDiff,
          step: 1,
        });
      }
      return;
    }
    if (!confirmObject) {
      setConfirmObject({
        diffKwH,
        percentDiff,
        step: 2,
      });
      return;
    } else {
      if (comesFromPvSystemTab) return requestUpdate(data);
      return handleOpenAttestmentModal();
    }
  }

  const handleOnInput = function () {
    setErrorObject(undefined);
    setConfirmObject(undefined);
    setAttestmentData(undefined);
  };

  async function requestVoid() {
    setIsVoiding(true);
    try {
      await voidContract({
        accountId: accountId,
        contractId: contract.id,
      }).unwrap();

      navigate(`/accounts/${accountId}`, {
        state: {
          justVoidedContract: true,
          justUpdated1yEstimate: true,
        },
      });
    } catch (error) {
      console.error({ error });
      if (error) {
        toast.error('An error has occurred trying to void the contract. Please try again.');
      } else {
        toast.error('An error has occurred. Please try again later.');
      }
    }
    setIsVoiding(false);
  }

  const loadingState = {
    isChecking,
    isVoiding,
    isUpdating,
  };

  const attestmentLabel = `I attest that the Installed Total Year-1 Production Estimate of ${formatKWHNumber(parseFloat(formData?.systemFirstYearProductionKwh || '0'))} kWh is based on the actual installed pv system design*`;

  return (
    <>
      <Helmet>
        <title>Check Production Value</title>
      </Helmet>
      <HeaderElement
        loadingState={loadingState}
        origin={origin}
        accountName={primaryApplicantName}
        accountId={accountId}
        errorObject={errorObject}
        requestVoid={requestVoid}
      />
      <MainContainer>
        <Box>
          <Formik
            initialValues={{
              systemFirstYearProductionKwh: currentDesignProdKwH,
            }}
            validationSchema={newProductionSchema}
            onSubmit={requestCheck}
            enableReinitialize={true}
            validateOnChange={true}
          >
            <InputForm
              confirmObject={confirmObject}
              errorObject={errorObject}
              loadingState={loadingState}
              activeQuote={contractQuote}
              origin={origin}
              onChange={handleOnInput}
            />
          </Formik>
        </Box>
        <AttestmentModal
          isOpen={isAttestmentModalOpen}
          onDismiss={handleCloseAttestmentModal}
          onAttestmentConfirmed={handleAttestmentConfirmation}
          label={attestmentLabel}
          title={'Production Estimate Acknowledgement'}
          isLoading={isUpdating}
        />
      </MainContainer>
    </>
  );
}
