import { Box } from '@palmetto/palmetto-components';
import { DomesticContentEligibilityLabels, DomesticContentEligibilityTypes } from 'types';
import EventBy from './EventBy';

const SabalDomesticContentEligibilityStatus = ({ event }: { event: any }) => {
  let domesticContentEligibilityLabel;
  switch (event?.data?.domesticContentEligibilityStatus) {
    case DomesticContentEligibilityTypes.eligible:
      domesticContentEligibilityLabel = DomesticContentEligibilityLabels.eligible;
      break;
    case DomesticContentEligibilityTypes.epcOptOut:
      domesticContentEligibilityLabel = DomesticContentEligibilityLabels.epcOptOut;
      break;
    case DomesticContentEligibilityTypes.confirmed:
      domesticContentEligibilityLabel = DomesticContentEligibilityLabels.confirmed;
      break;
    case DomesticContentEligibilityTypes.underConsideration:
      domesticContentEligibilityLabel = DomesticContentEligibilityLabels.underConsideration;
      break;
    default: {
      domesticContentEligibilityLabel = 'Unknown';
    }
  }

  return (
    <Box display="block">
      <EventBy event={event} /> updated Domestic Content Eligibility Status to {` `}
      <strong>{domesticContentEligibilityLabel}</strong>
    </Box>
  );
};

export default SabalDomesticContentEligibilityStatus;
