import { useParams } from 'react-router-dom';
import {
  useDeleteOrganizationEnergyCommunityMutation,
  useGetOrganizationEnergyCommunityQuery,
} from '../../../../../services/pricing';
import { Box, Button, Card, Column, Table, toast, useBreakpoint, useOpenClose } from '@palmetto/palmetto-components';
import { TableLoading } from '../../../../TableLoading';
import { CreateEnergyCommunity } from './modals/CreateEnergyCommunity';
import RequirePricingAdminUser from '@/components/auth/RequirePricingAdminUser';
import { formatITCModifierData } from '@/components/Pricing/ITCModifiers/SpecificITCModifier';
import { ItcModifierSystemType } from 'types';

export const EnergyCommunity = () => {
  const { alias = '' } = useParams<{ alias: string }>();
  const { isPhone } = useBreakpoint();
  const { isOpen: isModalOpen, handleOpen: openModal, handleClose: closeModal } = useOpenClose();
  const { data = [], isLoading, isFetching } = useGetOrganizationEnergyCommunityQuery({ alias }, { skip: !alias });
  const [deleteOrganizationEnergyCommunityMutation] = useDeleteOrganizationEnergyCommunityMutation();

  const handleArchive = async (id: string) => {
    try {
      await deleteOrganizationEnergyCommunityMutation({ alias, id }).unwrap();
      toast.success('Energy Community Archived successfully');
    } catch (e) {
      console.log(e);
      toast.error('Error archiving Energy Community');
    }
  };

  const columnConfig: Column[] = [
    { heading: 'Name', dataKey: 'name' },
    { heading: 'org', dataKey: 'organizationName' },
    { heading: 'State', dataKey: 'state' },
    {
      heading: 'PV Only',
      render: (_cell: any, row: any) => {
        const pv = row.values.find((d: any) => d.systemType === ItcModifierSystemType.pvOnly);
        if (!pv || !pv?.coalTract) return '';
        return (
          <Box childGap="sm">
            <Box direction="row" childGap="xs">
              <Box fontWeight="bold">Coal Closure:</Box> {formatITCModifierData(pv.coalTract)}
            </Box>
            <Box direction="row" childGap="xs">
              <Box fontWeight="bold">Fossil Fuel: </Box>
              {formatITCModifierData(pv.fossilFuel)}
            </Box>
            <Box direction="row" childGap="xs">
              <Box fontWeight="bold">Both:</Box> {formatITCModifierData(pv.combined)}
            </Box>
          </Box>
        );
      },
    },
    {
      heading: 'Storage Only',
      render: (_cell: any, row: any) => {
        const st = row.values.find((d: any) => d.systemType === ItcModifierSystemType.storageOnly);
        if (!st || !st?.coalTract) return '';
        return (
          <Box childGap="sm">
            <Box direction="row" childGap="xs">
              <Box fontWeight="bold">Coal Closure:</Box> {formatITCModifierData(st.coalTract)}
            </Box>
            <Box direction="row" childGap="xs">
              <Box fontWeight="bold">Fossil Fuel: </Box>
              {formatITCModifierData(st.fossilFuel)}
            </Box>
            <Box direction="row" childGap="xs">
              <Box fontWeight="bold">Both:</Box> {formatITCModifierData(st.combined)}
            </Box>
          </Box>
        );
      },
    },
    {
      heading: 'PV and Storage',
      render: (_cell: any, row: any) => {
        const pvStor = row.values.find((d: any) => d.systemType === ItcModifierSystemType.pvAndStorage);
        if (!pvStor || !pvStor?.coalTract) return '';

        return (
          <Box childGap="sm">
            <Box direction="row" childGap="xs">
              <Box fontWeight="bold">Coal Closure:</Box> {formatITCModifierData(pvStor.coalTract)}
            </Box>
            <Box direction="row" childGap="xs">
              <Box fontWeight="bold">Fossil Fuel: </Box>
              {formatITCModifierData(pvStor.fossilFuel)}
            </Box>
            <Box direction="row" childGap="xs">
              <Box fontWeight="bold">Both:</Box> {formatITCModifierData(pvStor.combined)}
            </Box>
          </Box>
        );
      },
    },
    {
      heading: '',
      render: (_cell: any, row: any) => (
        <RequirePricingAdminUser>
          <Button
            size="sm"
            variant="secondary"
            tone="neutral"
            onClick={() => {
              handleArchive(row.id);
            }}
          >
            Archive
          </Button>
        </RequirePricingAdminUser>
      ),
    },
  ];
  return (
    <>
      <CreateEnergyCommunity isModalOpen={isModalOpen} closeModal={closeModal} />
      <Card.Header>
        <RequirePricingAdminUser>
          <Box direction="row" justifyContent="space-between">
            <Box />
            <Button
              as="button"
              variant="primary"
              iconPrefix="add"
              size={{ base: 'sm', desktop: 'md' }}
              aria-label="create adder"
              onClick={() => openModal()}
            >
              {!isPhone && 'Energy Community'}
            </Button>
          </Box>
        </RequirePricingAdminUser>
      </Card.Header>
      <Box margin="0 lg 0 lg">
        {isFetching || isLoading ? (
          <TableLoading rows={12} />
        ) : (
          <Table
            rowKey="id"
            columns={columnConfig as Column[]}
            rows={data?.energyCommunities ?? []}
            isLoading={isLoading || isFetching}
            isScrollable={{ y: true }}
            hasStickyHeader
          />
        )}
      </Box>
    </>
  );
};
