export interface AttestmentData {
  attestedAt?: Date;
  attestmentText: string;
  userId?: string;
}

export enum DomesticContentAttestmentType {
  pvOnly = 'pvOnly',
  storageOnly = 'storageOnly',
  pvAndStorage = 'pvAndStorage',
}

export interface DomesticContentAttestmentData {
  attestedAt: Date;
  userId: string;
  valueConfirmation: boolean;
  resubmission?: boolean;
  attestmentType?: DomesticContentAttestmentType;
}
