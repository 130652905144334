import { currencyFormatter, kwhPriceFormatter } from '@/helpers/currencyFormatter';
import { useGetHoldbacksQuery, useGetItcModifiersQuery, useGetModifiersQuery } from '@/services/pricing';
import { Box, SelectInput } from '@palmetto/palmetto-components';
import { useFormikContext } from 'formik';
import { ItcModifierFormatType, ItcModifierSystemType, ProgramType } from 'types';
import { sortByLabel } from './util';
import { isFieldSelected } from './MappingFormFactory';
import { useEffect } from 'react';
import { formatITCModifierData } from '../ITCModifiers/SpecificITCModifier';

const energyCommunityMap: Record<string, string> = {
  coalTract: 'Coal Tract',
  fossilFuel: 'Fossil Fuel',
  combined: 'Both',
};

const SolarModifiersForm = ({ initialValues }: { initialValues: Record<any, any> }) => {
  const formik = useFormikContext<any>();
  const { data: holdbacks = [] } = useGetHoldbacksQuery({ programType: ProgramType.solar });
  const { data: energyCommunityAdders = [] } = useGetItcModifiersQuery({
    type: 'energyCommunity',
    programType: ProgramType.solar,
  });
  const { data: ppwModifiers = [] } = useGetModifiersQuery({
    type: 'ppwAdjustment',
    programType: ProgramType.solar,
  });
  const { data: domesticContent = [] } = useGetItcModifiersQuery({
    type: 'domesticContent',
    programType: ProgramType.solar,
  });
  const energyCommunityOptions = energyCommunityAdders
    .map((ec: any) => ({ label: ec.name, value: ec.id }))
    .sort(sortByLabel);
  const holdbackOptions = holdbacks.map((hb: any) => ({ label: hb.name, value: hb.id })).sort(sortByLabel);
  const ppwModifierOptions = ppwModifiers.map((ppw: any) => ({ label: ppw.name, value: ppw.id })).sort(sortByLabel);
  const domesticContentModifierOptions = domesticContent
    .map((domestic: any) => ({ label: domestic.name, value: domestic.id }))
    .sort(sortByLabel);

  useEffect(() => {
    if (
      initialValues.holdback &&
      holdbacks.length &&
      !formik.values.selectedHoldback &&
      !formik.touched.selectedHoldback
    ) {
      const selectedHoldback = holdbacks.find((hb: any) => hb.id === initialValues.holdback.value);
      formik.setFieldValue('selectedHoldback', selectedHoldback);
      formik.setFieldTouched('selectedHoldback', true);
    }
    if (
      initialValues.energyCommunity &&
      energyCommunityAdders.length &&
      !formik.values.selectedEnergyCommunity &&
      !formik.touched.selectedEnergyCommunity
    ) {
      const selectedEnergyCommunity = energyCommunityAdders.find(
        (ec: any) => ec.id === initialValues.energyCommunity.value,
      );
      formik.setFieldValue('selectedEnergyCommunity', selectedEnergyCommunity);
      formik.setFieldTouched('selectedEnergyCommunity', true);
    }
    if (
      initialValues.ppwModifier &&
      ppwModifiers.length &&
      !formik.values.selectedPpwModifier &&
      !formik.touched.selectedPpwModifier
    ) {
      const selectedPpwModifier = ppwModifiers.find((ppw: any) => ppw.id === initialValues.ppwModifier.value);
      formik.setFieldValue('selectedPpwModifier', selectedPpwModifier);
      formik.setFieldTouched('selectedPpwModifier', true);
    }
    if (
      initialValues.domesticContent &&
      domesticContent.length &&
      !formik.values.selectedDomesticContentModifier &&
      !formik.touched.selectedDomesticContentModifier
    ) {
      const selectedDomesticContentModifier = domesticContent.find(
        (dc: any) => dc.id === initialValues.domesticContent.value,
      );
      formik.setFieldValue('selectedDomesticContentModifier', selectedDomesticContentModifier);
      formik.setFieldTouched('selectedDomesticContentModifier', true);
    }
  }, [
    initialValues,
    holdbacks,
    energyCommunityAdders,
    ppwModifiers,
    domesticContent,
    formik.values,
    formik.touched,
  ]);

  return (
    <>
      <Box childGap="xl" radius="md" borderWidth="xs" borderColor="separator" padding="lg">
        <Box as="h3" fontWeight="medium" fontSize="md">
          Pricing Adjustments (applies to all projects)
        </Box>
        <Box direction={{ desktop: 'row', base: 'column' }} childGap={{ desktop: '4xl', base: 'sm' }}>
          <Box width={{ desktop: '3xl', base: 'auto' }} padding={{ desktop: '0 0 lg 0', base: '0 0 xs 0' }}>
            <SelectInput
              id="ppwModifier"
              name="ppwModifier"
              label="PPW Modifier"
              value={formik.values.ppwModifier}
              onChange={(event) => {
                const selectedOption = event.target.value;
                formik.setFieldValue('ppwModifier', selectedOption);
                const selectedPpwModifier = isFieldSelected(selectedOption)
                  ? ppwModifiers.find((ppw: any) => ppw.id === selectedOption.value)
                  : null;
                formik.setFieldValue('selectedPpwModifier', selectedPpwModifier);
                formik.setFieldTouched('selectedPpwModifier', true);
              }}
              options={ppwModifierOptions}
              onBlur={formik.handleBlur}
              isClearable
              isDisabled={formik.isSubmitting}
              error={formik.touched.ppwModifier && (formik.errors.ppwModifier as string)}
            />
          </Box>
          {formik.values.selectedPpwModifier && (
            <Box
              radius="md"
              borderWidth="xs"
              borderColor="separator"
              padding="sm lg"
              flex="auto"
              width="auto"
              alignSelf={{ base: 'flex-start', desktop: 'center' }}
            >
              <Box>{kwhPriceFormatter.format(formik.values.selectedPpwModifier.data.amount)} /kWh</Box>
            </Box>
          )}
        </Box>
        <Box childGap="xl">
          <Box direction={{ desktop: 'row', base: 'column' }} childGap={{ desktop: '4xl', base: 'sm' }}>
            <Box width={{ desktop: '3xl', base: 'auto' }} padding={{ desktop: '0 0 lg 0', base: '0 0 xs 0' }}>
              <SelectInput
                id="holdback"
                name="holdback"
                label="Standard Holdback"
                value={formik.values.holdback}
                onChange={(event) => {
                  const selectedOption = event.target.value;
                  formik.setFieldValue('holdback', selectedOption);
                  const selectedHoldback = isFieldSelected(selectedOption)
                    ? holdbacks.find((hb: any) => hb.id === selectedOption.value)
                    : null;
                  formik.setFieldValue('selectedHoldback', selectedHoldback);
                  formik.setFieldTouched('selectedHoldback', true);
                }}
                options={holdbackOptions}
                onBlur={formik.handleBlur}
                isClearable
                isDisabled={formik.isSubmitting}
                error={formik.touched.holdback && (formik.errors.holdback as string)}
              />
            </Box>
            {formik.values.selectedHoldback && (
              <Box
                radius="md"
                borderWidth="xs"
                borderColor="separator"
                padding="sm lg"
                flex="auto"
                width="auto"
                alignSelf={{ base: 'flex-start', desktop: 'center' }}
              >
                <Box>{currencyFormatter.format(formik.values.selectedHoldback.amount)}/W</Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box childGap="xl" radius="md" borderWidth="xs" borderColor="separator" padding="lg" margin="xl 0 0 0">
        <Box as="h3" fontWeight="medium" fontSize="md">
          ITC Modifiers (applies only to eligible projects)
        </Box>
        <Box direction={{ desktop: 'row', base: 'column' }} childGap={{ desktop: '4xl', base: 'sm' }}>
          <Box width={{ desktop: '3xl', base: 'auto' }} padding={{ desktop: '0 0 lg 0', base: '0 0 xs 0' }}>
            <SelectInput
              id="energyCommunity"
              name="energyCommunity"
              label="Energy Community Modifier"
              value={formik.values.energyCommunity}
              onChange={(event) => {
                const selectedOption = event.target.value;
                formik.setFieldValue('energyCommunity', selectedOption);
                const selectedEnergyCommunity = isFieldSelected(selectedOption)
                  ? energyCommunityAdders.find((ec: any) => ec.id === selectedOption.value)
                  : null;
                formik.setFieldValue('selectedEnergyCommunity', selectedEnergyCommunity);
                formik.setFieldTouched('selectedEnergyCommunity', true);
              }}
              options={energyCommunityOptions}
              onBlur={formik.handleBlur}
              isClearable
              isDisabled={formik.isSubmitting}
              error={formik.touched.energyCommunity && (formik.errors.energyCommunity as string)}
            />
          </Box>
          {formik.values.selectedEnergyCommunity && (
            <SelectedEnergyCommunityModifier
              selectedEnergyCommunityModifier={formik.values.selectedEnergyCommunity.data}
            />
          )}
        </Box>
        <Box childGap="xl">
          <Box direction={{ desktop: 'row', base: 'column' }} childGap={{ desktop: '4xl', base: 'sm' }}>
            <Box width={{ desktop: '3xl', base: 'auto' }} padding={{ desktop: '0 0 lg 0', base: '0 0 xs 0' }}>
              <SelectInput
                id="domesticContentModifier"
                name="domesticContentModifier"
                label="Domestic Content Modifier"
                value={formik.values.domesticContent}
                onChange={(event) => {
                  const selectedOption = event.target.value;
                  formik.setFieldValue('domesticContent', selectedOption);
                  const selectedDomesticContentModifier = isFieldSelected(selectedOption)
                    ? domesticContent.find((dc: any) => dc.id === selectedOption.value)
                    : null;
                  formik.setFieldValue('selectedDomesticContentModifier', selectedDomesticContentModifier);
                  formik.setFieldTouched('selectedDomesticContentModifier', true);
                }}
                options={domesticContentModifierOptions}
                onBlur={formik.handleBlur}
                isClearable
                isDisabled={formik.isSubmitting}
                error={formik.touched.domesticContentModifier && (formik.errors.domesticContentModifier as string)}
              />
            </Box>
            {formik.values.selectedDomesticContentModifier && (
              <SelectedDomesticContentModifier
                selectedDomesticContentModifier={formik.values.selectedDomesticContentModifier.data}
              />
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export const ModifiersForm = ({
  programType,
  initialValues,
}: {
  programType: ProgramType;
  initialValues: Record<any, any>;
}) => {
  switch (programType) {
    case ProgramType.solar:
    case ProgramType.newHomes:
      return <SolarModifiersForm initialValues={initialValues} />;
    default:
      return null;
  }
};

const SelectedDomesticContentModifier = ({
  selectedDomesticContentModifier,
}: {
  selectedDomesticContentModifier: any;
}) => {
  const pvOnly = selectedDomesticContentModifier.find((d: any) => d.systemType === ItcModifierSystemType.pvOnly);
  const storageOnly = selectedDomesticContentModifier.find(
    (d: any) => d.systemType === ItcModifierSystemType.storageOnly,
  );
  const pvAndStorage = selectedDomesticContentModifier.find(
    (d: any) => d.systemType === ItcModifierSystemType.pvAndStorage,
  );
  if (!pvOnly) {
    return null;
  }
  return (
    <Box
      radius="md"
      borderWidth="xs"
      borderColor="separator"
      padding="sm lg"
      flex="auto"
      width="auto"
      gap="4xl"
      direction="row"
      alignSelf={{ base: 'flex-start', desktop: 'center' }}
    >
      <Box childGap="sm">
        <Box fontSize="xs" fontWeight="bold">
          PV Only
        </Box>
        <Box>Modifier: {formatITCModifierData(pvOnly.modifier)}</Box>
        <Box>Holdback: {formatITCModifierData(pvOnly.holdback)}</Box>
        <Box childGap="sm">
          Net:{' '}
          {pvOnly.modifier?.format === ItcModifierFormatType.percent
            ? `${(parseFloat(pvOnly.modifier.value) - parseFloat(pvOnly.holdback?.value)).toFixed(2)}%`
            : `$${(parseFloat(pvOnly.modifier?.value) - parseFloat(pvOnly.holdback?.value)).toFixed(2)}/W`}
        </Box>
      </Box>
      <Box childGap="sm">
        <Box fontSize="xs" fontWeight="bold">
          Storage Only
        </Box>
        {storageOnly && (
          <Box childGap="sm">
            <Box>Modifier: {formatITCModifierData(storageOnly.modifier)}</Box>
            <Box>Holdback: {formatITCModifierData(storageOnly.holdback)}</Box>
            <Box childGap="sm">
              Net:{' '}
              {storageOnly.modifier?.format === ItcModifierFormatType.percent
                ? `${(parseFloat(storageOnly.modifier.value) - parseFloat(storageOnly.holdback?.value)).toFixed(2)}%`
                : `$${(parseFloat(storageOnly.modifier?.value) - parseFloat(storageOnly.holdback?.value)).toFixed(2)}/W`}
            </Box>
          </Box>
        )}
      </Box>
      <Box childGap="sm">
        <Box fontSize="xs" fontWeight="bold">
          PV and Storage
        </Box>
        {pvAndStorage && (
          <Box childGap="sm">
            <Box>Modifier: {formatITCModifierData(pvAndStorage.modifier)}</Box>
            <Box>Holdback: {formatITCModifierData(pvAndStorage.holdback)}</Box>
            <Box>
              Net:{' '}
              {pvAndStorage.modifier?.format === ItcModifierFormatType.percent
                ? `${(parseFloat(pvAndStorage.modifier.value) - parseFloat(pvAndStorage.holdback?.value)).toFixed(2)}%`
                : `$${(parseFloat(pvAndStorage.modifier?.value) - parseFloat(pvAndStorage.holdback?.value)).toFixed(2)}/W`}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const SelectedEnergyCommunityModifier = ({
  selectedEnergyCommunityModifier,
}: {
  selectedEnergyCommunityModifier: any;
}) => {
  const pvOnly = selectedEnergyCommunityModifier.find((d: any) => d.systemType === ItcModifierSystemType.pvOnly);
  const storageOnly = selectedEnergyCommunityModifier.find(
    (d: any) => d.systemType === ItcModifierSystemType.storageOnly,
  );
  const pvAndStorage = selectedEnergyCommunityModifier.find(
    (d: any) => d.systemType === ItcModifierSystemType.pvAndStorage,
  );
  if (!pvOnly) {
    return null;
  }

  return (
    <Box
      radius="md"
      borderWidth="xs"
      borderColor="separator"
      padding="sm lg"
      flex="auto"
      width="auto"
      gap="4xl"
      direction="row"
      alignSelf={{ base: 'flex-start', desktop: 'center' }}
    >
      <Box childGap="sm">
        <Box fontSize="xs" fontWeight="bold">
          PV Only
        </Box>
        {Object.keys(pvOnly)
          .map((key: string) =>
            energyCommunityMap[key] ? (
              <Box key={key}>
                {energyCommunityMap[key]}: {formatITCModifierData(pvOnly[key])}
              </Box>
            ) : null,
          )
          .filter((x) => !!x)}
      </Box>
      <Box childGap="sm">
        <Box fontSize="xs" fontWeight="bold">
          Storage Only
        </Box>
        {storageOnly &&
          Object.keys(storageOnly)
            .map((key: string) =>
              energyCommunityMap[key] ? (
                <Box key={key}>
                  {energyCommunityMap[key]}: {formatITCModifierData(storageOnly[key])}
                </Box>
              ) : null,
            )
            .filter((x) => !!x)}
      </Box>
      <Box childGap="sm">
        <Box fontSize="xs" fontWeight="bold">
          PV and Storage
        </Box>
        {pvAndStorage &&
          Object.keys(pvAndStorage)
            .map((key: string) =>
              energyCommunityMap[key] ? (
                <Box key={key}>
                  {energyCommunityMap[key]}: {formatITCModifierData(pvAndStorage[key])}
                </Box>
              ) : null,
            )
            .filter((x) => !!x)}
      </Box>
    </Box>
  );
};
