import * as yup from 'yup';
import { Box, FormikTextInput } from '@palmetto/palmetto-components';
import { Field, useFormikContext } from 'formik';
import { useCallback } from 'react';

const positiveNumber = yup.number().min(0, 'Must be a positive number').typeError('Must be a number');
const percent = yup
  .number()
  .min(0, 'Must be a positive number')
  .max(100, 'Must be less than 100')
  .typeError('Must be a number');
const exclusiveCheck = (value: any, thisField: any) =>
  value?.[0]
    ? thisField.oneOf([null as any, undefined, 0, '0', ''], 'Only a % or per watt can be submitted')
    : thisField.concat(percent);

export const createEnergyCommunityModifierValidationSchema = yup.object({
  ['pvOnly-coalTractPercent']: yup.number().when('pvOnly-coalTractPerWatt', exclusiveCheck),
  ['pvOnly-coalTractPerWatt']: positiveNumber,
  ['pvOnly-fossilFuelPercent']: yup.number().when('pvOnly-fossilFuelPerWatt', exclusiveCheck),
  ['pvOnly-fossilFuelPerWatt']: positiveNumber,
  ['pvOnly-combinedPercent']: yup.number().when('pvOnly-combinedPerWatt', exclusiveCheck),
  ['pvOnly-combinedPerWatt']: positiveNumber,
  ['storageOnly-coalTractPercent']: yup.number().when('storageOnly-coalTractPerWatt', exclusiveCheck),
  ['storageOnly-coalTractPerWatt']: positiveNumber,
  ['storageOnly-fossilFuelPercent']: yup.number().when('storageOnly-fossilFuelPerWatt', exclusiveCheck),
  ['storageOnly-fossilFuelPerWatt']: positiveNumber,
  ['storageOnly-combinedPercent']: yup.number().when('storageOnly-combinedPerWatt', exclusiveCheck),
  ['storageOnly-combinedPerWatt']: positiveNumber,
  ['pvAndStorage-coalTractPercent']: yup.number().when('pvAndStorage-coalTractPerWatt', exclusiveCheck),
  ['pvAndStorage-coalTractPerWatt']: positiveNumber,
  ['pvAndStorage-fossilFuelPercent']: yup.number().when('pvAndStorage-fossilFuelPerWatt', exclusiveCheck),
  ['pvAndStorage-fossilFuelPerWatt']: positiveNumber,
  ['pvAndStorage-combinedPercent']: yup.number().when('pvAndStorage-combinedPerWatt', exclusiveCheck),
  ['pvAndStorage-combinedPerWatt']: positiveNumber,
});

export const CreateEnergyCommunityModifierForm = ({ prefix }: { prefix: string }) => {
  const { setFieldTouched, setFieldValue } = useFormikContext();

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value, exclusive } = event.target as any;
      setFieldValue(name, value);
      if (exclusive) {
        setFieldTouched(exclusive, true, true);
      }
    },
    [setFieldTouched, setFieldValue],
  );

  return (
    <Box direction="column" childGap="md">
      <Box childGap="lg">
        <Box fontWeight="medium" color="body-primary">
          Credits
          <Box color="body-primary">Enter a % or $/Watt value for the modifier</Box>
        </Box>
        <Box fontWeight="bold" color="body-primary">
          Coal Closure
        </Box>
        <Box direction={{ base: 'column', desktop: 'row' }} childGap="xl" width="auto" flex="auto">
          <Field
            component={FormikTextInput}
            label=""
            name={`${prefix}-coalTractPercent`}
            id={`${prefix}-coalTractPercent`}
            type="number"
            suffix="%"
            onChange={handleChange}
            exclusive={`${prefix}-coalTractPerWatt`}
          />
          <Field
            component={FormikTextInput}
            label=""
            name={`${prefix}-coalTractPerWatt`}
            id={`${prefix}-coalTractPerWatt`}
            type="number"
            prefix="$"
            suffix="/W"
            onChange={handleChange}
            exclusive={`${prefix}-coalTractPercent`}
          />
        </Box>
        <Box fontWeight="bold" color="body-primary">
          Fossil Fuel Employment
        </Box>
        <Box direction={{ base: 'column', desktop: 'row' }} childGap="xl" width="auto" flex="auto">
          <Field
            component={FormikTextInput}
            label=""
            name={`${prefix}-fossilFuelPercent`}
            id={`${prefix}-fossilFuelPercent`}
            type="number"
            suffix="%"
          />
          <Field
            component={FormikTextInput}
            label=""
            name={`${prefix}-fossilFuelPerWatt`}
            id={`${prefix}-fossilFuelPerWatt`}
            type="number"
            prefix="$"
            suffix="/W"
          />
        </Box>
        <Box fontWeight="bold" color="body-primary">
          Both
        </Box>
        <Box direction={{ base: 'column', desktop: 'row' }} childGap="xl" width="auto" flex="auto">
          <Field
            component={FormikTextInput}
            label=""
            name={`${prefix}-combinedPercent`}
            id={`${prefix}-combinedPercent`}
            type="number"
            suffix="%"
          />
          <Field
            component={FormikTextInput}
            label=""
            name={`${prefix}-combinedPerWatt`}
            id={`${prefix}-combinedPerWatt`}
            type="number"
            prefix="$"
            suffix="/W"
          />
        </Box>
      </Box>
    </Box>
  );
};
