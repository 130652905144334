import { ProgramType, PayoutEventMap, PaymentPlan, WithObjectIdsAsString } from 'types';
import { Box, Table, RadioGroup } from '@palmetto/palmetto-components';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

interface PaymentPlanTableProps {
  paymentPlans: Omit<PaymentPlan, 'id'>[];
  programType: ProgramType;
  selectedPaymentPlanId?: string;
  selectPaymentPlan?(id: string): void;
  paymentPlanClickable?: boolean;
  showFirstColumn?: boolean;
}

const getPaymentScheduleColumns = (
  programType: ProgramType,
  selectedPaymentPlanId: string | undefined,
  selectPaymentPlan?: (id: string) => void,
  paymentPlanClickable?: boolean,
  showFirstColumn?: boolean,
) => {
  const baseColumns = showFirstColumn
    ? [
        {
          heading: '',
          dataKey: 'name',
          render: (cell?: any, row?: any) => (
            <Box fontWeight="medium" as="span" display="inline">
              {paymentPlanClickable ? (
                <Link to={`/admin/payouts/${programType}/payment-plans/${row.id}`}>{row.name}</Link>
              ) : (
                cell
              )}
            </Box>
          ),
        },
      ]
    : [];

  const dynamicColumns = PayoutEventMap[programType]
    .sort((a, b) => a.order - b.order)
    .map((entry) => ({
      heading: entry?.name,
      dataKey: entry.event,
      render: (_cell?: any, row?: any, _rowIndex?: number) => {
        const valueEntry = row.paymentSchedule?.find((event: any) => event.event === entry.event);
        return (
          <Box fontWeight="medium" as="span" display="inline">
            {valueEntry?.paymentPercent ? `${valueEntry.paymentPercent * 100}%` : '-'}
          </Box>
        );
      },
    }));

  if (selectPaymentPlan) {
    baseColumns.unshift({
      heading: '',
      dataKey: 'id',
      render: (_cell?: any, row?: any) =>
        row ? (
          <RadioGroup
            value={selectedPaymentPlanId}
            name="selectedPaymentPlan"
            onChange={() => selectPaymentPlan(row.id)}
            options={[{ id: row.id, value: row.id, label: '' }]}
          />
        ) : (
          <></>
        ),
    });
  }

  return [...baseColumns, ...dynamicColumns];
};

export const PaymentPlanTable = ({
  paymentPlans,
  programType,
  selectedPaymentPlanId,
  selectPaymentPlan,
  paymentPlanClickable,
  showFirstColumn = true,
}: PaymentPlanTableProps) => {
  const columns = useMemo(
    () =>
      getPaymentScheduleColumns(
        programType,
        selectedPaymentPlanId,
        selectPaymentPlan,
        paymentPlanClickable,
        showFirstColumn,
      ),
    [programType, selectedPaymentPlanId, selectPaymentPlan, paymentPlanClickable],
  );
  return paymentPlans ? <Table rowKey="id" columns={columns} rows={paymentPlans} /> : null;
};
