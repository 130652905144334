import FormSection from '../Forms/FormSection';
import {
  BillingDocumentTypes,
  InstallDocumentTypes,
  DomesticContentAttestmentType,
  QueueType,
  QueueStatus,
} from 'types';
import { DocumentList } from '../MilestonePackagePartials/Documents/DocumentList';
import { DomesticContentReview } from './DomesticContentReview';
import { Badge, BadgeVariant, Box, Button } from '@palmetto/palmetto-components';
import { useFormikContext } from 'formik';
import { EquipmentListSection } from '../SystemDesign/EquipmentSection';
import RequirePalmettoFinanceUser from '../auth/RequirePalmettoFinanceUser';
import RequirePermissions from '../auth/requirePermissions';
import { useNavigate, useParams } from 'react-router-dom';

import { useGetQueueByAccountIdAndTypeQuery } from '@/services/queues';

type DomesticContentProps = {
  shouldDisplayDomesticContentReviewInformation?: boolean;
  attestmentType?: DomesticContentAttestmentType;
};

const attestmentTypeBadgeMap: {
  [key in DomesticContentAttestmentType]: {
    message: string;
    variant: BadgeVariant;
  };
} = {
  pvOnly: {
    message: 'Solar Only',
    variant: 'primary',
  },
  storageOnly: {
    message: 'Storage Only',
    variant: 'secondary',
  },
  pvAndStorage: {
    message: 'Solar + Storage',
    variant: 'tertiary',
  },
} as const;

export const DomesticContent = ({
  shouldDisplayDomesticContentReviewInformation,
  attestmentType,
}: DomesticContentProps) => {
  const { values } = useFormikContext<any>();
  const { id } = useParams<{ id: any }>();
  const navigate = useNavigate();

  const { data: domesticContentQueue, isLoading: isDomesticContentQueueLoading } =
    useGetQueueByAccountIdAndTypeQuery({
      accountId: id,
      type: QueueType.domesticContent,
    });

  const domesticContentAttestmentTypeBadge = attestmentType ? attestmentTypeBadgeMap[attestmentType] : undefined;

  const showDCReSubmitAttestation =
    !domesticContentQueue ||
    domesticContentQueue.status === QueueStatus.inProgress ||
    domesticContentQueue.status === QueueStatus.rejected;

  return (
    <FormSection
      title="Domestic Content"
      description={
        <Box gap="xs">
          <Box>This project must pass review for Domestic Content (DC) compliance in order to qualify for DC incentive pricing.</Box>
          <Box display="flex" gap="xs" alignItems="flex-start">
            <Badge
              variant={domesticContentAttestmentTypeBadge?.variant || 'default'}
              message={domesticContentAttestmentTypeBadge?.message || ''}
            />
          </Box>
        </Box>}
      {...(showDCReSubmitAttestation && {
        headerChildren: (
          <>
            <Box>
              Need to change your Domestic Content review type? Click the button below to return to the attestation
              page
            </Box>
            <Button
              onClick={async () => {
                navigate(`/accounts/${id}/domestic-content-value-confirmation`);
              }}
              size="sm"
              variant="secondary"
              isLoading={isDomesticContentQueueLoading}
              disabled={isDomesticContentQueueLoading}
            >
              {'Re-submit Attestation'}
            </Button>
          </>
        ),
      })}

    >
      {isDomesticContentQueueLoading && (
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          backgroundColor="white"
          opacity="0.7"
          zIndex="overlay"
        />
      )}
      <Box gap="xl">
        {shouldDisplayDomesticContentReviewInformation && (
          <>
            <DomesticContentReview attestmentType={attestmentType} />
            <DocumentList
              documentTypeFilter={[InstallDocumentTypes.domesticContentDocuments]}
              title="Domestic Content Documents"
              showTypeLabelOnItems={false}
              baseCategory={InstallDocumentTypes.domesticContentDocuments}
            />
          </>
        )}
        <DocumentList
          documentTypeFilter={[
            InstallDocumentTypes.invoice,
            InstallDocumentTypes.billOfMaterials,
            BillingDocumentTypes.incomingCedInvoice,
          ]}
          title="Invoice/BOM Document"
          showTypeLabelOnItems={true}
          baseCategory={InstallDocumentTypes.domesticContentDocuments}
        />
        <RequirePalmettoFinanceUser>
          <RequirePermissions permissions={['admin']}>
            <EquipmentListSection installationData={values} />
          </RequirePermissions>
        </RequirePalmettoFinanceUser>
      </Box>
    </FormSection>
  );
};
