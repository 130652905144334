import csv from 'csvtojson';
import { ValidationError } from 'yup';

const objectIdRegexPattern = /^[0-9a-fA-F]{24}$/;

interface ValidateAccountIdsResult {
  isValid: boolean;
  accountIds?: string[];
  error?: ValidationError;
}

/**
 * Validates a string of account IDs, ensuring they are valid MongoDB ObjectIDs
 * @param value - String containing account IDs (can be CSV format)
 * @returns Object containing validation result, parsed account IDs (if valid), and error (if invalid)
 */
export async function validateAccountIds(value: string): Promise<ValidateAccountIdsResult> {
  try {
    if (!value) {
      return { isValid: false };
    }

    const parseResult = await csv({ noheader: true, trim: true, output: 'csv' }).fromString(value);
    let accountIds = parseResult.flat();

    if (accountIds.length === 0) {
      return { isValid: false };
    }

    accountIds = accountIds.filter((id) => id.trim().length > 0);
    const invalidIds = accountIds.filter((id) => !objectIdRegexPattern.test(id));

    if (invalidIds.length > 0) {
      return {
        isValid: false,
        error: new ValidationError(`Invalid account ids: ${invalidIds.join(', ')}`, value, 'accountIds'),
      };
    }

    return {
      isValid: true,
      accountIds,
    };
  } catch (_e) {
    return { isValid: false };
  }
}

/**
 * Creates a Yup test function for validating account IDs
 * @returns A test function that can be used in Yup schema validation
 */
export function createAccountIdsValidator() {
  return async function accountIdsValidator(value: string) {
    const result = await validateAccountIds(value);
    if (!result.isValid && result.error) {
      throw result.error;
    }
    return result.isValid;
  };
}
