import { PriceSheetMappingDocument, ProgramType } from 'types';
import { api } from './api';
import _ from 'lodash';

const buildQueryString = (params?: string[]): Record<string, string> => {
  if (!params || params.length === 0) {
    return {};
  }

  return params.reduce((acc: Record<string, string>, param: string) => {
    acc[param] = 'true';
    return acc;
  }, {});
};

export const pricingApi = api.injectEndpoints({
  endpoints: (build) => ({
    createConstructionAdder: build.mutation<any, { data: any; programType: ProgramType }>({
      query: ({ data, programType }) => ({
        url: `/admin/${programType}/construction-adders`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (_result, _error, { data }) => [
        { type: 'ConstructionAdders', id: data.adderType },
        { type: 'History', id: 'LIST' },
      ],
    }),
    editConstructionAdder: build.mutation<any, { id: string; data: any }>({
      query: ({ id, data }) => ({ url: `/admin/construction-adders/${id}`, method: 'PATCH', body: data }),
      invalidatesTags: (_result, _error, { data }) => [
        { type: 'ConstructionAdders', id: data.adderType },
        { type: 'History', id: 'LIST' },
      ],
    }),
    createHoldback: build.mutation<any, { data: any }>({
      query: ({ data }) => ({ url: `/admin/holdbacks`, method: 'POST', body: data }),
      invalidatesTags: () => [
        { type: 'Holdbacks', id: 'LIST' },
        { type: 'History', id: 'LIST' },
      ],
    }),
    editHoldback: build.mutation<any, { id: string; data: any }>({
      query: ({ id, data }) => ({ url: `/admin/holdbacks/${id}`, method: 'PATCH', body: data }),
      invalidatesTags: () => [
        { type: 'Holdbacks', id: 'LIST' },
        { type: 'History', id: 'LIST' },
      ],
    }),
    createModifier: build.mutation<any, { data: any }>({
      query: ({ data }) => ({ url: `/admin/modifiers`, method: 'POST', body: data }),
      invalidatesTags: (_result, _error, { data }) => [
        { type: 'Modifiers', id: data.modifierType },
        { type: 'History', id: 'LIST' },
      ],
    }),
    createItcModifier: build.mutation<any, { data: any }>({
      query: ({ data }) => ({ url: `/admin/itc-modifiers`, method: 'POST', body: data }),
      invalidatesTags: (_result, _error, { data }) => [
        { type: 'Modifiers', id: data.modifierType },
        { type: 'History', id: 'LIST' },
      ],
    }),
    editModifier: build.mutation<any, { id: string; data: any }>({
      query: ({ id, data }) => ({ url: `/admin/modifiers/${id}`, method: 'PATCH', body: data }),
      invalidatesTags: (_result, _error, { data }) => [
        { type: 'Modifiers', id: data.modifierType },
        { type: 'History', id: 'LIST' },
      ],
    }),
    editItcModifier: build.mutation<any, { id: string; data: any }>({
      query: ({ id, data }) => ({ url: `/admin/itc-modifiers/${id}`, method: 'PATCH', body: data }),
      invalidatesTags: (_result, _error, { data }) => [
        { type: 'Modifiers', id: data.modifierType },
        { type: 'History', id: 'LIST' },
      ],
    }),
    createPriceSheetMapping: build.mutation<any, { data: any; programType: ProgramType }>({
      query: ({ data, programType }) => ({
        url: `/admin/${programType}/price-sheets/mappings`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: () => [
        { type: 'PriceSheetMappings', id: 'LIST' },
        { type: 'History', id: 'LIST' },
      ],
    }),
    applyBulkPriceSheetMappings: build.mutation<any, { data: any; programType: ProgramType }>({
      query: ({ data, programType }) => ({
        url: `/admin/${programType}/price-sheets/bulk-mappings`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: () => [
        { type: 'PriceSheetMappings', id: 'LIST' },
        { type: 'History', id: 'LIST' },
      ],
    }),
    getPriceSheetMappingById: build.query<PriceSheetMappingDocument<any>, { id: string }>({
      query: ({ id }) => ({ url: `/admin/price-sheets/mappings/${id}` }),
      providesTags: (_result, _error, { id }) => [{ type: 'PriceSheetMappings', id }],
    }),
    getConstructionAdders: build.query<any, { type: string; programType: string }>({
      query: ({ type, programType }) => ({
        url: `/admin/${programType}/construction-adders?${new URLSearchParams({ adderType: type })}`,
      }),
      providesTags: (_result, _error, { type }) => [{ type: 'ConstructionAdders', id: type }],
    }),
    getHoldbacks: build.query<any, { programType: string }>({
      query: ({ programType }) => ({ url: `/admin/${programType}/holdbacks` }),
      providesTags: () => [{ type: 'Holdbacks', id: 'LIST' }],
    }),
    getOrganizationPricingHoldbacks: build.query<any, { alias: string }>({
      query: ({ alias }) => ({ url: `/organizations/${alias}/pricing/holdbacks` }),
      providesTags: (_result, _error, { alias }) => [{ type: 'OrganizationHoldbacks', id: alias }],
    }),
    createOrganizationPricingHoldbacks: build.mutation<any, { alias: string; data: any }>({
      query: ({ alias, data }) => ({ url: `/organizations/${alias}/pricing/holdbacks`, method: 'POST', body: data }),
      invalidatesTags: (_result, _error, { alias }) => [{ type: 'OrganizationHoldbacks', id: alias }],
    }),
    deleteOrganizationPricingHoldbacks: build.mutation<any, { alias: string; id: string }>({
      query: ({ alias, id }) => ({ url: `/organizations/${alias}/pricing/holdbacks/${id}`, method: 'DELETE' }),
      invalidatesTags: (_result, _error, { alias }) => [{ type: 'OrganizationHoldbacks', id: alias }],
    }),
    createOrganizationEnergyCommunity: build.mutation<any, { alias: string; data: any }>({
      query: ({ alias, data }) => ({
        url: `/organizations/${alias}/pricing/energy-community`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (_result, _error, { alias }) => [{ type: 'OrganizationEnergyCommunity', id: alias }],
    }),
    deleteOrganizationEnergyCommunity: build.mutation<any, { alias: string; id: string }>({
      query: ({ alias, id }) => ({
        url: `/organizations/${alias}/pricing/energy-community/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, { alias }) => [{ type: 'OrganizationEnergyCommunity', id: alias }],
    }),
    getOrganizationEnergyCommunity: build.query<any, { alias: string }>({
      query: ({ alias }) => ({ url: `/organizations/${alias}/pricing/energy-community` }),
      providesTags: (_result, _error, { alias }) => [{ type: 'OrganizationEnergyCommunity', id: alias }],
    }),
    createOrganizationPpwModifiers: build.mutation<any, { alias: string; data: any }>({
      query: ({ alias, data }) => ({
        url: `/organizations/${alias}/pricing/ppw-modifiers`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (_result, _error, { alias }) => [{ type: 'OrganizationPpwModifiers', id: alias }],
    }),
    deleteOrganizationPpwModifier: build.mutation<any, { alias: string; id: string }>({
      query: ({ alias, id }) => ({
        url: `/organizations/${alias}/pricing/ppw-modifiers/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, { alias }) => [{ type: 'OrganizationPpwModifiers', id: alias }],
    }),
    getOrganizationPpwModifiers: build.query<any, { alias: string }>({
      query: ({ alias }) => ({ url: `/organizations/${alias}/pricing/ppw-modifiers` }),
      providesTags: (_result, _error, { alias }) => [{ type: 'OrganizationPpwModifiers', id: alias }],
    }),
    createOrganizationDomesticContent: build.mutation<any, { alias: string; data: any }>({
      query: ({ alias, data }) => ({
        url: `/organizations/${alias}/pricing/domestic-content`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (_result, _error, { alias }) => [{ type: 'OrganizationDomesticContent', id: alias }],
    }),
    getOrganizationDomesticContent: build.query<any, { alias: string }>({
      query: ({ alias }) => ({ url: `/organizations/${alias}/pricing/domestic-content` }),
      providesTags: (_result, _error, { alias }) => [{ type: 'OrganizationDomesticContent', id: alias }],
    }),
    deleteOrganizationDomesticContent: build.mutation<any, { alias: string; id: string }>({
      query: ({ alias, id }) => ({
        url: `/organizations/${alias}/pricing/domestic-content/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, { alias }) => [{ type: 'OrganizationDomesticContent', id: alias }],
    }),
    getModifiers: build.query<any, { programType: string; type: string }>({
      query: ({ programType, type }) => ({
        url: `/admin/${programType}/modifiers?${new URLSearchParams({ modifierType: type })}`,
      }),
      providesTags: (_result, _error, { type }) => [{ type: 'Modifiers', id: type }],
    }),
    getItcModifiers: build.query<any, { programType: string; type: string }>({
      query: ({ programType, type }) => ({
        url: `/admin/${programType}/itc-modifiers?${new URLSearchParams({ modifierType: type })}`,
      }),
      providesTags: (_result, _error, { type }) => [{ type: 'Modifiers', id: type }],
    }),
    getPriceSheets: build.query<any[], Record<any, any>>({
      query: ({ programType, ...queryParams }) => {
        return {
          url: `admin/${programType}/price-sheets${
            queryParams &&
            '?' +
              new URLSearchParams({
                ...queryParams,
              })
          }`,
        };
      },
      providesTags: () => [{ type: 'PriceSheets', id: 'LIST' }],
    }),
    uploadPriceSheet: build.mutation<any, { formData: FormData; programType: ProgramType }>({
      query: ({ formData, programType }) => ({
        url: `admin/${programType}/price-sheets`,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: () => [
        { type: 'PriceSheets', id: 'LIST' },
        { type: 'History', id: 'LIST' },
      ],
    }),
    getPriceSheetDetails: build.query<any, { id: string }>({
      query: ({ id }) => ({ url: `/admin/price-sheets/${id}` }),
      providesTags: (_result, _error, { id }) => [{ type: 'PriceSheets', id }],
    }),
    editPriceSheet: build.mutation<any, { id: string; data: any }>({
      query: ({ id, data }) => ({ url: `/admin/price-sheets/${id}`, method: 'PATCH', body: data }),
      invalidatesTags: (_result, _error, { id }) => [
        { type: 'PriceSheets', id: 'LIST' },
        { type: 'PriceSheets', id },
        { type: 'History', id: 'LIST' },
      ],
    }),
    getPriceSheetMappingsById: build.query<any, { id: string }>({
      query: ({ id }) => ({ url: `/admin/price-sheets/${id}/mappings` }),
    }),
    getPricing: build.query<
      any[],
      { id: string; adders?: string[]; backupBatteryCost?: number; includeDomesticContentPricing?: boolean }
    >({
      query: ({ id, adders, backupBatteryCost, includeDomesticContentPricing }) => ({
        url: `/v2/accounts/${id}/pricing?${new URLSearchParams(buildQueryString(adders))}${backupBatteryCost !== undefined ? '&' + new URLSearchParams({ backupBatteryCost: backupBatteryCost.toString() }) : ''}${includeDomesticContentPricing !== undefined ? '&' + new URLSearchParams({ includeDomesticContentPricing: includeDomesticContentPricing.toString() }) : ''}`,
      }),
      providesTags: (_result, _error, { id }) => [{ type: 'Pricing', id }],
    }),
    getHVACPricing: build.query<any[], { accountId: string; totalFinancedAmount: number }>({
      query: ({ accountId, totalFinancedAmount }) => ({
        url: `/v2/accounts/${accountId}/pricing/hvac?totalFinancedAmount=${totalFinancedAmount || 0}`,
      }),
      providesTags: (_result, _error, { accountId }) => [{ type: 'Pricing', id: accountId }],
    }),
    getDOEPRPricing: build.query<
      any[],
      {
        id: string;
        adders?: string[];
        backupBatteryManufacturer?: number;
        backupBatteryModelNumber?: string;
        backupBatteryQuantity?: number;
        solarReadinessCost?: number;
      }
    >({
      query: ({
        id,
        adders,
        backupBatteryManufacturer,
        backupBatteryModelNumber,
        backupBatteryQuantity,
        solarReadinessCost,
      }) => {
        let urlSearchParams = new URLSearchParams();
        if (backupBatteryManufacturer !== undefined) {
          urlSearchParams.append('backupBatteryManufacturer', `${backupBatteryManufacturer}`);
        }
        if (backupBatteryModelNumber !== undefined) {
          urlSearchParams.append('backupBatteryModelNumber', `${backupBatteryModelNumber}`);
        }
        if (backupBatteryQuantity !== undefined) {
          urlSearchParams.append('backupBatteryQuantity', `${backupBatteryQuantity}`);
        }
        if (solarReadinessCost !== undefined) {
          urlSearchParams.append('solarReadinessCost', `${solarReadinessCost}`);
        }
        return {
          url: `/v2/accounts/${id}/pricing/doe-pr?${new URLSearchParams(buildQueryString(adders))}${urlSearchParams.size > 0 ? `&${urlSearchParams.toString()}` : ''}`,
        };
      },
      providesTags: (_result, _error, { id }) => [{ type: 'Pricing', id }],
    }),
    getPriceSheetMappings: build.query<any, Record<any, any>>({
      query: ({ programType, ...queryParams }) => {
        return {
          url: `/admin/${programType}/price-sheets/mappings${
            queryParams &&
            '?' +
              new URLSearchParams({
                ...queryParams,
              })
          }`,
        };
      },
      providesTags: () => [{ type: 'PriceSheetMappings', id: 'LIST' }],
    }),
    getProductPriceCaps: build.query<any, { accountId: string; productType: string }>({
      query: ({ accountId, productType }) => ({ url: `/accounts/${accountId}/pricing/price-caps/${productType}` }),
    }),
    getPriceSheetMappingsListFilters: build.query<any, { programType: ProgramType }>({
      query: ({ programType }) => {
        return {
          url: `/admin/${programType}/price-sheets/mappings/filters`,
        };
      },
    }),
  }),
});

export const {
  useCreateConstructionAdderMutation,
  useCreateHoldbackMutation,
  useCreateModifierMutation,
  useCreateItcModifierMutation,
  useCreateOrganizationEnergyCommunityMutation,
  useCreateOrganizationPpwModifiersMutation,
  useCreateOrganizationDomesticContentMutation,
  useCreateOrganizationPricingHoldbacksMutation,
  useCreatePriceSheetMappingMutation,
  useDeleteOrganizationEnergyCommunityMutation,
  useDeleteOrganizationPpwModifierMutation,
  useDeleteOrganizationDomesticContentMutation,
  useDeleteOrganizationPricingHoldbacksMutation,
  useEditConstructionAdderMutation,
  useEditHoldbackMutation,
  useEditModifierMutation,
  useEditItcModifierMutation,
  useEditPriceSheetMutation,
  useGetConstructionAddersQuery,
  useGetHoldbacksQuery,
  useGetModifiersQuery,
  useGetItcModifiersQuery,
  useGetOrganizationEnergyCommunityQuery,
  useGetOrganizationDomesticContentQuery,
  useGetOrganizationPricingHoldbacksQuery,
  useGetPriceSheetDetailsQuery,
  useGetPriceSheetMappingByIdQuery,
  useGetPriceSheetMappingsByIdQuery,
  useGetPriceSheetMappingsListFiltersQuery,
  useGetPriceSheetMappingsQuery,
  useGetPriceSheetsQuery,
  useGetPricingQuery,
  useGetProductPriceCapsQuery,
  useLazyGetConstructionAddersQuery,
  useLazyGetHoldbacksQuery,
  useLazyGetModifiersQuery,
  useLazyGetItcModifiersQuery,
  useLazyGetOrganizationEnergyCommunityQuery,
  useLazyGetOrganizationPpwModifiersQuery,
  useLazyGetOrganizationDomesticContentQuery,
  useLazyGetOrganizationPricingHoldbacksQuery,
  useLazyGetPriceSheetDetailsQuery,
  useLazyGetPriceSheetMappingsQuery,
  useLazyGetPriceSheetsQuery,
  useUploadPriceSheetMutation,
  useApplyBulkPriceSheetMappingsMutation,
  useGetDOEPRPricingQuery,
  useLazyGetHVACPricingQuery,
} = pricingApi;
