import { Box } from '@palmetto/palmetto-components';
import { DateTime } from 'luxon';
import { useGetFundHistoryQuery } from '@/services/funds';
import { useParams } from 'react-router-dom';
import { FundEvent, FundHistory as FundHistoryType } from 'types';

export const FundHistory = () => {
  const { key } = useParams<{ key: any }>();
  const {
    data: fundHistory = [],
    isLoading: isFundDataLoading,
    isFetching: isFundDataFetching,
  } = useGetFundHistoryQuery(key);

  if (isFundDataLoading || isFundDataFetching) {
    return <Box>Loading...</Box>;
  }

  const formatDate = (date: Date) => DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_MED);
  const formatTime = (date: Date) => DateTime.fromJSDate(date).toLocaleString(DateTime.TIME_SIMPLE);

  const fundHistoryGroupedByDate = fundHistory.reduce(
    (acc: Record<string, Record<string, any[]>>, item: FundHistoryType) => {
      const date = formatDate(new Date(item.date));
      const time = formatTime(new Date(item.date));

      if (!acc[date]) acc[date] = {};
      if (!acc[date][time]) acc[date][time] = [];
      acc[date][time].push(item);

      return acc;
    },
    {} as Record<string, Record<string, any[]>>,
  );

  const generateRowDescription = (item: FundHistoryType) => {
    const user = item.user;
    const trancheName = item.tranche?.name || '';
    const accountCounter = item.accountCounter || 0;

    switch (item.event) {
      case FundEvent.updateTranche:
        return `${user} updated tranche ${trancheName}`;
      case FundEvent.addAccount:
        return `${user} added accounts to tranche ${trancheName} (${accountCounter} added)`;
      case FundEvent.reassignAccount:
        return `${user} reassigned accounts in tranche ${trancheName} (${accountCounter} moved)`;
      case FundEvent.removeAccount:
        return `${user} removed accounts from tranche ${trancheName} (${accountCounter} removed)`;
      default:
        return `${user} performed unknown action`;
    }
  };

  return (
    <Box display="block" padding="md" fontSize="sm">
      {fundHistory.length === 0 ? (
        <Box textAlign="center" fontSize="md">
          No history available
        </Box>
      ) : (
        Object.entries(fundHistoryGroupedByDate).map(([date, times]) => (
          <Box key={date} margin="md 0">
            <Box fontWeight="bold" fontSize="md" margin="0 0 sm 0">
              {date}
            </Box>
            {Object.entries(times).map(([time, events]: [string, any[]]) => (
              <Box key={time} margin="sm 0">
                <Box fontWeight="medium" fontSize="sm" margin="0 0 xs lg">
                  {time}
                </Box>
                {events.map((event, index) => (
                  <Box key={index} margin="xs lg">
                    {generateRowDescription(event)}
                  </Box>
                ))}
              </Box>
            ))}
          </Box>
        ))
      )}
    </Box>
  );
};
