import { Badge, BadgeVariant, Box, Button, useOpenClose } from '@palmetto/palmetto-components';
import { useGetFundQuery } from '../../services/funds';
import { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { PropertyValue } from '../PropertyListBox/PropertyValue';
import { DateTime } from 'luxon';
import { FundAccountsSummary } from './FundAccountsSummary';
import { EditTrancheModal } from './EditTrancheModal';
import { TrancheStatus } from 'types';
import { useFlags } from 'launchdarkly-react-client-sdk';

const convertIsoToDateMed = (isoDate: string) =>
  isoDate ? DateTime.fromISO(isoDate).toLocaleString(DateTime.DATE_MED) : null;

export function FundOverview() {
  const navigate = useNavigate();
  const { key } = useParams<{ key: any }>();
  const { data: fund = {}, isLoading: isFundDataLoading, } = useGetFundQuery(key);

  const { ptoGreenlight = false } = useFlags();

  const trancheStatus: {
    [key in TrancheStatus]: {
      label: string;
      variant: BadgeVariant;
    };
  } = {
    [TrancheStatus.open]: {
      label: 'Open',
      variant: 'default',
    },
    [TrancheStatus.closed]: {
      label: 'Closed',
      variant: 'default',
    },
  };
  const { isOpen: isModalOpen, handleOpen: openModal, handleClose: closeModal } = useOpenClose();
  const [selectedTranche, setSelectedTranche] = useState<any>(null);

  const getTrancheFilter = (trancheName: string) =>
    `[{"id": "tranche", "operation": "Equal", "selectedValues": ["${trancheName}"]}]`;

  const handleEdit = (tranche: any) => {
    setSelectedTranche(tranche);
    openModal();
  };

  return (
    <Box direction="row" childGap="3xl">
      <Box>
        <Box margin="0 0 lg 0" childGap={'xs'}>
          <Button size="sm" navigate={() => navigate(`/admin/funds/${key}/assign`)}>
            Tranche Accounts
          </Button>
          {ptoGreenlight && (
            <Button size="sm" variant="secondary" navigate={() => navigate(`/admin/funds/${key}/unassign`)}>
              Un-tranche Accounts
            </Button>
          )}
        </Box>
        {!isFundDataLoading && fund && (
          <Box childGap="xs" fontSize="xs" margin="0 0 lg 0">
            <PropertyValue label="NetSuite Subsidiary Id" value={fund.netSuiteSubsidiaryId} />
            <PropertyValue label="Created" value={convertIsoToDateMed(fund.createdDate) as string} />
          </Box>
        )}
        <Box childGap="lg" radius="md" borderWidth="xs" borderColor="separator" padding="lg" margin="0 0 md 0">
          <Box as="h3" fontWeight="medium" fontSize="md">
            Tranches
          </Box>
          <Box>
            {!isFundDataLoading &&
              fund?.tranches &&
              fund.tranches.map((tranche: any) => (
                <Box padding="0 0 sm 0" key={tranche.name}>
                  <Box>
                    <Box direction="row" alignItems="baseline" justifyContent="space-between" padding="0 0 xs 0">
                      <Box as="h3" fontWeight="medium" fontSize="sm">
                        <Link
                          to={`/admin/funds/${fund.fundKey}?advancedFilters=${encodeURIComponent(getTrancheFilter(tranche.name))}`}
                        >
                          {tranche.name}
                        </Link>
                      </Box>
                      <Button
                        onClick={() => handleEdit(tranche)}
                        variant="primary"
                        tone="neutral"
                        size="xs"
                        aria-label="Edit Tranche Account"
                      >
                        Edit
                      </Button>
                    </Box>
                    {ptoGreenlight && tranche.trancheStatus && (
                      <Box direction="row" alignItems="baseline" justifyContent="space-between" padding="xs 0">
                        <Badge
                          variant={trancheStatus[tranche.trancheStatus as TrancheStatus]?.variant}
                          message={trancheStatus[tranche.trancheStatus as TrancheStatus]?.label}
                          size={{ base: 'md', desktop: 'lg' }}
                        />
                      </Box>
                    )}
                    <Box
                      direction="row"
                      alignItems="baseline"
                      // justifyContent="space-between"
                      padding="0 0 xs 0"
                      childGap="lg"
                    >
                      <Box childGap="xs" fontSize="xs" flex="auto" padding="xs 0 0 0">
                        <Box direction="row" gap="2xs" justifyContent="space-between">
                          <Box color="body-secondary" fontWeight="medium">
                            Submitted
                          </Box>
                          <Box
                            direction="row"
                            childGap="xs"
                            alignItems="center"
                            textAlign="right"
                            style={{ wordBreak: 'break-word' }}
                          >
                            {convertIsoToDateMed(tranche.submittedDate)}
                          </Box>
                        </Box>
                        <Box direction="row" gap="2xs" justifyContent="space-between">
                          <Box color="body-secondary" fontWeight="medium">
                            Accepted
                          </Box>
                          <Box
                            direction="row"
                            childGap="xs"
                            alignItems="center"
                            textAlign="right"
                            style={{ wordBreak: 'break-word' }}
                          >
                            {tranche.trancheDate ? convertIsoToDateMed(tranche.trancheDate) : '-'}
                          </Box>
                        </Box>
                        <Box
                          direction="row"
                          alignItems="baseline"
                          justifyContent="space-between"
                          borderWidth="0 0 xs 0"
                          borderColor="separator"
                          padding="0 0 xs 0"
                          childGap="lg"
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ))}
          </Box>
        </Box>
      </Box>
      <Box flex="auto">
        <FundAccountsSummary fundKey={key} />
      </Box>
      <EditTrancheModal
        fundKey={key}
        selectedTranche={selectedTranche}
        isModalOpen={isModalOpen}
        closeModal={closeModal}
      />
    </Box>
  );
}
