import { useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useGetFundQuery, useUnTrancheAccountsMutation } from '../../services/funds';
import PageHeader from '../PageHeader';
import { Box, Button, FormikSelectInputNative, FormikTextareaInput, toast } from '@palmetto/palmetto-components';
import { MainContainer } from '../MainContainer';
import { Helmet } from 'react-helmet';
import { Field, Form, Formik } from 'formik';
import FormSection from '../Forms/FormSection';
import { isErrorWithData } from '../../services/helpers';
import { useNavigate } from 'react-router-dom';
import { object, string, Schema } from 'yup';
import { UnAssignAccountsResult } from './UnAssignAccountsResult';
import { createAccountIdsValidator } from '@/helpers/validateAccountIds';
import { parseAccountIdsFromString } from '@/helpers/parseAccountIdsFromString';

export interface Option {
  value: string;
  label: string;
}

interface UnAssignAccountFormData {
  accountIds: string;
  tranche?: string;
}

const unAssignAccountSchema = {
  accountIds: string()
    .required('Accounts are required')
    .test('is-valid-mongo-ids', 'Invalid account ids', createAccountIdsValidator()),
};

export const AssignAccountFormSchema: Schema<UnAssignAccountFormData> = object()
  .shape(unAssignAccountSchema)
  .noUnknown(true);

export function UnAssignAccounts() {
  const [results, setResults] = useState<any>();
  const { key } = useParams<{ key: any }>();
  const { data: fundData = {}, isLoading: isFundDataLoading, isFetching: isFundDataFetching } = useGetFundQuery(key);

  const fund = useMemo(() => {
    if (isFundDataLoading || isFundDataFetching) return undefined;
    return fundData;
  }, [fundData, isFundDataLoading, isFundDataFetching]);

  const trancheOptions = useMemo(() => {
    return fund?.tranches.map((tranche: any) => ({ value: tranche.name, label: tranche.name })) ?? [];
  }, [fund?.tranches]);
  const [unTrancheAccounts] = useUnTrancheAccountsMutation();

  const navigate = useNavigate();

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    const loadingToastId = toast.loading('Processing accounts...');
    try {
      let accountIds = await parseAccountIdsFromString(values.accountIds);
      accountIds = accountIds.filter((id: string) => id.trim().length > 0);

      const trancheRequest = {
        accountIds,
        trancheName: values.tranche,
      };

      const results = await unTrancheAccounts({ fundKey: key, trancheRequest }).unwrap();
      setResults(results);
    } catch (e) {
      if (isErrorWithData(e)) {
        toast.error(e.data.message);
      } else {
        console.error(e);
        toast.error('Error submitting un-tranche request');
      }
    } finally {
      toast.dismiss(loadingToastId);
      setSubmitting(false);
    }
  };

  const initialValues = {
    tranche: '',
    trancheName: '',
  };

  const handleViewFund = () => {
    navigate(`/admin/funds/${key}`);
  };

  return (
    <>
      <Helmet>
        <title>Un-Tranche Accounts</title>
      </Helmet>
      <MainContainer>
        <PageHeader
          eyebrow={<Link to={`/admin/funds/${key}`}>{fund?.description}</Link>}
          title={results ? `${results.trancheName} Results` : 'Remove Accounts from a Tranche'}
        />
        {!results ? (
          <Formik validationSchema={AssignAccountFormSchema} initialValues={initialValues} onSubmit={handleSubmit}>
            {({ isSubmitting, submitForm }) => (
              <Form>
                <Box>
                  <Box>
                    <FormSection title="Tranche">
                      <Box direction="column" childGap={{ base: 'lg', desktop: 'xl' }}>
                        <Box>
                          <Field
                            name="tranche"
                            id="tranche"
                            options={trancheOptions}
                            component={FormikSelectInputNative}
                            isDisabled={isSubmitting}
                          />
                        </Box>
                      </Box>
                    </FormSection>
                  </Box>
                  <Box>
                    <FormSection title="Accounts" description="One account ID per line, or comma separated">
                      <Field
                        name="accountIds"
                        id="accountIds"
                        component={FormikTextareaInput}
                        isDisabled={isSubmitting}
                        rows={10}
                      />
                    </FormSection>
                  </Box>
                </Box>
                <Box
                  direction={{
                    base: 'column',
                    tablet: 'row',
                  }}
                  alignItems={{
                    base: 'stretch',
                    tablet: 'flex-end',
                  }}
                  justifyContent={{
                    tablet: 'flex-end',
                  }}
                  childGap="sm"
                  style={{ flexShrink: 0 }}
                  padding="lg 0 0 0"
                >
                  <Button
                    navigate={() => navigate(`/admin/funds/${key ?? ''}`)}
                    variant="secondary"
                    tone="neutral"
                    size="md"
                    isLoading={isSubmitting}
                  >
                    Cancel
                  </Button>
                  <Button size="md" variant="primary" type="submit" onClick={submitForm} isLoading={isSubmitting}>
                    Remove From Tranche
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        ) : (
          <UnAssignAccountsResult results={results} onViewFundClick={handleViewFund} />
        )}
      </MainContainer>
    </>
  );
}
