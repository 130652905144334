import * as yup from 'yup';
import { Box, FormikTextInput } from '@palmetto/palmetto-components';
import { Field, useFormikContext } from 'formik';
import { useCallback } from 'react';

export const createDomesticContentModifierValidationSchema = yup.object().shape({
  name: yup.string().required('Option Name is required'),
  // PV Only
  ['pvOnly-domesticContentModifierPercent']: yup
    .number()
    .min(0, 'Must be a positive number')
    .max(100, 'Must be less than 100')
    .test({
      name: 'exclusive-modifier',
      test: function (value) {
        if (!value) return true;
        if (this.parent['pvOnly-domesticContentModifierPerWatt']) {
          return this.createError({ message: 'Only a % or per watt can be submitted' });
        }
        return true;
      },
    })
    .test({
      name: 'greater-than-holdback',
      test: function (value) {
        if (!value || !this.parent['pvOnly-domesticContentHoldbackPercent']) return true;
        return (
          value >= this.parent['pvOnly-domesticContentHoldbackPercent'] ||
          this.createError({ message: 'Modifier must be greater than or equal to holdback' })
        );
      },
    }),
  ['pvOnly-domesticContentModifierPerWatt']: yup
    .number()
    .min(0, 'Must be a positive number')
    .test({
      name: 'exclusive-modifier',
      test: function (value) {
        if (!value) return true;
        if (this.parent['pvOnly-domesticContentModifierPercent']) {
          return this.createError({ message: 'Only a % or per watt can be submitted' });
        }
        return true;
      },
    })
    .test({
      name: 'greater-than-holdback',
      test: function (value) {
        if (!value || !this.parent['pvOnly-domesticContentHoldbackPerWatt']) return true;
        return (
          value >= this.parent['pvOnly-domesticContentHoldbackPerWatt'] ||
          this.createError({ message: 'Modifier must be greater than or equal to holdback' })
        );
      },
    }),
  ['pvOnly-domesticContentHoldbackPercent']: yup
    .number()
    .min(0, 'Must be a positive number')
    .max(100, 'Must be less than 100')
    .test({
      name: 'matching-type',
      test: function (value) {
        if (!value) return true;
        if (this.parent['pvOnly-domesticContentModifierPerWatt']) {
          return this.createError({ message: 'Holdback requires a $/watt' });
        }
        return (
          value <= this.parent['pvOnly-domesticContentModifierPercent'] ||
          this.createError({ message: 'Holdback must be less than or equal to modifier' })
        );
      },
    }),
  ['pvOnly-domesticContentHoldbackPerWatt']: yup
    .number()
    .min(0, 'Must be a positive number')
    .test({
      name: 'matching-type',
      test: function (value) {
        if (!value) return true;
        if (this.parent['pvOnly-domesticContentModifierPercent']) {
          return this.createError({ message: 'Holdback requires a percent' });
        }
        return (
          value <= this.parent['pvOnly-domesticContentModifierPerWatt'] ||
          this.createError({ message: 'Holdback must be less than or equal to modifier' })
        );
      },
    }),
  // Storage Only
  ['storageOnly-domesticContentModifierPercent']: yup
    .number()
    .min(0, 'Must be a positive number')
    .max(100, 'Must be less than 100')
    .test({
      name: 'exclusive-modifier',
      test: function (value) {
        if (!value) return true;
        if (this.parent['storageOnly-domesticContentModifierPerWatt']) {
          return this.createError({ message: 'Only a % or per watt can be submitted' });
        }
        return true;
      },
    })
    .test({
      name: 'greater-than-holdback',
      test: function (value) {
        if (!value || !this.parent['storageOnly-domesticContentHoldbackPercent']) return true;
        return (
          value >= this.parent['storageOnly-domesticContentHoldbackPercent'] ||
          this.createError({ message: 'Modifier must be greater than or equal to holdback' })
        );
      },
    }),
  ['storageOnly-domesticContentModifierPerWatt']: yup
    .number()
    .min(0, 'Must be a positive number')
    .test({
      name: 'exclusive-modifier',
      test: function (value) {
        if (!value) return true;
        if (this.parent['storageOnly-domesticContentModifierPercent']) {
          return this.createError({ message: 'Only a % or per watt can be submitted' });
        }
        return true;
      },
    })
    .test({
      name: 'greater-than-holdback',
      test: function (value) {
        if (!value || !this.parent['storageOnly-domesticContentHoldbackPerWatt']) return true;
        return (
          value >= this.parent['storageOnly-domesticContentHoldbackPerWatt'] ||
          this.createError({ message: 'Modifier must be greater than or equal to holdback' })
        );
      },
    }),
  ['storageOnly-domesticContentHoldbackPercent']: yup
    .number()
    .min(0, 'Must be a positive number')
    .max(100, 'Must be less than 100')
    .test({
      name: 'matching-type',
      test: function (value) {
        if (!value) return true;
        if (this.parent['storageOnly-domesticContentModifierPerWatt']) {
          return this.createError({ message: 'Holdback requires a $/watt' });
        }
        return (
          value <= this.parent['storageOnly-domesticContentModifierPercent'] ||
          this.createError({ message: 'Holdback must be less than or equal to modifier' })
        );
      },
    }),
  ['storageOnly-domesticContentHoldbackPerWatt']: yup
    .number()
    .min(0, 'Must be a positive number')
    .test({
      name: 'matching-type',
      test: function (value) {
        if (!value) return true;
        if (this.parent['storageOnly-domesticContentModifierPercent']) {
          return this.createError({ message: 'Holdback requires a percent' });
        }
        return (
          value <= this.parent['storageOnly-domesticContentModifierPerWatt'] ||
          this.createError({ message: 'Holdback must be less than or equal to modifier' })
        );
      },
    }),
  // PV & Storage
  ['pvAndStorage-domesticContentModifierPercent']: yup
    .number()
    .min(0, 'Must be a positive number')
    .max(100, 'Must be less than 100')
    .test({
      name: 'exclusive-modifier',
      test: function (value) {
        if (!value) return true;
        if (this.parent['pvAndStorage-domesticContentModifierPerWatt']) {
          return this.createError({ message: 'Only a % or per watt can be submitted' });
        }
        return true;
      },
    })
    .test({
      name: 'greater-than-holdback',
      test: function (value) {
        if (!value || !this.parent['pvAndStorage-domesticContentHoldbackPercent']) return true;
        return (
          value >= this.parent['pvAndStorage-domesticContentHoldbackPercent'] ||
          this.createError({ message: 'Modifier must be greater than or equal to holdback' })
        );
      },
    }),
  ['pvAndStorage-domesticContentModifierPerWatt']: yup
    .number()
    .min(0, 'Must be a positive number')
    .test({
      name: 'exclusive-modifier',
      test: function (value) {
        if (!value) return true;
        if (this.parent['pvAndStorage-domesticContentModifierPercent']) {
          return this.createError({ message: 'Only a % or per watt can be submitted' });
        }
        return true;
      },
    })
    .test({
      name: 'greater-than-holdback',
      test: function (value) {
        if (!value || !this.parent['pvAndStorage-domesticContentHoldbackPerWatt']) return true;
        return (
          value >= this.parent['pvAndStorage-domesticContentHoldbackPerWatt'] ||
          this.createError({ message: 'Modifier must be greater than or equal to holdback' })
        );
      },
    }),
  ['pvAndStorage-domesticContentHoldbackPercent']: yup
    .number()
    .min(0, 'Must be a positive number')
    .max(100, 'Must be less than 100')
    .test({
      name: 'matching-type',
      test: function (value) {
        if (!value) return true;
        if (this.parent['pvAndStorage-domesticContentModifierPerWatt']) {
          return this.createError({ message: 'Holdback requires a $/watt' });
        }
        return (
          value <= this.parent['pvAndStorage-domesticContentModifierPercent'] ||
          this.createError({ message: 'Holdback must be less than or equal to modifier' })
        );
      },
    }),
  ['pvAndStorage-domesticContentHoldbackPerWatt']: yup
    .number()
    .min(0, 'Must be a positive number')
    .test({
      name: 'matching-type',
      test: function (value) {
        if (!value) return true;
        if (this.parent['pvAndStorage-domesticContentModifierPercent']) {
          return this.createError({ message: 'Holdback requires a percent' });
        }
        return (
          value <= this.parent['pvAndStorage-domesticContentModifierPerWatt'] ||
          this.createError({ message: 'Holdback must be less than or equal to modifier' })
        );
      },
    }),
});

export const CreateDomesticContentModifierForm = ({ prefix }: { prefix: string }) => {
  const { setFieldTouched, setFieldValue, values } = useFormikContext<any>();

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value, exclusive } = event.target as any;
      setFieldValue(name, value);
      if (exclusive) {
        setFieldValue(exclusive, null);
        setFieldTouched(exclusive, true, true);
      }
    },
    [setFieldTouched, setFieldValue],
  );

  const calculateNetEffect = () => {
    if (values[`${prefix}-domesticContentModifierPercent`]) {
      if (values[`${prefix}-domesticContentHoldbackPercent`]) {
        return `${(values[`${prefix}-domesticContentModifierPercent`] - values[`${prefix}-domesticContentHoldbackPercent`]).toFixed(2)}%`;
      }
      return `${values[`${prefix}-domesticContentModifierPercent`]}%`;
    }
    if (values[`${prefix}-domesticContentModifierPerWatt`]) {
      if (values[`${prefix}-domesticContentHoldbackPerWatt`]) {
        return `$${(values[`${prefix}-domesticContentModifierPerWatt`] - values[`${prefix}-domesticContentHoldbackPerWatt`]).toFixed(2)}/W`;
      }
      return `$${values[`${prefix}-domesticContentModifierPerWatt`]}/W`;
    }
    return '$0.0/W';
  };

  return (
    <Box direction="column" childGap="md">
      <Box childGap="md">
        <Box fontWeight="medium" color="body-primary">
          <Box color="body-primary">Enter a % or $/watt modifier</Box>
        </Box>
        <Box fontWeight="bold" color="body-primary">
          Modifier
        </Box>
        <Box direction={{ base: 'column', desktop: 'row' }} childGap="xl" width="auto" flex="auto">
          <Field
            component={FormikTextInput}
            label=""
            name={`${prefix}-domesticContentModifierPercent`}
            id={`${prefix}-domesticContentModifierPercent`}
            type="number"
            suffix="%"
            onChange={handleChange}
            exclusive={`${prefix}-domesticContentModifierPerWatt`}
          />
          <Field
            component={FormikTextInput}
            label=""
            name={`${prefix}-domesticContentModifierPerWatt`}
            id={`${prefix}-domesticContentModifierPerWatt`}
            type="number"
            prefix="$"
            suffix="/W"
            onChange={handleChange}
            exclusive={`${prefix}-domesticContentModifierPercent`}
          />
        </Box>
        <Box fontWeight="medium" color="body-primary">
          <Box color="body-primary">Enter a % or $/watt holdback</Box>
        </Box>
        <Box fontWeight="bold" color="body-primary">
          Holdback
        </Box>
        <Box direction={{ base: 'column', desktop: 'row' }} childGap="xl" width="auto" flex="auto">
          <Field
            component={FormikTextInput}
            label=""
            name={`${prefix}-domesticContentHoldbackPercent`}
            id={`${prefix}-domesticContentHoldbackPercent`}
            type="number"
            suffix="%"
          />
          <Field
            component={FormikTextInput}
            label=""
            name={`${prefix}-domesticContentHoldbackPerWatt`}
            id={`${prefix}-domesticContentHoldbackPerWatt`}
            type="number"
            prefix="$"
            suffix="/W"
          />
        </Box>
        <Box fontWeight="medium" color="body-primary">
          <Box color="body-primary">The net value will be the pricing shown to the sales rep</Box>
        </Box>
        <Box direction={'row'} childGap="xl" width="auto" flex="auto">
          <Box fontWeight="bold" color="body-primary">
            Net Pricing Effect
          </Box>
          <Box color="body-primary">{calculateNetEffect()}</Box>
        </Box>
      </Box>
    </Box>
  );
};
