import { Helmet } from 'react-helmet';
import { Box, Button, Card, toast, useOpenClose } from '@palmetto/palmetto-components';
import { FilterableProjectTransactionTable } from '@/components/Payouts/FilterableProjectTransactionTable';
import { useParams } from 'react-router-dom';
import { useCallback, useMemo, useState } from 'react';
import {
  useCreateProjectTransactionMutation,
  useGetBatchByIdQuery,
  useGetProjectTransactionsQuery,
  useUnbatchTransactionMutation,
} from '@/services/payouts';
import { DateTime } from 'luxon';
import { CreateProjectTransactionPayload, ProjectTransactionStatus, UserPermissions } from 'types';
import { useGetOrganizationDetailsQuery } from '@/services/organizations';
import { RemoveTransactionsConfirmationModal } from '@/components/Payouts/admin/RemoveTransactionsConfirmationModal';
import { AddProjectTransactionModal } from '@/components/Payouts/admin/AddProjectTransactionModal';
import { buildQueryString } from '@/helpers/buildQueryString';
import getConfigValue from '@/config';
import { RequirePayoutsAccountingAdminUser } from '../auth/RequirePayoutsAccountingAdminUser';
import { useAuth } from '@/components/auth/authProvider';
import { isPalmettoFinanceUser } from '@/components/auth/RequirePricingAdminUser';
import { skipToken } from '@reduxjs/toolkit/query';

const api = getConfigValue('VITE_APP_API_BASE_URL');

/**
 * View a single batch of transactions, it is based off the ProjectTransactionsView component, but we may be able to use composition to clean this up
 *
 * @constructor
 */
export const BatchView = () => {
  const { batchId } = useParams<{ batchId: string }>();

  const {
    isOpen: isConfirmRemoveTransactionModalOpen,
    handleOpen: handleOpenConfirmRemoveTransaction,
    handleClose: handleCloseConfirmRemoveTransaction,
  } = useOpenClose();
  const {
    isOpen: isAddProjectTransactionModalOpen,
    handleOpen: handleOpenAddProjectTransactionModal,
    handleClose: handleCloseAddProjectTransactionModal,
  } = useOpenClose();

  const [createTransaction] = useCreateProjectTransactionMutation();
  const { data: transactions, isLoading: isTransactionsLoading } = useGetProjectTransactionsQuery({
    batchId,
  });
  const { data: batch, isLoading: isBatchLoading } = useGetBatchByIdQuery(batchId ? { batchId } : skipToken);
  const { data: organization, isLoading: isOrganizationLoading } = useGetOrganizationDetailsQuery(
    batch?.payeeExternalId ? { alias: batch.payeeExternalId } : skipToken,
  );
  // Not using RTK query to have the browser download directly
  const exportUrl = batchId
    ? `${api}/payouts/transactions?${buildQueryString({ batchId, format: 'csv' })}`
    : undefined;
  const [unbatchTransaction] = useUnbatchTransactionMutation();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const isLoading = isTransactionsLoading || isBatchLoading || isOrganizationLoading;
  const { claims } = useAuth();
  const hideColumns = useMemo(() => {
    const columns = ['batchDate', 'payeeName'];
    if (
      !isPalmettoFinanceUser(claims) ||
      !claims?.permissions?.includes(UserPermissions.lightReachAccountingAdmin)
    ) {
      columns.push('isManual');
    }
    return columns;
  }, [claims]);
  const handleRowSelected = useCallback(
    (ids: string[], selected: boolean) => {
      if (selected) {
        setSelectedIds([...new Set([...selectedIds, ...ids])]);
      } else {
        setSelectedIds(selectedIds.filter((selectedId) => !ids.includes(selectedId)));
      }
    },
    [selectedIds, setSelectedIds],
  );

  const batchDateDisplay = useMemo(() => {
    return batch?.batchDate ? DateTime.fromISO(batch.batchDate).toFormat('EEEE MMM d, yyyy') : '';
  }, [batch?.batchDate]);

  const removeSelectedTransactions = useCallback(async () => {
    try {
      for (const id of selectedIds) {
        await unbatchTransaction({ transactionId: id }).unwrap();
      }
      handleCloseConfirmRemoveTransaction();
      setSelectedIds([]);
      toast.success('Transactions removed successfully');
    } catch (error) {
      console.log(error);
      toast.error('Could not remove transactions');
    }
  }, [selectedIds, unbatchTransaction]);

  const handleAddTransaction = useCallback(async (values: CreateProjectTransactionPayload) => {
    try {
      await createTransaction({
        ...values,
        ...(values.clawbackCategory
          ? { description: `${values.clawbackCategory} - ${values.description ?? ''}` }
          : {}),
      }).unwrap();
      handleCloseAddProjectTransactionModal();
      toast.success('Transaction added successfully');
    } catch (error) {
      console.log(error);
      toast.error('Could not add transaction');
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Payouts</title>
      </Helmet>
      <RemoveTransactionsConfirmationModal
        isOpen={isConfirmRemoveTransactionModalOpen}
        onClose={handleCloseConfirmRemoveTransaction}
        onConfirm={removeSelectedTransactions}
        count={selectedIds?.length || 0}
        total={selectedIds.reduce((acc, id) => {
          const transaction = transactions?.find((transaction) => transaction.id === id);
          return acc + (transaction?.amount ?? 0);
        }, 0)}
      />
      <AddProjectTransactionModal
        isOpen={isAddProjectTransactionModalOpen}
        handleClose={handleCloseAddProjectTransactionModal}
        modalTitle="Add Payout"
        submitButtonText="Add Payout"
        modalId="addPaymentModal"
        handleSubmit={handleAddTransaction}
        batchDate={batch?.batchDate}
        payeeType={batch?.payeeType}
      />
      <Card padding="sm" marginTop="0">
        <Card.Header>
          <Box direction="row" justifyContent="space-between">
            <Box fontSize="xl" fontWeight="medium">
              Batch - {batchDateDisplay} - {organization?.name}
            </Box>
            <RequirePayoutsAccountingAdminUser>
              {status === ProjectTransactionStatus.pending && (
                <Box id="toolbar" direction="row" alignSelf="flex-end" childGap="sm">
                  <Button
                    iconPrefix="add"
                    size="sm"
                    variant="secondary"
                    tone="neutral"
                    disabled={isLoading}
                    onClick={handleOpenAddProjectTransactionModal}
                  >
                    Add Payout
                  </Button>
                  {!isLoading && selectedIds?.length > 0 && (
                    <Box childGap="sm">
                      <Button
                        size="sm"
                        prefix="trash"
                        variant="primary"
                        tone="danger"
                        onClick={handleOpenConfirmRemoveTransaction}
                      >
                        Remove Transaction{selectedIds.length > 1 ? 's' : ''}
                      </Button>
                    </Box>
                  )}
                </Box>
              )}
            </RequirePayoutsAccountingAdminUser>
          </Box>
        </Card.Header>
        <Box margin="0 lg 0 lg">
          {transactions && (
            <FilterableProjectTransactionTable
              transactions={transactions}
              handleRowSelected={handleRowSelected}
              selectedIds={selectedIds}
              exportUrl={exportUrl}
              hideColumns={hideColumns}
            />
          )}
        </Box>
      </Card>
    </>
  );
};
