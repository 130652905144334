import { Helmet } from 'react-helmet';
import { MainContainer } from '../MainContainer';
import { Box, Card, Column, Table } from '@palmetto/palmetto-components';
import PageHeader from '../PageHeader';
import { FundType } from 'types';
import { useLazyGetFundsQuery, useLazyGetFundsStatsQuery } from '../../services/funds';
import { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';

export function Funds() {
  const [trigger, { data: fundData = [], isLoading: isFundDataLoading, isFetching: isFundDataFetching }] =
    useLazyGetFundsQuery();
  const [triggerStats, { data: fundStats = [], isLoading: isStatsLoading, isError: isStatsError }] =
    useLazyGetFundsStatsQuery();

  const funds = useMemo(() => {
    if (isFundDataLoading || isFundDataFetching) return [];
    if (fundData === null) return [];
    if (fundData && fundData?.length === 0) return [];

    const defaultStats = {
      inTranche: 0,
      sold: 0,
      totalAccounts: 0,
    };

    return fundData.map((fund) => {
      if (isStatsError || !fundStats) {
        return {
          ...fund,
          ...defaultStats,
        };
      }

      const stats = fundStats.find((stat) => stat.fundKey === fund.fundKey) || defaultStats;
      return {
        ...fund,
        ...stats,
      };
    });
  }, [fundData, fundStats, isFundDataLoading, isFundDataFetching, isStatsError]);
  const fundTypeDisplayName = {
    [FundType.strandedAsset]: 'Stranded Asset',
    [FundType.taxEquity]: 'Tax Equity',
    [FundType.debt]: 'Debt',
    [FundType.jointStrandedAsset]: 'Joint Stranded Asset',
  };
  const columnConfig = [
    {
      heading: 'Name',
      dataKey: 'description',
      render: (_cell: any, row: any) => <Link to={`/admin/funds/${row.fundKey}`}>{row.description}</Link>,
    },
    {
      heading: 'Type',
      dataKey: 'fundType',
      render: (_cell: any, row: any) => <Box>{fundTypeDisplayName[row.fundType as FundType]}</Box>,
      width: 200,
    },
    {
      heading: 'In Tranche',
      dataKey: 'inTranche',
      width: 150,
    },
    {
      heading: 'Sold',
      dataKey: 'sold',
      width: 150,
    },
    {
      heading: 'Total Accounts',
      dataKey: 'totalAccounts',
      width: 150,
    },
    {
      heading: 'Tranches',
      dataKey: 'trancheCount',
      width: 200,
    },
  ];

  useEffect(() => {
    trigger({});
    triggerStats({});
  }, [trigger, triggerStats]);

  return (
    <>
      <Helmet>
        <title>Funds</title>
      </Helmet>
      <MainContainer>
        <PageHeader title="Funds"></PageHeader>
        <Card padding="sm">
          {funds.length > 0 ? (
            <Table
              rowKey="fundKey"
              columns={columnConfig as Column[]}
              rows={funds || []}
              isLoading={isFundDataLoading || isFundDataFetching || isStatsLoading}
              isScrollable={{
                x: true,
              }}
            />
          ) : (
            <Box padding="xl" textAlign="center">
              No funds found
            </Box>
          )}
        </Card>
      </MainContainer>
    </>
  );
}
