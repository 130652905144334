import { Box } from '@palmetto/palmetto-components';
import { ReactNode } from 'react';
interface PropertyBoxProps {
  title: ReactNode;
  className?: string;
  children?: ReactNode;
  noBorder?: boolean;
}

export const PropertyBox: React.FC<PropertyBoxProps> = ({ className, title, children, noBorder = false }) => {
  return (
    <Box
      alignItems="baseline"
      borderWidth={noBorder ? '0' : '0 0 xs 0'}
      borderColor="separator"
      padding="xs 0 xs 0"
      className={className}
      childGap="xs"
      display="block"
    >
      <Box direction="row" justifyContent="space-between" width="100">
        {title}
      </Box>
      {children}
    </Box>
  );
};
