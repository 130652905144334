import { Box, Button, FormLabel, FormikRadioGroup, FormikTextareaInput, Modal } from '@palmetto/palmetto-components';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { DomesticContentAttestmentType } from 'types';
import {
  DomesticContentReviewStatusForSolarAndStorage,
  DomesticContentReviewStatusForSolarOnly,
  DomesticContentReviewStatusForStorageOnly,
} from 'types';

interface DomesticContentReviewModalProps {
  isModalOpen: boolean;
  handleClose: () => void;
  handleSubmit: (values: { notes: string | undefined; domesticContentStatus: string }) => Promise<void>;
  attestmentType?: DomesticContentAttestmentType;
}

const domesticContentStatusOptionsMap = {
  [DomesticContentAttestmentType.pvOnly]: DomesticContentReviewStatusForSolarOnly,
  [DomesticContentAttestmentType.storageOnly]: DomesticContentReviewStatusForStorageOnly,
  [DomesticContentAttestmentType.pvAndStorage]: DomesticContentReviewStatusForSolarAndStorage,
};

const domesticContentSchema = yup.object({
  notes: yup.string().optional().nullable(),
  domesticContentStatus: yup.string().required('A Status is required'),
});

export const DomesticContentReviewModal = ({
  isModalOpen,
  handleClose,
  handleSubmit,
  attestmentType = DomesticContentAttestmentType.pvOnly,
}: DomesticContentReviewModalProps) => {
  const domesticContentStatusOptions = Object.keys(
    domesticContentStatusOptionsMap[attestmentType as keyof typeof domesticContentStatusOptionsMap],
  ).map((reasons) => ({
    label:
      domesticContentStatusOptionsMap[attestmentType as keyof typeof domesticContentStatusOptionsMap][
        reasons as keyof (typeof domesticContentStatusOptionsMap)[typeof attestmentType]
      ],
    value: reasons,
    id: reasons,
  }));
  return (
    <Modal
      isOpen={isModalOpen}
      onDismiss={() => {
        handleClose();
      }}
      maxWidth="4xl"
      ariaLabelledBy="domesticContentReviewModalHeader"
      fullScreenMobile
    >
      <Modal.Header
        id="domesticContentReviewModalHeader"
        title={`Complete Domestic Content Review`}
        onDismiss={() => {
          handleClose();
        }}
      />
      <Formik
        initialValues={{ domesticContentStatus: '' as any, notes: '' }}
        onSubmit={handleSubmit}
        enableReinitialize={false}
        validationSchema={domesticContentSchema}
      >
        {({ isSubmitting }) => (
          <Form noValidate id="domesticContentReviewForm">
            <Modal.Body background="secondary" gap="lg" fontSize="sm">
              <Box gap="lg">
                <FormLabel isFieldRequired inputId="domesticContentStatus">
                  Mark Domestic Content for this Project as...
                </FormLabel>
                <Field
                  isRequired
                  component={FormikRadioGroup}
                  id="domesticContentStatus"
                  label="Domestic Content Status"
                  name="domesticContentStatus"
                  options={domesticContentStatusOptions}
                />
                <Field
                  label="Notes (Optional)"
                  name="notes"
                  id="notes"
                  component={FormikTextareaInput}
                  autoComplete="off"
                  isDisabled={isSubmitting}
                />
              </Box>
            </Modal.Body>
            <Modal.Footer justifyContent={'flex-end'}>
              <Box direction="row" gap="sm">
                <Button variant="secondary" tone="neutral" size="md" onClick={handleClose} isDisabled={isSubmitting}>
                  Cancel
                </Button>
                <Button tone="primary" size="md" variant="primary" type="submit" isLoading={isSubmitting}>
                  Submit Review
                </Button>
              </Box>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
