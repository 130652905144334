import { FundHistory } from 'types';
import { api } from './api';

interface FundAccountsSearchResponse {
  pageNum: number;
  total: number;
  accounts: Array<any>;
}

interface TranchePostSchema {
  accountIds: Array<string>;
  trancheName: string;
  submittedDate?: string;
  trancheDate?: string;
}

export interface FundStats {
  fundKey: string;
  inTranche: number;
  sold: number;
  totalAccounts: number;
}

interface UnTranchePostSchema {
  accountIds: Array<string>;
  trancheName: string;
}

export const fundsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getFunds: build.query<any[], Record<any, any>>({
      query: () => ({ url: `/admin/funds` }),
      providesTags: () => [{ type: 'Funds', id: 'LIST' }],
    }),
    getFund: build.query<any, { fundKey: string }>({
      query: (fundKey) => ({ url: `/admin/funds/${fundKey}` }),
      providesTags: (_result, _error, key) => [{ type: 'Funds', key }],
    }),
    getFundHistory: build.query<FundHistory[], { fundKey: string }>({
      query: (fundKey) => ({ url: `/admin/funds/${fundKey}/history` }),
      providesTags: (_result, _error, key) => [{ type: 'Funds', id: 'LIST' }],
    }),
    getTrancheByTrancheNameAndFundKey: build.query<any, { fundKey: string, trancheName: string }>({
      query: ({fundKey, trancheName}) => ({ url: `/admin/funds/${fundKey}/tranches/${trancheName}` }),
      providesTags: (_result, _error, key) => [{ type: 'Funds', key }],
    }),
    searchFundAccounts: build.query<FundAccountsSearchResponse, Record<any, any>>({
      query: (queryParams) => {
        const { fundKey, ...rest } = queryParams;
        return {
          url: `admin/funds/${fundKey}/search${
            queryParams &&
            '?' +
              new URLSearchParams({
                ...rest,
                ...(rest?.searchTerm && { searchTerm: rest?.searchTerm }),
              })
          }`,
        };
      },
      transformResponse: (response: any) => {
        const { data, pageNum, total } = response || {};
        return { pageNum, total, accounts: data.accounts };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.accounts.map(({ id }) => ({ type: 'FundAccounts' as const, id })),
              { type: 'FundAccounts', id: 'LIST' },
            ]
          : [{ type: 'FundAccounts', id: 'LIST' }],
    }),
    getFundListFilters: build.query<any, string>({
      query: (fundKey) => ({
        url: `admin/funds/${fundKey}/filters`,
      }),
    }),
    trancheAccounts: build.mutation<any, { fundKey: string; trancheRequest: TranchePostSchema }>({
      query: ({ fundKey, trancheRequest }) => ({
        url: `/admin/funds/${fundKey}/tranches/assign`,
        method: 'POST',
        body: trancheRequest,
      }),
      transformResponse: (response: any, _meta: any, arg: any) => {
        return { ...response, trancheName: arg.trancheRequest.trancheName };
      },
      invalidatesTags: (key) => [
        { type: 'FundAccounts', id: 'LIST' },
        { type: 'Funds', key },
      ],
    }),
    unTrancheAccounts: build.mutation<any, { fundKey: string; trancheRequest: UnTranchePostSchema }>({
      query: ({ fundKey, trancheRequest }) => ({
        url: `/admin/funds/${fundKey}/tranches/unassign`,
        method: 'POST',
        body: trancheRequest,
      }),
      transformResponse: (response: any, _meta: any, arg: any) => {
        return { ...response, trancheName: arg.trancheRequest.trancheName };
      },
      invalidatesTags: (key) => [
        { type: 'FundAccounts', id: 'LIST' },
        { type: 'Funds', key },
      ],
    }),
    editTrancheAccount: build.mutation<any, { fundKey: string; trancheKey: string; data: any }>({
      query: ({ fundKey, trancheKey, data }) => ({
        url: `/admin/funds/${fundKey}/tranches/${trancheKey}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (key) => [
        { type: 'FundAccounts', id: 'LIST' },
        { type: 'Funds', key },
      ],
    }),
    getFundsStats: build.query<FundStats[], Record<string, never>>({
      query: () => ({ url: `/admin/funds/stats` }),
      providesTags: () => [{ type: 'Funds', id: 'STATS' }],
    }),
  }),
});

export const {
  useGetFundsQuery,
  useLazyGetFundsQuery,
  useGetFundQuery,
  useLazyGetFundQuery,
  useLazySearchFundAccountsQuery,
  useGetFundListFiltersQuery,
  useTrancheAccountsMutation,
  useUnTrancheAccountsMutation,
  useEditTrancheAccountMutation,
  useGetFundHistoryQuery,
  useGetTrancheByTrancheNameAndFundKeyQuery,
  useLazyGetTrancheByTrancheNameAndFundKeyQuery,
  useLazyGetFundsStatsQuery,
} = fundsApi;
