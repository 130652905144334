export enum DataFilterType {
  Select = 'Select',
}

export enum DataFilterOperation {
  Equal = 'Equal',
  NotEqual = 'NotEqual',
}

export const DataFilterOperationDisplay = {
  Equal: { label: 'is' },
  NotEqual: { label: 'is not' },
} as const;

export interface DataFilter<Data, TSchema> {
  id: string;
  label: string;
  type: DataFilterType;
  operations: DataFilterOperation[];
  selectOptions?: (data?: Data) => Promise<DropdownOption[]>;
  getMongoFilter: (data: Data, operation: DataFilterOperation) => Partial<TSchema> | object | undefined;
}

export interface DataFilterDisplay {
  id: string;
  label: string;
  description?: string;
  type: DataFilterType;
  displayValuePrefix?: string;
  displayValueSuffix?: string;
  operations: DataFilterOperation[];
  selectOptions?: DropdownOption[];
}

export interface AppliedDataFilter {
  id: string;
  selectedValues?: string[];
  operation?: DataFilterOperation;
}

export interface DropdownOption {
  value: string;
  label: string;
  group?: string;
}
