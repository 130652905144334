import { MilestoneRequirement, MilestoneStatus } from 'types';
import { MilestoneType } from '../../../types/Milestone';
import { Box, Heading } from '@palmetto/palmetto-components';

export const ProgressStatusHeader = ({ milestones = [] }: { milestones?: any[] }) => {
  const milestoneRequirements = milestones.find(
    (milestone: any) => milestone.type === MilestoneType.noticeToProceed,
  )?.requirements;
  let progressText;
  if (
    [MilestoneStatus.approved, MilestoneStatus.submitted].includes(
      milestoneRequirements?.find((requirement: any) => requirement.type === MilestoneRequirement.contractSigned)
        ?.status,
    )
  ) {
    progressText = 'Active Contract';
  } else if (
    milestoneRequirements?.find((requirement: any) => requirement.type === MilestoneRequirement.quoteActive)
      ?.status === 'completed'
  ) {
    progressText = 'Active Quote';
  }

  return progressText ? (
    <Box alignItems="flex-start">
      <Heading as="h5">{progressText}</Heading>
    </Box>
  ) : null;
};
