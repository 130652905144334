import React from 'react';
import { Box, Badge, BadgeVariant } from '@palmetto/palmetto-components';

interface FormSectionHeaderProps {
  className?: string;
  title: string;
  description?: string | React.ReactNode;
  badge?: {
    message: string;
    variant: BadgeVariant;
  };
  children?: React.ReactNode;
}

const FormSectionHeader = ({ className, title, description, badge, children }: FormSectionHeaderProps) => {
  const renderDescription =
    typeof description === 'string' ? (
      <Box as="p" color="body-secondary" fontSize="sm">
        {description}
      </Box>
    ) : (
      description
    );

  return (
    <Box
      width={{
        desktop: '2xl',
        hd: '3xl',
      }}
      childGap="2xs"
      className={className}
      style={{
        flexShrink: 0,
      }}
    >
      <Box as="h2" fontSize="md" fontWeight="medium">
        {title}
      </Box>
      {badge && (
        <Box padding="xs 0 xs 0">
          <Badge message={badge.message} variant={badge.variant} style={{ width: 'fit-content' }} />
        </Box>
      )}
      {renderDescription}
      {children && (
        <Box as="p" color="body-secondary" fontSize="sm" padding="lg 0 0 0" gap={'sm'}>
          {children}
        </Box>
      )}
    </Box>
  );
};

export default FormSectionHeader;
